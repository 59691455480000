import React, { Component } from 'react';

export default class QuizTestQn extends Component {

  componentDidMount(){

  }

  answerHandler = () => {
    let answers = document.getElementsByName("qn"+this.props.index);
    for(let i = 0; i < answers.length; i++){
      let answer = answers[i];
      if(answer.checked === true){
        //register answer to the main
        this.props.answerCallback({
          index: this.props.index,
          answer: answer.value,
        })
      }
    }
  }

  render(){
    return (
      <div className="card" style={{ marginBottom:"10px" }}>
        <div className="card-body">
          <h5 className="card-title">#{this.props.index}</h5>
          <div className="form-group">
            <h5 style={{ color:"var(--accentColor)" }}>{this.props.qn.question}</h5>
          </div>
          <div className="form-check">
            <input value={this.props.qn.answers[0]} onChange={() => this.answerHandler()} className="form-check-input" type="radio" name={"qn"+this.props.index} id={"qn"+this.props.index+"_a"}/>
            <label htmlFor="qn1_a" className="form-check-label">{this.props.qn.answers[0]}</label>
          </div>
          <div className="form-check">
            <input value={this.props.qn.answers[1]} onChange={() => this.answerHandler()} className="form-check-input" type="radio" name={"qn"+this.props.index} id={"qn"+this.props.index+"_b"}/>
            <label htmlFor="qn1_a" className="form-check-label">{this.props.qn.answers[1]}</label>
          </div>
          <div className="form-check">
            <input value={this.props.qn.answers[2]} onChange={() => this.answerHandler()} className="form-check-input" type="radio" name={"qn"+this.props.index} id={"qn"+this.props.index+"_c"}/>
            <label htmlFor="qn1_a" className="form-check-label">{this.props.qn.answers[2]}</label>
          </div>
          <div className="form-check">
            <input value={this.props.qn.answers[3]} onChange={() => this.answerHandler()} className="form-check-input" type="radio" name={"qn"+this.props.index} id={"qn"+this.props.index+"_d"}/>
            <label htmlFor="qn1_a" className="form-check-label">{this.props.qn.answers[3]}</label>
          </div>
        </div>
      </div>
    )
  }
}
