import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { callApi, getInlineLoader, BASE_API, formatMoney, WEB_URL, copyText, formatMoneyShorten } from '../Helper';
import SwipeTabs from "../components/SwipeTabs";
import { BiUser } from 'react-icons/bi';
import { RiUserFollowLine } from 'react-icons/ri';
import ContentFeed from '../ones/ContentFeed';



export default function View(props) {
  const appContext = useContext(AppContext);

  const [ id, setId ] = useState(props.id);
  const [ loading, setLoading ] = useState(false);
  const [ ready, setReady ] = useState(false);
  const [ data, setData ] = useState(null);
  const [ books, setBooks ] = useState(null);
  const [ courses, setCourses ] = useState(null);
  const [ podcasts, setPodcasts ] = useState(null);
  const [ articles, setArticles ] = useState(null);

  const [ canFollow, setCanFollow ] = useState(false);
  const [ hasFollowed, setHasFollowed ] = useState(false);
  
  const getData = async () => {
    return new Promise(async resolve => {
      //get authors data including articles etc..
      
      await callApi('get_author_profile.php', { authorId: id }).then(response => {
        
        if(response.status === 1) {
          setData(response.profile);
          
          setCanFollow(appContext.canFollow(response.profile.userData.id));
          setHasFollowed(appContext.hasFollowed(response.profile.userData.id));
          resolve(true);
        } else {
          appContext.setError(response.msg);
          resolve(false)
        }
      })
    })
  }
  const getAuthorBooks = async () => {
    return new Promise(async resolve => {
      //get authors data including articles etc..
      
      await callApi('get_author_books.php', { authorId: id }).then(response => {
        
        if(response.status === 1) {
          setBooks(response.profile.books);
          resolve(true);
        } else {
          appContext.setError(response.msg);
          resolve(false)
        }
      })
    })
  }

  const getAuthorCourses = async () => {
    return new Promise(async resolve => {
      //get authors data including articles etc..
      
      await callApi('get_author_courses.php', { authorId: id }).then(response => {
        
        if(response.status === 1) {
          setCourses(response.profile.courses);
          resolve(true);
        } else {
          appContext.setError(response.msg);
          resolve(false)
        }
      })
    })
  }

  const getAuthorPodcasts = async () => {
    return new Promise(async resolve => {
      //get authors data including articles etc..
      
      await callApi('get_author_podcasts.php', { authorId: id }).then(response => {
        
        if(response.status === 1) {
          setPodcasts(response.profile.podcasts);
          resolve(true);
        } else {
          appContext.setError(response.msg);
          resolve(false)
        }
      })
    })
  }

  const getAuthorArticles = async () => {
    return new Promise(async resolve => {
      //get authors data including articles etc..
      
      await callApi('get_author_articles.php', { authorId: id }).then(response => {
        
        if(response.status === 1) {
          setArticles(response.profile.articles);
          resolve(true);
        } else {
          appContext.setError(response.msg);
          resolve(false)
        }
      })
    })
  }

  const followAuthor = async () => {
    
    setLoading(true)
    await callApi('follow_author.php', { authorId: data.userData.id }).then(async response => {
      if(response.status === 1) {
        await appContext.authCheck()
        appContext.setMessage("You followed succesfully")
        setLoading(false)
      } else {
        setLoading(false)
        appContext.setError(response.msg);
      }
    })
  }

  
  const init = async () => {
    setData(null);
    setLoading(true);

    await getData().then(async () => {
      await getAuthorBooks().then(async () => {
        await getAuthorPodcasts().then(async () => {
          await getAuthorCourses().then(async () => {
            await getAuthorArticles().then(async () => {
              setLoading(false);
              setReady(true);
            })
          })
        })
      })
    })
  }

  useEffect(() => {
    //..
  }, [ canFollow, hasFollowed ])

  useEffect(() => {
    if(data) {
      setCanFollow(appContext.canFollow(data.userData.id));
      setHasFollowed(appContext.hasFollowed(data.userData.id));
    }
  }, [ data ])

  useEffect(() => {
    if(data) {
      setCanFollow(appContext.canFollow(data.userData.id));
      setHasFollowed(appContext.hasFollowed(data.userData.id));
    }
  }, [ appContext.following, appContext.followers, appContext.userData ])
  
  useEffect(() => {
    init();
  }, [ ]);

  
  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  
  useEffect(() => {
    init();
  }, [ id ]);

  useEffect(() => {
    setId(props.id);
  }, [ props.id ]);

  
  if(ready) {
    if(data && data.userData.publishing_status === 1) {
      return (
        <div className="FullPane" id="fullPane" data-support-top-bar={true}>
          <SwipeTabs
            tabs={[
              {
                title: "Author Info",
                id: "author-info",
                view: (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12" style={{ marginTop: "20px" }}>
                        <BiUser size={80} style={{ display: "block" }}/>
                        <h2 className="font-light" style={{ textTransform: "capitalize" }} >{data.userData.username}</h2>
                        
                        {
                          (!hasFollowed && canFollow) ?
                          <button
                            style={{
                              marginTop: "20px"
                            }}
                            className="btn btn-sm bg-secondary"
                            onClick={followAuthor}
                          >
                            Follow <RiUserFollowLine/>
                          </button>
                          :
                          ""
                        }

                        {
                          (!hasFollowed && canFollow) ?
                          <div className="text-start">
                            <small className="font-light text-muted">Follow this author to stay updated with his/her content</small>
                          </div>
                          :
                          ""
                        }

                        {
                          (hasFollowed) ?
                          <div className="text-start">
                            <small className="font-light text-muted">You are following this author</small>
                          </div>
                          :
                          ""
                        }
                    
                        <hr/>
                        <p>{data.userData.bio}</p>
                      </div>
                    </div>
                  </div>
                )
              },
              {
                title: "Articles",
                id: "author-articles",
                view: (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12" style={{ marginTop: "20px" }}>
                        {
                          (articles && articles.length > 0) ? 
                          articles.map((item, i) => {
                            return (
                              <ContentFeed type="article" data={item} key={i}/>
                            )
                          })
                          :
                          <h6 className="text-muted ">This author has not yet posted articles</h6>
                        }
                      </div>
                    </div>
                  </div>
                )
              },
              {
                title: "Books",
                id: "author-books",
                view: (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12" style={{ marginTop: "20px" }}>
                        {
                          (books && books.length > 0) ? 
                          <div 
                            className="col-md-12 d-flex mNoScrollbars"
                            id="m-books-container"
                            style={{
                              flexDirection: "row",
                              overflowX: "auto",
                            }}
                          >
                            {
                              books.map((item, i) => {
                                return (
                                  <ContentFeed type="book" data={item} key={i}/>
                                )
                              })
                            }
                          </div>
                          :
                          <h6 className="text-muted ">This author has not yet published books</h6>
                        }
                      </div>
                    </div>
                  </div>
                )
              },
              {
                title: "Videos",
                id: "author-courses",
                view: (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12" style={{ marginTop: "20px" }}>
                        {
                          (courses && courses.length > 0) ? 
                          courses.map((item, i) => {
                            return (
                              <ContentFeed type="course" data={item} key={i}/>
                            )
                          })
                          :
                          <h6 className="text-muted ">This author has not yet posted videos</h6>
                        }
                      </div>
                    </div>
                  </div>
                )
              },
              {
                title: "Podcasts",
                id: "author-podcasts",
                view: (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12" style={{ marginTop: "20px" }}>
                        {
                          (podcasts && podcasts.length > 0) ? 
                          <div 
                            className="col-md-12 d-flex mNoScrollbars"
                            id="m-books-container"
                            style={{
                              flexDirection: "row",
                              overflowX: "auto",
                            }}
                          >
                            {
                              podcasts.map((item, i) => {
                                return (
                                  <ContentFeed type="podcast" data={item} key={i}/>
                                )
                              })
                            }
                          </div>
                          :
                          <h6 className="text-muted ">This author has not yet posted podcasts</h6>
                        }
                      </div>
                    </div>
                  </div>
                )
              },
              
            ]}
          />
        </div>
      )
      
    } else {
      return (
        <div className="row">
          <div className="col-md-12 support-loading">
            <h6 className="text-muted font-light">Unknown error occurred, this content may be unavaliable or you do not have permission to view it</h6>
          </div>
        </div>
      )
    }
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }

}
