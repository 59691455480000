import { useState, useContext, createContext, useEffect, useRef } from "react";
import { AppContext } from "../App";
import { MdArrowRight } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { MdSettings, MdArrowBack, MdDarkMode, MdOutlineDarkMode, MdNotifications } from 'react-icons/md';
import { getViewerView } from '../Helper';
import { Modal } from 'bootstrap';
import TopBar from '../components/TopBar';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ view, setView ] = useState(<></>);
  const [ modal, setModal ] = useState(null);

  const modalRef = useRef(null);


  const showModal = () => {
    modalRef.current.show();
  }

  const hideModal = () => {
    modalRef.current.hide();
  }

  const init = () => {
    if(appContext.showViewer) {
      //launch the modal
      showModal();
      //then get content to view
      setView(appContext.viewerView);
    } else {
      //hide modal
      hideModal();
      //reset view
      setView(<></>);
    }
  }

  useEffect(() => {
    //modalRef.current = new Modal(document.getElementById('viewerModal'));
  }, [  ]);

  useEffect(() => {
    if(modalRef.current) {
      init();
      console.log("init 1 - 1");
      //set listeners
      let mainHidder = () => {
        appContext.setShowViewer(false)
        setView(<></>);
      }
      document.getElementById('viewerModal').addEventListener('hidden.bs.modal', mainHidder);

      return (() => {
        document.getElementById('viewerModal').removeEventListener('hidden.bs.modal', mainHidder);
      })
    } else {
      console.log("init 1 - 2");
      modalRef.current = new Modal(document.getElementById('viewerModal'));
    }
  }, [ modal ])

  useEffect(() => {
    if(appContext.showViewer) {
      //...
      if(modalRef.current) {
        init();
        console.log('init 2');
      } else {
        //modalRef.current = new Modal(document.getElementById('viewerModal'));
      }
      //...
    } else {
      if(modalRef.current) {
        hideModal();
      }
    }
  }, [  appContext.showViewer]);

  

  useEffect(() => {
    if(appContext.showViewer) {
      setView(appContext.viewerView);
    }
  }, [ appContext.viewerView ])

  return (
    <div className="modal fade" data-bs-backdrop="static" id="viewerModal"  aria-labelledby="viewerModalLabel" aria-hidden="true">
      <div className="modal-fullscreen modal-dialog">
        <div className="modal-content">
          {
            (!appContext.hideTopBar) ?
            <div className="modal-header">
              <TopBar/>
            </div>: ""
          }
          <div className="modal-body" style={(appContext.hideTopBar) ? { paddingTop:"0" } : { paddingTop:"var(--top-bar-height)" }}>
          
            <div 
              className=""
              style={{
                maxWidth: "960px",
                margin: "0 auto",
                padding: "0"
              }}
            >
              {view}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
