import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { NewsFeedContext } from '../views/NewsFeed'
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { BsPersonCircle } from 'react-icons/bs';
import { formatMoney, BASE_API, isContainerAtEnd } from '../Helper';
import logo from '../assets/logo.png';
import sample from '../assets/sample-cover.jpeg';
import FeedTail from '../ones/FeedTail';

export default function View(props) {
  const appContext = useContext(AppContext);
  const newsFeedContext = useContext(NewsFeedContext);
  const [ data, setData ] = useState(props.data);
  const [ type, setType ] = useState(props.type);
  const [ isLast, setIsLast ] = useState(props.isLast);
  const [ mId, setMid ] = useState(props.mId);
  const [ nextBatch, setNextBatch ] = useState(props.nextBatch);

  const view = () => {
    appContext.navTo({
      item: 'view',
      subItem: type,
      extraItem: data.id,
    })
  }

  const init = () => {

    if(isLast) {
      //lazy load call
      //console.log(`mid is ${mId} and data.id is ${data.id}`);
      

      let container = null;
      if(type === 'podcast') {
        container = document.getElementById('m-podcasts-container')
      } else if(type === 'book') {
        container = document.getElementById('m-books-container')
      } else {
        container = document.documentElement;
      }
      

      let checker = () => {
        let element = document.getElementById(mId);
        if(element) {
          console.log("Is In Viewport", isContainerAtEnd(element, container));
          if(isContainerAtEnd(element, container)) {
            //call for next batch of this type
            newsFeedContext.triggerLazyLoad(nextBatch, type, mId);
          }
        }
      }

      
      if(type === 'podcast' || type === 'book') {
        container.addEventListener('scroll', checker);

        return () => {
          container.removeEventListener('scroll', checker);
        };
      } else {
        document.addEventListener('scroll', checker);

        return () => {
          document.removeEventListener('scroll', checker);
        };
      }
    
    }
  }

  useEffect(() => {
    let finisher = init();
    if(finisher) {
      return finisher;
    }
  }, [ ]);

  useEffect(() => {
    setData(props.data);
    setType(props.type);
    setIsLast(props.isLast);
    setMid(props.mId);
    setNextBatch(props.nextBatch);
  }, [ props.data, props.type, props.isLast, props.mId, props.nextBatch ]);

  if(isLast) {

    return (
      <div id={mId} className="col-12" style={{ paddingLeft:"20px", paddingRight:"20px", paddingTop:"20px"}}>
        <div 
          onClick={view} 
          className="bg-secondary d-flex justify-content-end" 
          style={{ 
            padding:"0px", 
            borderRadius: "10px", 
            cursor: "pointer", 
            overflow: "hidden" 
          }}>
          <div
            className="bg-surface" 
            style={{
              width: "calc(100% - 10px)",
              padding: "20px",
            }}
          >
            <h5 className="font-light" style={{ fontSize: "25px", marginBottom: "20px" }}>{data.title}</h5>
            <h6 className="text-muted" style={{ textTransform: "capitalize", fontSize: "14px" }}>
              {(data.seriesType === 'course') ? "Video" : data.seriesType}s
            </h6>
            <h6 className="text-primary" style={{ fontSize: "12px" }}>{data.categories}</h6>
            <h5 className="text-end font-regular text-secondary" style={{ fontSize: "18px" }}>
              {(Number(data.price) > 0) ? "Tsh "+formatMoney(data.price)  : "Free"}
            </h5>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="col-12" style={{ paddingLeft:"20px", paddingRight:"20px", paddingTop:"20px"}}>
        <div 
          onClick={view} 
          className="bg-secondary d-flex justify-content-end" 
          style={{ 
            padding:"0px", 
            borderRadius: "10px", 
            cursor: "pointer", 
            overflow: "hidden" 
          }}>
          <div
            className="bg-surface" 
            style={{
              width: "calc(100% - 10px)",
              padding: "20px",
            }}
          >
            <h5 className="font-light" style={{ fontSize: "25px", marginBottom: "20px" }}>{data.title}</h5>
            <h6 className="text-muted" style={{ textTransform: "capitalize", fontSize: "14px" }}>
              {(data.seriesType === 'course') ? "Video" : data.seriesType}s
            </h6>
            <h6 className="text-primary" style={{ fontSize: "12px" }}>{data.categories}</h6>
            <h5 className="text-end font-regular text-secondary" style={{ fontSize: "18px" }}>
              {(Number(data.price) > 0) ? "Tsh "+formatMoney(data.price)  : "Free"}
            </h5>
          </div>
        </div>
      </div>
    )
  }
}
