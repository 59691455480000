import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts, MdOutlineRemoveRedEye, MdShare, MdOutlineAddShoppingCart, MdOutlineAudiotrack } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import logo from '../assets/logo.png';
import { callApi, getInlineLoader, BASE_API, formatMoney, WEB_URL, copyText, formatMoneyShorten } from '../Helper';
import BookReader from './BookReader';
import CourseWatcher from './CourseWatcher';
import PodcastListener from './PodcastListener';
import TakeQuiz from './TakeQuiz';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import FeedbackForm from './FeedbackForm';
import BuyForm from './BuyForm';
import AuthorThumb from "../ones/AuthorThumb";
import { AiOutlineEye, AiOutlineShareAlt } from 'react-icons/ai';
import { BsDownload } from 'react-icons/bs';
import ArticleMainBody from "../ones/ArticleMainBody";
import ContentFeedMin from "../ones/ContentFeedMin";
import { useRef } from "react";

export const ContentViewContext = createContext(null);
export default function View(props) {
  const appContext = useContext(AppContext);

  const canUserBuy = (data) => {
    if(data) {
      if(Number(data.price) > 0) {
        //check if this boo is in user collection
        let inCol = false;
        if(type === 'book') {
          inCol = appContext.isBookInCol(data);
        } else if(type === 'course') {
          inCol = appContext.isCourseInCol(data);
        } else if(type === 'podcast') {
          inCol = appContext.isPodcastInCol(data);
        } else if(type === 'article') {
          inCol = appContext.isArticleInCol(data);
        }

        if(inCol) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const checkInCol = (type, data) => {
    if(type === 'book') {
      return appContext.isBookInCol(data);
    } else if(type === 'course') {
      return appContext.isCourseInCol(data);
    } else if(type === 'podcast') {
      return appContext.isPodcastInCol(data);
    } else if(type === 'article') {
      return appContext.isArticleInCol(data);
    }

    return false;
  }

  const [ type, setType ] = useState(props.type);
  const [ id, setId ] = useState(props.id);
  const [ loading, setLoading ] = useState(false);
  const [ ready, setReady ] = useState(false);
  const [ data, setData ] = useState(null);
  const [ canBuy, setCanBuy ] = useState(() => canUserBuy(data));
  const [ inCol, setInCol ] = useState(() => checkInCol(type, data));
  const [ showIntro, setShowIntro ] = useState(true);
  const [ showWatcher, setShowWatcher ] = useState(false);
  const [ showReader, setShowReader ] = useState(false);
  const [ showListener, setShowListener ] = useState(false);
  const [ showQuizer, setShowQuizer ] = useState(false);
  const [ showBuyForm, setShowBuyForm ] = useState(false);
  const [ showFeedbackForm, setShowFeedbackForm ] = useState(false);
  const [ url, setUrl ] = useState(null);
  const [ feedBacks, setFeedBacks ] = useState(null);
  const refresherIntervalIdRef = useRef(null);

  const getData = async () => {
    return new Promise(async resolve => {
      if(type === 'book') {
        //get book data
        await callApi('get_book_data.php', { bookId: id }).then(response => {
          if(response.status === 1) {
            //setData
            setData(response.bookData);
          } else {
            appContext.setError(response.msg);
          }
          resolve();
        })
      } else if(type === 'course') {
        //get book data
        await callApi('get_course_data.php', { courseId: id }).then(response => {
          if(response.status === 1) {
            //setData
            setData(response.courseData);
          } else {
            appContext.setError(response.msg);
          }
          resolve();
        })
      } else if(type === 'podcast') {
        //get book data
        await callApi('get_podcast_data.php', { courseId: id }).then(response => {
          if(response.status === 1) {
            //setData
            setData(response.podcastData);
          } else {
            appContext.setError(response.msg);
          }
          resolve();
        })
      } else if(type === 'article') {
        //get article data
        await callApi('get_article_data.php', { articleId: id }).then(response => {
          if(response.status === 1) {
            //setData
            setData(response.articleData);
          } else {
            appContext.setError(response.msg);
          }
          resolve();
        })
      } else if(type === 'series') {
        //get series data
        await callApi('get_series_data.php', { seriesId: id }).then(response => {
          if(response.status === 1) {
            //setData
            setData(response.seriesData);
          } else {
            appContext.setError(response.msg);
          }
          resolve();
        })
      }

    })
  }

  const saveForOffline = () => {
    //check if user can save this content for offline
    return new Promise(async resolve => {
      setLoading(true)
      await callApi('request_download.php', { id, type }).then(response => {
        setLoading(false);
        if(response.status === 1) {
          if(window.calipaApp && window.calipaApp.saveContentForOffline) {
            window.calipaApp.saveContentForOffline(JSON.stringify(response.content));
          } else {
            appContext.setError("Please use Updated version of CALIPA Mobile App for this action");
          }
        } else {
          appContext.setError(response.msg);
        }
        resolve()
        
      })
    })
  }

  const getFeedBacks = async () => {
    return new Promise(async resolve => {
      await callApi('get_feedbacks.php', { id, type }).then(response => {
        if(response.status === 1) {
          setFeedBacks(response.feedbacks)
        } else {
          //appContext.setError(response.msg);
        }
        resolve();
      })
    })
  }

  const init = async () => {
    //setData(null);
    setReady(false);
    setLoading(true);
    await getData().then(async () => {
      await getFeedBacks().then(async () => {
        setLoading(false);
        setReady(true);
      })
    })
  }

  const startQuiz = async () => {
    if(inCol) {
      setShowIntro(false);
      setShowReader(false);
      setShowListener(false);
      setShowWatcher(false);
      setShowQuizer(true);
      setShowFeedbackForm(false);
      setShowBuyForm(false);
    } else {
      appContext.setError("Add to your collection first before taking quiz");
    }
  }

  const startBuy = async () => {
    if(canBuy) {
      setShowIntro(false);
      setShowReader(false);
      setShowListener(false);
      setShowWatcher(false);
      setShowQuizer(false);
      setShowFeedbackForm(false);
      setShowBuyForm(true);
    } else {
      appContext.setError("failed to buy this content");
    }
  }

  const startFeedback = async () => {
    if(inCol) {
      setShowIntro(false);
      setShowReader(false);
      setShowListener(false);
      setShowWatcher(false);
      setShowQuizer(false);
      setShowFeedbackForm(true);
      setShowBuyForm(false);
    } else {
      appContext.setError("Add to your collection first before giving feedback");
    }
  }



  const addToCollection = async () => {
    if(appContext.auth === 1) {
      if(!inCol || (appContext.userData && Number(appContext.userData.id) === Number(data.publisher))) {

        setLoading(true);
        await callApi('request_sub.php', { id, type }).then(async response => {
          if(response.status === 1) {
            await appContext.authCheck();
            setInCol(true);
            appContext.setMessage(`${type} was added to your collection`);
            setLoading(false);
          } else {
            setLoading(false);
            appContext.setError(response.msg);
          }
        })
      } else {
        appContext.setError('This content may have already been added to your collection')
      }
    } else {
      appContext.setError("Login or Register please");
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
    }
  }

  const readBook = async () => {
    if(appContext.auth === 1) {
      setLoading(true);
      await callApi('request_view.php', { id, type }).then(async response => {
        if(response.status === 1) {
          await appContext.authCheck();
          setUrl(response.url);
          setLoading(false);
        } else {
          setLoading(false);
          appContext.setError(response.msg);
        }
      })
    } else {
      appContext.setError("Login or Register please");
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
    }
  }

  const watchCourse = async () => {
    if(appContext.auth === 1) {
      setLoading(true);
      await callApi('request_view.php', { id, type }).then(async response => {
        if(response.status === 1) {
          await appContext.authCheck();
          setUrl(response.url);
          setLoading(false);
        } else {
          setLoading(false);
          appContext.setError(response.msg);
        }
      })
    } else {
      appContext.setError("Login or Register please");
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
    }
  }

  const copyLink = () => {
    //copy link
    let _type = btoa(type);
    let _id = btoa(id);

    let link = `${WEB_URL}?d=${_type}-${_id}`;
    copyText(link);
    appContext.setMessage('Content Link was copied');
    //tellUser link was copied
  }
  const listenPodcast = async () => {
    if(appContext.auth === 1) {
      setLoading(true);
      await callApi('request_view.php', { id, type }).then(async response => {
        if(response.status === 1) {
          await appContext.authCheck();
          setUrl(response.url);
          setLoading(false);
        } else {
          setLoading(false);
          appContext.setError(response.msg);
        }
      })
    } else {
      appContext.setError("Login or Register please");
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
    }
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    if(url) {
      if(type === 'book') {
        setShowIntro(false);
        setShowReader(true);
        setShowListener(false);
        setShowWatcher(false);
        setShowQuizer(false);
        setShowFeedbackForm(false);
        setShowBuyForm(false);
      } else if(type === 'course') {
        setShowIntro(false);
        setShowReader(false);
        setShowListener(false);
        setShowWatcher(true);
        setShowQuizer(false);
        setShowBuyForm(false);
        setShowFeedbackForm(false);
      } else if(type === 'podcast') {
        setShowIntro(false);
        setShowReader(false);
        setShowListener(true);
        setShowWatcher(false);
        setShowQuizer(false);
        setShowFeedbackForm(false);
        setShowBuyForm(false);
      }
    } else {
      setShowIntro(true);
      setShowReader(false);
      setShowListener(false);
      setShowWatcher(false);
      setShowQuizer(false);
      setShowFeedbackForm(false);
      setShowBuyForm(false);
    }
  }, [ url ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    setCanBuy(() => canUserBuy(data));
    setInCol(() => checkInCol(type, data));
  }, [ data, type ]);

  useEffect(() => {
    init();
  }, [ type, id ]);

  // useEffect(() => {
  //   init();
  // }, [ appContext.navSubItem, appContext.navExtraItem ]);

  useEffect(() => {
    setType(props.type);
    setId(props.id);
  }, [ props.type, props.id ]);

  const contentViewContext = {
    setUrl,
    url,
    setShowIntro,
    setShowListener,
    setShowWatcher,
    setShowReader,
    setShowQuizer,
    setShowFeedbackForm,
    setShowBuyForm,
    data,
    type,
    refresherIntervalIdRef,
  }

  if(ready) {
    if(data) {
      //....
      if(data.moded === 'yes') {
        //..
        if(showIntro) {
          if(type === 'article') {
            //handle special case for article
            return (
              <ContentViewContext.Provider value={contentViewContext}>
                <div 
                  className=""
                  style={{
                    maxWidth: "960px",
                    margin: "0 auto",
                    padding: "0"
                  }}
                >
                  
                  <div className="row">
                    <div className="col-md-12" style={{ padding: "10px" }}>
                      <h1 
                        className=""
                        style={{
                          color: "var(--on-background)",
                          fontSize: "35px"
                        }}
                      >
                        {data.title}
                      </h1>
                      {
                        (data.type === 'paid') ?
                        <>
                          <h6 className="font-bold" style={{ color: "#c99504" }}>CALIPA+ Premium Article</h6>
                        </>
                        :
                        <h6 className="text-muted">Free Article</h6> 
                      }
                      <small className="text-muted font-bold">{data.category}</small>
                    </div>
      
                    <div className="col-md-12" style={{ minHeight: "var(--top-bar-height)", paddingTop: "20px", paddingBottom: "20px", }} >
                      <AuthorThumb
                        data={{
                          bio: data.authorBio,
                          name: data.authorName,
                          id: data.publisher,
                        }}
                      />
                      <div className="d-flex justify-content-between">
                        <small
                          style={{
                            marginTop: "20px"
                          }}
                          className="text-muted align-self-center"
                        >
                          <AiOutlineEye size={25}/>
                          {formatMoneyShorten(data.views)}
                        </small>
      
                        <small
                          style={{
                            marginTop: "20px"
                          }}
                          className="text-muted align-self-center"
                        >
                          {data.date}
                        </small>
                      </div>
                    </div>
      
                    <div className="col-md-12" style={{ paddingTop: "30px", paddingBottom: "10px", }} >
                      
                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-outline-light align-self-center"
                          style={{
                            marginRight: "10px"
                          }}
                          onClick={copyLink}
                        >
                          <AiOutlineShareAlt size={20}/> Share
                        </button>
      
                      </div>
                    </div>
      
                    <div className="col-md-12" style={{ marginTop: "10px", marginBottom: "10px", overflowX:"hidden", padding: "10px" }}>
                      <img src={BASE_API+data.coverPhoto} width="100%" height="auto" style={{ borderRadius: "20px" }}/>
                    </div>
                    
      
                    <div className="col-md-12" style={{ padding: "10px", overflowX: "hidden", marginTop: "10px", marginBottom: "20px" }}>
                      <ArticleMainBody data={data} />
                    </div>
    
                    <div className="col-md-12" style={{ paddingTop: "30px", paddingBottom: "10px", }} >
                      
                      <button
                        className="btn btn-sm btn-outline-light align-self-center"
                        style={{
                          marginRight: "10px"
                        }}
                        onClick={addToCollection}
                      >
                        Add To Collection
                      </button>
                      <button
                        className="btn btn-sm btn-outline-light align-self-center"
                        style={{
                          marginRight: "10px"
                        }}
                        onClick={startFeedback}
                      >
                        Rate / Comment
                      </button>
                    </div>
                    
                    <div className="col-md-12" style={{ paddingBottom: "50px" }}>
                      <div className="text-start" style={{ marginTop:"20px" }}>
                        <h4 className="font-light">Audience Feedback</h4>
      
                        {
                          (feedBacks) ?
                          <div className="mStarsBar d-flex justify-content-start">
                            <div className="mStar">
                              {
                                (feedBacks.rating >= 1) ?
                                <AiFillStar className="mStarIcon"/>
                                :
                                <AiOutlineStar className="mStarIcon"/>
                              }
                            </div>
      
                            <div className="mStar">
                              {
                                (feedBacks.rating >= 2) ?
                                <AiFillStar className="mStarIcon"/>
                                :
                                <AiOutlineStar className="mStarIcon"/>
                              }
                            </div>
                            <div className="mStar">
                              {
                                (feedBacks.rating >= 3) ?
                                <AiFillStar className="mStarIcon"/>
                                :
                                <AiOutlineStar className="mStarIcon"/>
                              }
                            </div>
                            <div className="mStar">
                              {
                                (feedBacks.rating >= 4) ?
                                <AiFillStar className="mStarIcon"/>
                                :
                                <AiOutlineStar className="mStarIcon"/>
                              }
                            </div>
                            <div className="mStar">
                              {
                                (feedBacks.rating >= 5) ?
                                <AiFillStar className="mStarIcon"/>
                                :
                                <AiOutlineStar className="mStarIcon"/>
                              }
                            </div>
                          </div>
                          :
                          <></>
                        }
                        <small className="form-text">{feedBacks.rating} Stars Average Rating</small>
                        <hr/>
                        {/* comments follow*/}
                        {
                          (feedBacks) ?
                          <div style={{ width: "100%" }}>
                            <h5>Comments ({feedBacks.comments.length})</h5>
                            {
                              (feedBacks.comments.length > 0) ?
                              feedBacks.comments.map((item, i) => {
                                return (
                                  <div key={i} className="text-start" style={{ wordWrap: "break-word", width: "100%", overflow: "hidden", borderLeft: "5px solid orange", marginTop: "20px", paddingLeft: "10px" }}>
                                    <p>{item.comment}</p>
                                    <h6 className="text-muted font-light text-end">
                                      <small>By {(item.username.trim().length > 0) ? item.username : "Anonymous user"} </small>
                                      <br/>
                                      <small>On {item.date}</small>
                                    </h6>
                                  </div>
                                )
                              }): <small className="text-muted">No comments were found</small>
                            }
                          </div>: ""
                        }
                      </div>
                    </div>
                    
                  </div>
                  
                </div>
              </ContentViewContext.Provider>
            )
          } else if(type === 'series') {
            //handle special case for series
            return (
              <ContentViewContext.Provider value={contentViewContext}>
                <div 
                  className=""
                  style={{
                    maxWidth: "960px",
                    margin: "0 auto",
                    padding: "0"
                  }}
                >
                  <img src={BASE_API+data.coverPhoto} width="100%" height="auto" style={{ }}/>
                  <div className="row">
                    <div className="col-md-6" style={{ padding: "10px" }}>
                      <h1 
                        className=""
                        style={{
                          color: "var(--on-background)",
                          fontSize: "35px",
                          marginTop: "10px"
                        }}
                      >
                        {data.title}
                      </h1>
                      <h6 className="text-primary" style={{ textTransform: "capitalize", fontSize: "14px" }}>
                        {(data.seriesType === 'course') ? "Video" : data.seriesType}s
                      </h6>
                      <small className="text-muted font-bold">{data.category}</small>
                      {
                        (Number(data.price) > 0) ?
                        <div className="text-end">
                          <h6 className="font-light text-secondary text-end" style={{ fontSize: "30px", marginTop: "30px" }}>{formatMoney(data.price)} Tshs</h6>
                          
                          {
                            (canBuy) ?
                            <button
                              onClick={startBuy}
                              style={{
                                padding: "10px",
                              }}
                              className="btn bg-secondary"
                            >
                              <MdOutlineAddShoppingCart size={20}/> Buy Now
                            </button>
                            : 
                            ""
                          }
                        </div>
                        :
                        <h6 className="text-secondary text-end" style={{ marginTop: "30px" }}>Free Series</h6> 
                      }
                      
                      <div className="form-group" style={{ marginTop: "20px" }}>
                        <hr/>
                        <AuthorThumb
                          data={{
                            bio: data.authorBio,
                            name: data.authorName,
                            id: data.publisher,
                          }}
                        />
                      </div>
                    </div>
      
                    
        
                    <div className="col-md-6" style={{ padding: "10px", overflowX: "hidden", marginTop: "10px", marginBottom: "20px" }}>
                      <hr/>
                      <h6 className="font-bold text-muted" style={{ marginTop: "0px" }}>All items in this series</h6>
                      {
                       (data.contents) ?
                       data.contents.map((item, i) => {
                        return (
                          <ContentFeedMin mId={data.seriesType+'_'+item.id} data={item} type={data.seriesType} key={i}/>
                        )
                      })
                      :""
                      }
                      
                    </div>
                  
                    
                  </div>
                  
                </div>
              </ContentViewContext.Provider>
            )
          } else {
            //...
            return (
              <ContentViewContext.Provider value={contentViewContext}>
                <div className="row">
                  <div className="col-md-6" style={{ padding: "0" }}>
                    <div className="ContentInfo">
    
                      <div className="mBanner">
                        <div className="mCover">
                          <p className="mTitle">
                            {data.title}
                          </p>
                          
                        </div>
                        <img src={logo}/>
                      </div>
    
                      <div className="mInfo">
                        <img src={BASE_API+data.coverPhoto}/>
    
                        <div className="row mSpacerMargin" style={{ padding:"10px" }}>
    
                          <div className="col-12" style={{ marginBottom: "10px" }}>
                            <h1 style={{ margin: "0" }}>
                              

                              {
                                (data.seriesData) ?
                                <>
                                  <span className="font-regular" style={{ display: "block", fontSize: "14px" }}>Complete Series of "<span className="font-bold">{data.seriesData.title}</span>" with {data.seriesData.contents.length} item(s) for:</span>
                                  {
                                    (Number(data.seriesData.price) > 0) ? 
                                    <>
                                      <span className="font-regular">{formatMoney(data.seriesData.price)+" Tsh"} </span><br/>
                                    </>
                                    : 
                                    <span className="">Free</span>
                                  }
                                </>
                                :
                                <>{(Number(data.price) > 0) ? formatMoney(data.price)+" Tsh" : "Free"}</>
                  
                              }
  
                            </h1>
                            <small className="form-text">Price</small>
                          </div>
    
                          <div className="col-12" style={{ marginBottom: "30px", marginTop: "30px" }}>
                            <AuthorThumb
                              data={{
                                bio: data.authorBio,
                                name: data.authorName,
                                id: data.publisher,
                              }}
                            />
                          </div>

                          {
                            (data.seriesData) ?
                            <div className="col-12" style={{ marginBottom: "10px" }}>
                              <h6 style={{ margin: "0", textTransform:"capitalize" }}>{data.seriesData.title}</h6>
                              <small className="form-text">Series Title</small>
                            </div>
                            :
                            ""
                          }
  
                          <div className="col-12" style={{ marginBottom: "10px" }}>
                            <h6 style={{ margin: "0", textTransform:"capitalize" }}>{data.category}</h6>
                            <small className="form-text">Category</small>
                          </div>
    
                          <div className="col-6" style={{ marginBottom: "10px" }}>
                            <h6 style={{ margin: "0", textTransform:"capitalize" }}>{type}</h6>
                            <small className="form-text">Type</small>
                          </div>
    
                          <div className="col-6" style={{ marginBottom: "10px" }}>
                            <h6 style={{ margin: "0" }}>{data.views}</h6>
                            <small className="form-text">Views</small>
                          </div>
    
                        </div>
    
    
                        <div className="text-center" style={{ padding:"10px" }}>
                          {
                            (type === 'book') ?
                            <button onClick={readBook} className="btn mBtn bg-primary">
                              <MdOutlineRemoveRedEye size={20}/><br/>Read
                            </button> : ""
                          }
    
                          {
                            (type === 'course') ?
                            <button onClick={watchCourse} className="btn mBtn bg-primary">
                              <MdOutlineRemoveRedEye size={20}/><br/>Watch
                            </button> : ""
                          }
    
                          {
                            (type === 'podcast') ?
                            <button onClick={listenPodcast} className="btn mBtn bg-primary">
                              <MdOutlineAudiotrack size={20}/><br/>Listen
                            </button> : ""
                          }
    
                          <button onClick={copyLink} className="btn mBtn bg-primary">
                            <MdShare size={20}/><br/>Share
                          </button>
    
                          {
                            (canBuy) ?
                            <button onClick={startBuy} className="btn mBtn bg-primary">
                              <MdOutlineAddShoppingCart size={20}/><br/>Buy
                            </button> : ""
                          }
                        </div>
  
                        <div className="text-center" style={{ padding:"10px" }}>
                          <button onClick={saveForOffline} className="btn mBtn bg-secondary">
                            <BsDownload/>&nbsp;
                            Save For Offline
                          </button>
                        </div>
    
                        {
                          (!inCol) ?
                          <div className="text-center" style={{ padding:"10px" }}>
                            <button onClick={addToCollection} className="btn mBtn bg-secondary">Add to your Collection</button>
                          </div>: ""
                        }
    
                        {
                          (Number(data.quizId) > 0) ?
                          <div onClick={startQuiz} className="text-center" style={{ padding:"10px" }}>
                            <button className="btn mBtn bg-secondary">Take Quiz to get Certified</button>
                          </div> : ""
                        }
  
                        <div className="text-center" style={{ padding:"10px" }}>
                          <button onClick={startFeedback} className="btn mBtn bg-secondary">Rate this content or Give Feedback</button>
                        </div>
    
                      </div>
                    </div>
                  </div>
    
                  <div className="col-md-6" style={{ }}>

                    <div className="text-start" style={{ marginTop:"40px" }}>
                      <h4 className="font-light">Content Description</h4>
                      <pre className="font-regular mDescriptionHolder" style={{ background: "none", fontSize: "15px", }}>
                        {data.description}
                      </pre>
                    </div>

                    {
                      (data.seriesData) ?
                      <div className="text-start" style={{ marginTop:"40px" }}>
                        <h4 className="font-light">Series Description</h4>
                        <pre className="font-regular mDescriptionHolder" style={{ background: "none", fontSize: "15px", }}>
                          {data.seriesData.description}
                        </pre>

                        <h4 className="font-light" style={{ marginTop: "40px" }}>All items in this series</h4>
                        {
                          data.seriesData.contents.map((item, i) => {
                            return (
                              <ContentFeedMin mId={data.seriesData.seriesType+'_'+item.id} data={item} type={data.seriesData.seriesType} key={i}/>
                            )
                          })
                        }
                      </div>
                      :
                      ""
                    }
    
                    <div className="text-start" style={{ marginTop:"40px" }}>
                      <h4 className="font-light">Audience Feedback</h4>
    
                      {
                        (feedBacks) ?
                        <div className="mStarsBar d-flex justify-content-start">
                          <div className="mStar">
                            {
                              (feedBacks.rating >= 1) ?
                              <AiFillStar className="mStarIcon"/>
                              :
                              <AiOutlineStar className="mStarIcon"/>
                            }
                          </div>
    
                          <div className="mStar">
                            {
                              (feedBacks.rating >= 2) ?
                              <AiFillStar className="mStarIcon"/>
                              :
                              <AiOutlineStar className="mStarIcon"/>
                            }
                          </div>
                          <div className="mStar">
                            {
                              (feedBacks.rating >= 3) ?
                              <AiFillStar className="mStarIcon"/>
                              :
                              <AiOutlineStar className="mStarIcon"/>
                            }
                          </div>
                          <div className="mStar">
                            {
                              (feedBacks.rating >= 4) ?
                              <AiFillStar className="mStarIcon"/>
                              :
                              <AiOutlineStar className="mStarIcon"/>
                            }
                          </div>
                          <div className="mStar">
                            {
                              (feedBacks.rating >= 5) ?
                              <AiFillStar className="mStarIcon"/>
                              :
                              <AiOutlineStar className="mStarIcon"/>
                            }
                          </div>
                        </div>
                        :
                        <></>
                      }
                      <small className="form-text">{(feedBacks) ? feedBacks.rating : 5} Stars Average Rating</small>
                      <hr/>
                      {/* comments follow*/}
                      {
                        (feedBacks) ?
                        <div style={{ width: "100%" }}>
                          <h5>Comments ({feedBacks.comments.length})</h5>
                          {
                            (feedBacks.comments.length > 0) ?
                            feedBacks.comments.map((item, i) => {
                              return (
                                <div key={i} className="text-start" style={{ wordWrap: "break-word", width: "100%", overflow: "hidden", borderLeft: "5px solid orange", marginTop: "20px", paddingLeft: "10px" }}>
                                  <p>{item.comment}</p>
                                  <h6 className="text-muted font-light text-end">
                                    <small>By {(item.username.trim().length > 0) ? item.username : "Anonymous user"} </small>
                                    <br/>
                                    <small>On {item.date}</small>
                                  </h6>
                                </div>
                              )
                            }): <small className="text-muted">No comments were found</small>
                          }
                        </div>: ""
                      }
                    </div>
    
                  </div>
                </div>
              </ContentViewContext.Provider>
            )
    
            //...1
          }
        } else {
          if(showReader) {
            return (
              <ContentViewContext.Provider value={contentViewContext}>
                <BookReader/>
              </ContentViewContext.Provider>
            )
          } else if(showWatcher) {
            return (
              <ContentViewContext.Provider value={contentViewContext}>
                <CourseWatcher/>
              </ContentViewContext.Provider>
            )
          } else if(showListener) {
            return (
              <ContentViewContext.Provider value={contentViewContext}>
                <PodcastListener/>
              </ContentViewContext.Provider>
            )
          } else if(showQuizer) {
            return (
              <ContentViewContext.Provider value={contentViewContext}>
                <TakeQuiz id={data.quizId}/>
              </ContentViewContext.Provider>
            )
          } else if(showFeedbackForm) {
            return (
              <ContentViewContext.Provider value={contentViewContext}>
                <FeedbackForm />
              </ContentViewContext.Provider>
            )
          } else if(showBuyForm) {
            return (
              <ContentViewContext.Provider value={contentViewContext}>
                <BuyForm />
              </ContentViewContext.Provider>
            )
          }
        }
        //....
        
      } else {
        return (
          <div className="row">
            <div className="col-md-12 support-loading">
              <h6 className="text-muted font-light">This content is still under moderation, please wait for our moderators to review it first</h6>
            </div>
          </div>
        )
      }
      //...
    } else {
      return (
        <div className="row">
          <div className="col-md-12 support-loading">
            <h6 className="text-muted font-light">Unknown error occurred, this content may be unavaliable or you do not have permission to view it</h6>
          </div>
        </div>
      )
    }
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }

}
