import React, { Component } from 'react';

export default function QuizQn(props) {

  return (
    <div className="card" style={{ marginBottom:"10px" }}>
      <div className="card-body">
        <h5 className="card-title">#{props.number}</h5>
        <div className="form-group">
          <label>Question</label>
          <textarea className="form-control" defaultValue={(props.qn !== undefined) ? props.qn.question : '' }></textarea>
        </div>
        <div className="form-group">
          <label>Right Answer</label>
          <input defaultValue={(props.qn !== undefined) ? props.qn.rightAnswer : '' } className="form-control"/>
        </div>
        <div className="form-group">
          <label>Wrong Answer 1</label>
          <input defaultValue={(props.qn !== undefined) ? props.qn.wrongAnswers[0] : '' } className="form-control"/>
        </div>
        <div className="form-group">
          <label>Wrong Answer 2</label>
          <input defaultValue={(props.qn !== undefined) ? props.qn.wrongAnswers[1] : '' } className="form-control"/>
        </div>
        <div className="form-group">
          <label>Wrong Answer 3</label>
          <input defaultValue={(props.qn !== undefined) ? props.qn.wrongAnswers[2] : '' } className="form-control"/>
        </div>
      </div>
    </div>
  )
}
