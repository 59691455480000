import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';
import { getInlineLoader, giv, callApi } from '../Helper';
import TermsPrivacy from './TermsPrivacy';
import TermsRefund from './TermsRefund';
import TermsService from './TermsService';


export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const init = () => {
    setReady(true);
  }


  useEffect(() => {
    init();
  }, [ ]);


  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"40px" }}>
          <h4 className="font-light text-secondary">Terms of service</h4>
          <TermsService/>
        </div>

        <div className="col-12" style={{ marginBottom:"40px" }}>
          <h4 className="font-light text-secondary">Privacy Policy</h4>
          <TermsPrivacy/>
        </div>

        <div className="col-12" style={{ marginBottom:"40px" }}>
          <h4 className="font-light text-secondary">Refund Policy</h4>
          <TermsRefund/>
        </div>
      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
