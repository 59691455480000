import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader,giv, callApi } from '../Helper';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const init = () => {
    if(appContext.auth === 1) {
      //stay here
      setReady(true);
    } else {
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
    }
  }

  const logOut = async () => {
    //log out
    setLoading(true);
    appContext.deleteLocalUsers().then(async () => {
      //send log out req
      await callApi('logout.php', { }).then(async () => {
        appContext.setMessage('You were logged out');
        appContext.refresh();
        
      })
    })
  }

  const updateDetails = async () => {
    if(!loading) {
      let username = giv('_username');
      let bio = giv('_bio');

      if(username.trim().length > 0) {
        if(bio.trim().length > 0) {
          setLoading(true);
          await callApi('update_details.php', { username, bio }).then(response => {
            setLoading(false);
            if(response.status === 1) {
              appContext.setMessage('Your details were updated successfully')
            } else {
              appContext.setError(response.msg);
            }
          })
        } else {
          appContext.setError('Invalid bio');
        }
      } else {
        appContext.setError('Invalid username');
      }
    }
  }

  const changePwd = async () => {
    if(!loading) {
      let oldPwd = giv('_oldPwd');
      let newPwd = giv('_newPwd');
      let confirmPwd = giv('_confirmPwd');

      if(oldPwd.trim().length > 0) {
        if(newPwd.trim().length >= 6) {
          if(newPwd === confirmPwd) {
            setLoading(true);
            await callApi('change_password.php', { newPwd, oldPwd }).then(response => {
              setLoading(false);
              if(response.status === 1) {
                appContext.setMessage('Password was changed successfully');
              } else {
                appContext.setError(response.msg);
              }
            })
          } else {
            appContext.setError('Please confirm your new password correctly');
          }
        } else {
          appContext.setError('Invalid new password. password must have atleast 6 characters');
        }
      } else {
        appContext.setError('Invalid current password');
      }
    }
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"40px" }}>
          <h4 className="font-light">Account & Profile settings</h4>
          <small className="text-muted font-light" style={{ display: "block"}} >{appContext.userData.email}</small>
          <button
            className="btn btn-dark"
            style={{
              marginTop: "20px"
            }}
            onClick={logOut}
          >
            Log Out
          </button>
        </div>

        <div className="col-md-6" style={{ marginBottom:"20px" }}>
          <h6 className="font-semi-bold text-primary">Username & Bio</h6>
          <div className="text-start">
            <div className="form-group">
              <label className="text-muted">Username</label>
              <input id="_username" type="text" defaultValue={appContext.userData.username} className="form-control"/>
            </div>
            <div className="form-group">
              <label className="text-muted">Bio</label>
              <textarea id="_bio" rows={4} defaultValue={appContext.userData.bio} className="form-control"/>
            </div>
            <div className="form-group text-end">
              <button onClick={updateDetails} className="btn bg-primary text-light">Update Details</button>
            </div>
          </div>
        </div>


        <div className="col-md-6" style={{ marginBottom:"20px" }}>
          <h6 className="font-semi-bold text-primary">Password</h6>
          <div className="text-start">
            <div className="form-group">
              <label className="text-muted">Current Password</label>
              <input type="password" id="_oldPwd" className="form-control"/>
            </div>

            <div className="form-group">
              <label className="text-muted">New password Password</label>
              <input type="password" id="_newPwd" className="form-control"/>
            </div>

            <div className="form-group">
              <label className="text-muted">Confirm new Password</label>
              <input type="password" id="_confirmPwd"  className="form-control"/>
            </div>

            <div className="form-group text-end">
              <button onClick={changePwd} className="btn bg-primary text-light">Change Password</button>
            </div>
          </div>
        </div>



      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
