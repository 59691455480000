import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';
import { getInlineLoader, giv, callApi } from '../Helper';
import TermsPrivacy from './TermsPrivacy';
import TermsRefund from './TermsRefund';
import TermsService from './TermsService';


export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const init = () => {
    setReady(true);
  }


  useEffect(() => {
    init();
  }, [ ]);


  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"40px" }}>
          <h4 className="font-light text-secondary">About CALIPA</h4>
          Online books, seminars and courses platform for Authors, Publishers, motivational speakers, and Readers.
          Acquire the best knowledge and skills at your finger tips and get paid for sharing it here.
          You have an option of using the website or an App (CALIPA), which you can download from Playstore by pressing here.
          It is simple and easy. Just sign up, register or login on the platform and you’re good to go. No permission required. No computer skills needed.
          It’s free to register and post your work in numerous formats like PDF, podcast, MP4 etc.
          CALIPA will market your content to the world for you for free.
          You can set quizzes and issue certificates on the platform with easy.
          What else.…..you will earn whole lots of money by using this tried and trusted platform just by posting your educational valuable contents.
        </div>

        <div className="col-12" style={{ marginBottom:"40px" }}>
          <h4 className="font-light text-secondary">Contact Us</h4>
          <h6 className="text-muted font-light">For any problem, suggestion or help reach to us via the contacts below</h6>
          <h6 style={{ fontSize: "16px" }}>+255 787 559 510 (Whatsapp and Calls)</h6>
          <h6 style={{ fontSize: "16px" }}>+255 749 487 578 (Whatsapp and Calls)</h6>
          <h6 style={{ fontSize: "16px" }}>support@calipa.co.tz</h6>
          <h6 style={{ fontSize: "16px" }}>developer@calipa.co.tz</h6>
          <h6 style={{ fontSize: "16px" }}>moderator@calipa.co.tz</h6>
        </div>


      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
