import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { openBlog } from '../Helper';
import { IoIosArrowForward } from 'react-icons/io';

export default function View(props) {
  const appContext = useContext(AppContext);

  const launchOfflineMode = () => {
    window.calipaApp.launchOfflineMode();
  }

  return (
    <div className="row">
      <div className="col-12" style={{ marginBottom:"20px" }}>
        {
          (appContext.auth && appContext.userData) ?
          <h4 className="font-light">Hi {appContext.userData.username}, welcome back to CALIPA</h4> :
          <h4 className="font-light">Hi there, welcome to CALIPA</h4>
        }
      </div>

      <div className="col-md-6">

        <div
          onClick={() => {
            appContext.navTo({
              item: 'account',
              subItem: 'collections',
            })
          }}
          className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
          <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
            Your Collections<br/>
            <small className="text-muted font-light">Books, Courses, Articles and Podcasts you've bought, bookmarked or subscribed to</small>
          </h6>
          <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
        </div>

        <div
          onClick={() => {
            appContext.navTo({
              item: 'account',
              subItem: 'profile',
            })
          }}
          className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
          <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
            Profile & Account settings
          </h6>
          <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
        </div>

        <div
          onClick={() => {
            appContext.navTo({
              item: 'account',
              subItem: 'calipa_plus',
            })
          }}
          className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
          <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
            <span className="font-bold text-secondary">CALIPA+</span> Subscription<br/>
            <small className="text-muted font-light">With <span className="font-bold text-secondary">CALIPA+</span> Subscription you can access all premium articles</small>
          </h6>
          <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
        </div>

        <div
          onClick={() => {
            appContext.navTo({
              item: 'account',
              subItem: 'notifications',
            })
          }}
          className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
          <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
            Your Notifications<br/>
          </h6>
          <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
        </div>

        

        <div
          onClick={() => {
            appContext.navTo({
              item: 'account',
              subItem: 'certificates',
            })
          }}
          className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
          <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
            Your Certificates<br/>
            <small className="text-muted font-light">Certificates provided after Quiz completion</small>
          </h6>
          <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
        </div>

      </div>

      <div className="col-md-6">
        <div onClick={() => appContext.navTo({ item: 'account', subItem: 'terms' })} className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
          <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
            Terms & Policies<br/>
            <small className="text-muted font-light">Terms of service, Refund policy & Privacy Policy</small>
          </h6>
          <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
        </div>

        <div onClick={() => appContext.navTo({ item: 'publisher' })} className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
          <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
            Publisher Dashboard<br/>
            <small className="text-muted font-light">Publish and Manage your content on our platform</small>
          </h6>
          <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
        </div>

        <div onClick={() => appContext.navTo({ item: 'account', subItem: 'about' })} className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
          <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
            About CALIPA & Support<br/>
            <small className="text-muted font-light">About, Contact and Support service</small>
          </h6>
          <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
        </div>

        {
          (window.calipaApp && window.calipaApp.launchOfflineMode) ?
          <div onClick={launchOfflineMode}  className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
            <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
              View saved content<br/>
              <small className="text-muted font-light">Click to view contents you saved for offline use</small>
            </h6>
            <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
          </div> :
          ""

        }
      </div>

    </div>
  )
}
