import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { BiUser } from 'react-icons/bi';
import { ContentViewContext } from '../views/ContentView';
import { getInlineLoader, callApi } from "../Helper";


export default function ArticleMainBody(props) {
  const appContext = useContext(AppContext);
  const contentViewContext = useContext(ContentViewContext);

  const [ data, setData ] = useState(props.data);
  const [ content, setContent ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ tellSub, setTellSub ] = useState(false);
  const [ tellLogin, setTellLogin ] = useState(false);

  const requestContent = async () => {
    setLoading(true);
    await callApi('request_view_article.php', { id: data.id, type: 'article' }).then(async response => {
      if(response.status === 1) {
        await appContext.authCheck();
        setContent(response.content);
        setTellLogin(false);
        setTellSub(false);
        setLoading(false);
      } else {
        setLoading(false);
        
        if(response.status === 2) {
          setTellLogin(true);
          setTellSub(false);
          setContent(null);
        } else if(response.status === 3) {
          setTellLogin(false);
          setTellSub(true);
          setContent(null);
        } else if(response.status === 0) {
          appContext.setError(response.msg);
        }
      }
    })
  }

  const addUniqueView = async () => {
    //this function will be called some minutes after this rticle body has been loaded
    await callApi('add_unique_view.php', { id: data.id, type: 'article' }).then(response => {
      //no need to implement it here
      console.log('Sending unique view request');
      console.log(response);
    })
  }

  const init = async () => {
    await requestContent();
    
  }

  useEffect(() => {
    init();
    let timeoutId = setTimeout(() => {
      addUniqueView();
    }, 90000);

    return () => {
      clearTimeout(timeoutId);
    }
  }, [])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ])

  if(!loading) {
    return (
      <>
        {
          (content) ?
          <div style={{ width: "100%", padding: "0px", margin: "0px" }}>
            <hr/>
            <div 
              className=""
              style={{
                width: "100%",
                height:"auto",
                padding: "0px",
              }}
              dangerouslySetInnerHTML={{__html: content}}
            >
              
            </div>
            <hr/>
          </div> : ""
        }

        {
          (tellLogin) ?
          <div className="text-center" style={{ width: "100%", padding: "0px", margin: "0px" }}>
            <h5 className="font-light">You need to Login or Register in order to view CALIPA+ Premium content like this one</h5>
            <div className="text-center">
              <button
                className="btn bg-primary"
                style={{
                  padding: "15px",
                  width: "100px",
                }}
                onClick={() => {
                  appContext.navTo({
                    item: 'account',
                    subItem: 'login',
                  }, true);
                }}
              >
                LOGIN
              </button>

              &nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp;

              <button
                className="btn bg-secondary"
                style={{
                  padding: "15px",
                  width: "100px",
                }}
                onClick={() => {
                  appContext.navTo({
                    item: 'account',
                    subItem: 'register',
                  }, true);
                }}
              >
                REGISTER
              </button>
            </div>
            <hr/>
          </div> : ""
        }

        {
          (tellSub) ?
          <div className="text-center" style={{ width: "100%", padding: "0px", margin: "0px" }}>
            <h5 className="font-light">You need <span className="font-bold text-secondary">CALIPA+</span> Subscription in order to view premium articles like this one</h5>
            <div className="text-center">
              
              <button
                className="btn bg-secondary"
                style={{
                  padding: "15px",
                  width: "100%",
                  fontSize: "20px",
                }}
                onClick={() => {
                  appContext.navTo({
                    item: 'account',
                    subItem: 'calipa_plus',
                  });
                }}
              >
                Buy <span className="font-bold">CALIPA+</span> Subscription
              </button>

              <br/>
              <h6 className="text-primary" style={{ marginTop: "20px" }}>Starts at 5,000 Tsh Per Month</h6>
            </div>
            <hr/>
          </div> : ""
        }


      </>
    )
  } else {
    return (
      <div className="support-loading" style={{ width: "100%", padding: "0px", margin: "0px" }}>
        {getInlineLoader()}
      </div>
    )
  }
}
