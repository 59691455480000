import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../App';
import { MdLightMode, MdSettings, MdArrowBack, MdDarkMode, MdOutlineDarkMode, MdNotifications } from 'react-icons/md';
import logo from '../assets/logo.png';

export default function TopBar(props) {
  const appContext = useContext(AppContext);

  if(appContext.navItem === 'search' && !appContext.showViewer) {
    return <></>
  } else {
    return (
      <div className="TopBar" data-hidetopbar={appContext.hideTopBar}>
        <div className="container d-flex justify-content-between" style={(appContext.showViewer) ? { height:"100%", padding:"0px", zIndex: "inherit" } : { height:"100%", padding:"0px" }}>
          <p  className="align-self-center mTitle noMargin noPad">
            <MdArrowBack onClick={() => appContext.goBack()} size={30} style={{ cursor: "pointer" }}/>&nbsp;
            <span className="">CALIPA</span>
          </p>
          <MdLightMode style={{ cursor: 'pointer' }} onClick={() => appContext.toggleTheme()} size={30} className="align-self-center noMargin noPad" color='var(--on-background)'/>
        </div>
      </div>
    )
  }
}
