import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { PublisherContext } from "../main-views/PublisherPanel";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader, formatMoney, callApi, giv } from '../Helper';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';

export default function View(props) {
  const appContext = useContext(AppContext);
  const publisherContext = useContext(PublisherContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ type, setType ] = useState('paid');
  const [ price, setPrice ] = useState(0);
  const [ title, setTitle ] = useState("");
  const [ category, setCategory ] = useState('technology');
  const [ description, setDescription ] = useState('');
  const [ uploadedTitle, setUploadedTitle ] = useState(false);
  const [ selectedTags, setSelectedTags ] = useState([]);

  const [ seriesId, setSeriesId ] = useState("");

  const init = () => {
    setReady(true);
  }

  const addTag = (tag) => {
    
    if(selectedTags.length < 3) {
      setSelectedTags(prev => {
        let _i = prev.indexOf(tag);
        if(_i === -1) {
          //add it
          return [ ...prev, tag ];
        } else {
          return prev;
        }
        
      })
    }
  }

  const removeTag = (tag) => {
    //..
    setSelectedTags(prev => {
      let _i = prev.indexOf(tag);
      if(_i > -1) {
        //remove it
        return prev.splice(_i, 1);
      } else {
        return prev;
      }
      
    })
    //..
  }


  const loadCoverPhoto = (e) => {
    let img = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsDataURL(img)
    fileReader.onload = function () {
      document.getElementById('_coverPhotoImg').src = fileReader.result;
    }
  }

  const upload = async () => {
    if(!loading) {
      setUploadedTitle(false);
      let coverPhoto = document.getElementById('_coverPhoto').files[0];
      let file = document.getElementById('_file').files[0];
      if(file) {
        if(coverPhoto) {
          if(title.trim().length > 0) {
            if(type.trim().length > 0) {
              if(selectedTags.length > 0) {
                if(description.trim().length > 0) {

                  let _seriesId = (seriesId) ? seriesId : "none";
                  if((!seriesId && ((type === 'paid' && !isNaN(price) && Number(price) >= 1000) || (type === 'free' && Number(price) === 0))) || seriesId) {
                    setLoading(true);
                    await callApi('create_new_course.php', { seriesId: _seriesId, title, type, price, category:selectedTags.toString(), description, file, coverPhoto }).then(async response => {
                      if(response.status === 1) {
                        await publisherContext.getPublisherData();
                        setLoading(false);
                        setUploadedTitle(title);
                        appContext.setMessage('Your course was uploaded');
                        setType('paid');
                        setCategory('technology');
                        setSeriesId("");
                        setPrice(0);
                        setDescription('');
                        setTitle('');
                        document.getElementById('_coverPhoto').value = "";
                        document.getElementById('_file').value = "";
                        document.getElementById('_coverPhotoImg').src = logo;
                        appContext.scrollToTop();
                      } else {
                        setLoading(false);
                        appContext.setError(response.msg);
                      }
                    })
                  } else {
                    appContext.setError("Invalid Price, minimum content price is 1000");
                  }
                } else {
                  appContext.setError("Invalid Description");
                }
              } else {
                appContext.setError("Invalid category");
              }
            } else {
              appContext.setError("Invalid type");
            }
          } else {
            appContext.setError("Invalid title");
          }
        } else {
          appContext.setError('Invalid Cover Photo');
        }
      } else {
        appContext.setError('Invalid video file');
      }
    }
  }

  useEffect(() => {
    if(type === 'free') {
      setPrice(0);
    }
  }, [ type ])

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"20px" }}>
          <h4 className="font-light">Upload New Course</h4>
        </div>

        {
          (uploadedTitle) ?
          <div className="col-12" style={{ marginBottom:"20px" }}>
            <div className="z-depth-1 bg-primary" style={{ width: "100%", padding: "10px", wordWrap: "break-word" }}>
              <h6 className="font-light">{uploadedTitle} was uploaded successfully</h6>
            </div>
          </div>: ""
        }

        <div className="col-md-6" style={{ marginBottom:"40px" }}>
          <input onChange={loadCoverPhoto} hidden={true} id="_coverPhoto" type="file" accept="image/*"/>
          <small className="form-text font-light">Click the image below to select a cover photo for your course <span className="text-secondary">(recommended ratio is 2:1 )</span></small>
          <div onClick={() => document.getElementById('_coverPhoto').click()} className="CoverPhotoUploader text-center">
            <img src={logo} id="_coverPhotoImg"/>
          </div>
        </div>

        <div className="col-md-6" style={{ marginBottom:"40px" }}>

          <div className="form-group">
            <label>Assign to series</label>
            <select value={seriesId} onChange={(e) => { setSeriesId(e.target.value) }} className="form-control">
              <option value="" >DO NOT ASSIGN SERIES</option>
              {
                (publisherContext.publisherData && publisherContext.publisherData.series && publisherContext.publisherData.series.length > 0) ?
                publisherContext.publisherData.series.map((item, i) => {
                  if(item.seriesType === 'course') {
                    return (
                      <option key={i} value={item.id}>{item.title} - {item.seriesType} series</option>
                    )
                  }
                })
                : ""
              }
            </select>
          </div>

          {
            (!seriesId) ?
            <>
            <div className="form-group">
              <label>Type</label>
              <select value={type} onChange={(e) => { setType(e.target.value) }} className="form-control">
                <option value="paid" >Paid</option>
                <option value="free" >Free</option>
              </select>
            </div>

            {
              (type === 'paid') ?
              <div className="form-group">
                <label>Price in Tsh</label>
                <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} className="form-control"/>
              </div>: ""
            }
            </>
            :
            ""
          }

          <div className="form-group">
            <label>Course Title (70 chars max)</label>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} maxLength={70} className="form-control"/>
          </div>

          <div className="form-group">
            <label>Course File (Only mp4, webm or ogg files, 1000MBs max)</label>
            <input type="file" id="_file" className="form-control"/>
          </div>

          <div className="form-group">
            <label>Categories & Tags</label>
            <div className="text-start">
              {
                (appContext.systemParams.contentTags && appContext.systemParams.contentTags.length > 0) ?
                appContext.systemParams.contentTags.map((item, i) => {
                  if(selectedTags.indexOf(item) > -1) {
                    //selected tag
                    return (
                      <button
                        className="btn btn-sm"
                        key={i}
                        style={{
                          color: "var(--on-primary)",
                          background: "var(--primary-color)",
                          margin: "5px",
                        }}
                        onClick={() => removeTag(item)}
                      >
                        {item}
                      </button>
                    )
                  } else {
                    //tag not sselected
                    return (
                      <button
                        className="btn btn-sm"
                        key={i}
                        style={{
                          color: "var(--primary-color)",
                          background: "none",
                          margin: "5px",
                        }}
                        onClick={() => addTag(item)}
                      >
                        {item}
                      </button>
                    )
                  }
                }): ""
              }
            </div>
            <h6 className="form-text">Select up to 3</h6>
          </div>

          <div className="form-group">
            <label>Course Description</label>
            <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="form-control"/>
          </div>

          <div className="form-group text-end">
            <button onClick={upload} className="btn bg-primary text-light" style={{ minWidth: "100px", height: "60px" }}>Upload</button>
          </div>

        </div>

      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
