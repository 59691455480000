import { useState, useContext, createContext } from "react";
import { AppContext } from "../App";
import { getInlineLoader } from "../Helper";
import logo from '../assets/logo.png';

export default function MainLoader(props) {
  const appContext = useContext(AppContext);

  return (
    <div className="MainLoader" style={{ background: "var(--background-color) !important", zIndex: "9999999999" }}>
      {getInlineLoader()}
      <div className="text-center" style={{ position: "absolute", bottom:"20vh", width:"100%" }}>
        <img src={logo} style={{ width: "200px", height: "auto"}}/>
        <h3 className="font-bold text-muted">CAS Life Purpose Academy</h3>
      </div>
    </div>
  )
}
