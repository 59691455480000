import { useState, useContext, createContext, useEffect } from "react";
import QuizQn from '../ones/QuizQn';
import { AppContext } from "../App";
import { shuffleArray, BASE_API } from '../Helper';
import $ from 'jquery';

export default function NewQuiz (props) {
  const appContext = useContext(AppContext);
  const [  ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const saveQuiz = () => {
    //get data for all questions
    let quizQnsContainer = document.getElementById('_quizQns');
    let quizQns = [];
    for(let i = 0; i < quizQnsContainer.children.length; i++){
      let qnContainer = quizQnsContainer.children[i];
      let question = qnContainer.children[0].children[1].children[1].value;
      let rightAnswer = qnContainer.children[0].children[2].children[1].value;
      let answers = [
        rightAnswer,
        qnContainer.children[0].children[3].children[1].value,
        qnContainer.children[0].children[4].children[1].value,
        qnContainer.children[0].children[5].children[1].value,
      ];
      let wrongAnswers = [
        qnContainer.children[0].children[3].children[1].value,
        qnContainer.children[0].children[4].children[1].value,
        qnContainer.children[0].children[5].children[1].value,
      ];
      answers = shuffleArray(answers);

      if(question.trim().length > 0 &&
         answers[0].trim().length > 0 &&
         answers[1].trim().length > 0 &&
         answers[2].trim().length > 0 &&
         answers[3].trim().length > 0
      ){

        quizQns.push({
          question, rightAnswer, answers, wrongAnswers
        });
      }
      else{
        //.. not valid question
      }
    }

    if(quizQns.length > 0){
      //stringfy this object and send it to the server
      quizQns = JSON.stringify(quizQns);
      let reqData = {
        id: props.contentId,
        type: props.contentType,
        quizQns,
      }
      //console.log(reqData);
      setLoading(true);
      $.post(BASE_API+"save_quiz.php", reqData, (data, status) => {
        setLoading(false);
        if(status === 'success'){
          let response = JSON.parse(data);
          if(response.status === 1){
            appContext.setMessage('Quiz was saved');
          }
          else{
            appContext.setError(response.msg);
          }
        }
        else{
          appContext.setError('Network error');
        }
      });
    }
    else{
      //.. no valid question was found
      appContext.setError('No valid question was found in your quiz');
      appContext.setError('Atleast 1 Question should have all answers filled');
    }
  }

  const init = () => {
    setReady(true);
  }

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    init()
  }, [ ])

  if(ready) {
    return (
      <div style={{ width: "100%" }}>
        <h3 className="sectionTitle">Questions</h3>

        <div id="_quizQns" style={{ width:"100%", }}>
          <QuizQn number="1"/>
          <QuizQn number="2"/>
          <QuizQn number="3"/>
          <QuizQn number="4"/>
          <QuizQn number="5"/>
          <QuizQn number="6"/>
          <QuizQn number="7"/>
          <QuizQn number="8"/>
          <QuizQn number="9"/>
          <QuizQn number="10"/>
          <QuizQn number="11"/>
          <QuizQn number="12"/>
          <QuizQn number="13"/>
          <QuizQn number="14"/>
          <QuizQn number="15"/>
          <QuizQn number="16"/>
          <QuizQn number="17"/>
          <QuizQn number="18"/>
          <QuizQn number="19"/>
          <QuizQn number="20"/>
        </div>

        <div className="text-end" style={{ width:"100%", marginTop:"20px", marginBottom:"20px" }}>
          <button onClick={() => saveQuiz()} className="btn bg-primary text-light">Save Quiz</button>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
