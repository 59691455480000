import axios from 'axios';
import Account from './main-views/Account';
import Content from './main-views/Content';
import Search from './main-views/Search';
import Login from './views/Login';
import ResetPassword from './views/ResetPassword';
import AccountProfile from './views/AccountProfile';
import Collections from './views/Collections';
import AllCerts from './views/AllCerts';
import AccountVerification from './views/AccountVerification';
import Register from './views/Register';
import NewsFeed from './views/NewsFeed';
import PublisherPanel from './main-views/PublisherPanel';
import ContentView from './views/ContentView';
import Notifications from './views/Notifications';
import Terms from './views/Terms';
import About from './views/About';
import CertificateViewer from './views/CertificateViewer';
import CalipaPlus from './views/CalipaPlus';
import BuyCalipaPlus from './views/BuyCalipaPlus';
import AuthorView from './views/AuthorView';

//export const BASE_API = "https://calipa.co.tz/api_test/"; //dev
export const BASE_API = "/api/"; //production

export const WEB_URL = 'https://calipa.co.tz/v'; //production
//export const WEB_URL = 'http://localhost/github_projects/calipa/api/index.php'; //dev

export async function copyText(text) {
  //navigator.clipboard.writeText(text);
  try {
    window.calipaApp.copyText(text);
  } catch (e) {
    //probably running on web
    await forceCopy(text).then(response => {
      //alert(response)
    }).catch((e) => {
      //alert(e.message)
    });
  }
}

export function textShortener(text, length=50) {
  if(text.trim().length > length) {
    return text.substring(0, length)+"...";
  } else {
    return text;
  }
}

export function forceCopy(text) {
  return new Promise(async (resolve, reject) => {
    if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
      // const type = "text/plain";
      // const blob = new Blob([text], { type });
      // const data = [new ClipboardItem({ [type]: blob })];
      // navigator.permissions.query({name: "clipboard-write"}).then((permission) => {
      //     if (permission.state === "granted" || permission.state === "prompt") {
      //         navigator.clipboard.write(data).then(resolve, reject).catch(reject);
      //     }
      //     else {
      //         reject(new Error("Permission not granted!"));
      //     }
      // });
      await navigator.clipboard.writeText(text).then(() => { });
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";
      textarea.style.width = '2em';
      textarea.style.height = '2em';
      textarea.style.padding = 0;
      textarea.style.border = 'none';
      textarea.style.outline = 'none';
      textarea.style.boxShadow = 'none';
      textarea.style.background = 'transparent';
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
          document.execCommand("copy");
          document.body.removeChild(textarea);
          resolve();
      }
      catch (e) {
          document.body.removeChild(textarea);
          reject(e);
      }
    }
    else {
      reject(new Error("None of copying methods are supported by this browser!"));
    }
  });

}

export function openBlog() {
  try {
    window.calipaApp.openBlog();
  } catch (e) {
    //probably running on web
    window.open('https://calipa.co.tz/blog', '_blank');
  }
}

export function openExternalUrl(url) {
  try {
    window.calipaApp.openExternalUrl(url);
  } catch (e) {
    //probably running on web
    //window.open(url, '_blank');
    window.location.href = url;
  }
}

export function getViewerView(type, id) {
  if(type === 'book') {
    return <ContentView type={type} id={id}/>
  } else if(type === 'course') {
    return <ContentView type={type} id={id}/>
  } else if(type === 'podcast') {
    return <ContentView type={type} id={id}/>
  } else if(type === 'article') {
    return <ContentView type={type} id={id}/>
  } else if(type === 'series') {
    return <ContentView type={type} id={id}/>
  } else if(type === 'buy_calipa_plus' && id) {
    return <BuyCalipaPlus period={id}/>;
  }
}

export function isContainerAtEnd(element, container) {
  
  //use scroll positions to check if it's at the end of the 
  const scrollHeight = container.scrollHeight;
  const scrollWidth = container.scrollWidth;
  const scrollTop = container.scrollTop;
  const scrollLeft = container.scrollLeft;


  // console.log('scrollHeight', scrollHeight);
  // console.log('scrollTop', scrollTop);
  // console.log('scrollWidth', scrollWidth);
  // console.log('scrollLeft', scrollLeft);

  const dope = 100; //dope with 100px

  if((scrollTop + dope) >= scrollHeight/2) {
    return true;
  } else if((scrollLeft + dope) >= scrollWidth/2) {
    return true;
  } else {
    return false;
  }
  
}



export  function shuffleArray(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


export function  resolveMainView(app) {
  //takes appContext and returns view basing on nav
  if(app.navItem === "content" && !app.navSubItem && !app.navExtraItem) {
    return <Content/>
  } else if(app.navItem === "search" && !app.navSubItem && !app.navExtraItem) {
    return <Search/>
  } else if(app.navItem === "content" && app.navSubItem && !app.navExtraItem) {
    return <NewsFeed isHome={false} isSearch={false} type={app.navSubItem} category={false}/>
  } else if(app.navItem === "authors" && app.navSubItem && !app.navExtraItem) {
    return <AuthorView id={app.navSubItem}/>
  } else if(app.navItem === "content" && app.navSubItem && app.navExtraItem) {
    return <NewsFeed isHome={false} isSearch={false} type={app.navSubItem} category={app.navExtraItem}/>
  } else if(app.navItem === "account" && !app.navSubItem && !app.navExtraItem) {
    return <Account/>
  } else if(app.navItem === "account" && app.navSubItem === 'login' && !app.navExtraItem) {
    return <Login/>
  } else if(app.navItem === "account" && app.navSubItem === 'reset' && !app.navExtraItem) {
    return <ResetPassword/>
  } else if(app.navItem === "account" && app.navSubItem === 'register' && !app.navExtraItem) {
    return <Register/>
  }  else if(app.navItem === "account" && app.navSubItem === 'verification' && !app.navExtraItem) {
    return <AccountVerification/>
  } else if(app.navItem === "account" && app.navSubItem === 'profile' && !app.navExtraItem) {
    return <AccountProfile/>
  } else if(app.navItem === "account" && app.navSubItem === 'collections' && !app.navExtraItem) {
    return <Collections/>
  } else if(app.navItem === "account" && app.navSubItem === 'certificates' && !app.navExtraItem) {
    return <AllCerts/>
  } else if(app.navItem === "account" && app.navSubItem === 'calipa_plus' && !app.navExtraItem) {
    return <CalipaPlus/>
  } else if(app.navItem === "account" && app.navSubItem === 'notifications' && !app.navExtraItem) {
    return <Notifications/>
  } else if(app.navItem === "account" && app.navSubItem === 'terms' && !app.navExtraItem) {
    return <Terms/>
  } else if(app.navItem === "account" && app.navSubItem === 'about' && !app.navExtraItem) {
    return <About/>
  } else if(app.navItem === "publisher") {
    return <PublisherPanel/>
  } else if(app.navItem === "view") {
    return (
      <div className='container'>
        {getViewerView(app.navSubItem, app.navExtraItem)}
      </div>
    );
  } else if(app.navItem === "view-cert" && app.navSubItem && app.navExtraItem) {
    return <CertificateViewer userId={app.navSubItem} quizId={app.navExtraItem} />;
  } else {
    return <Content/>
  }
}

export function getInlineLoader() {
  return (
    <div className="Loader">
    </div>
  )
}


export function giv(id) {
  return document.getElementById(id).value;
}

export function callApi (url, params) {
  return new Promise(async resolve => {
    //call main api
    let formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }

    axios.post(BASE_API+url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then(response => {
      console.log(url);
      console.log(response.data);
      resolve(response.data);
    }).catch(error => {
      resolve({
        status: 0,
        msg: "Network Connection Error",
      })
    })
  })
}

export function formatDate(timestamp, fromPhp = true) {
  timestamp = (fromPhp) ? Number(timestamp) * 1000 : timestamp;
  let date = new Date(timestamp);
  return (date.toLocaleString());
}

export function dateForInput(timestamp = null , fromPhp = true) {
  let date = '';
  if(timestamp){
    if(fromPhp){
      date = new Date(Number(timestamp) * 1000);
    }
    else {
      date = new Date(timestamp);
    }
  }
  else {
    date = new Date();
  }
  return new Date(date.getTime() + date.getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);

}

export function formatMoney(x) {
  //round x to 2 decimal places before og function
  x = Number(x);
  x = x.toFixed(0);

  //then continue
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function formatSecs(secs) {
  secs = Math.floor(secs);
  let hr = Math.floor(secs / 3600);
  let min = Math.floor((secs % (3600)) / 60);
  let _secs = secs % 60;

  if(min < 10) {
    min = '0'+String(min);
  }

  if(_secs < 10) {
    _secs = '0'+String(_secs);
  }

  return String(hr)+":"+String(min)+":"+String(_secs);
}

export function formatMoneyShorten(num, digits = 2) {
  num = Number.parseFloat(num);
  if(num <= 1 && num >= -1 ) {
    return num;
  }

  const lookup = [
   { value: 1, symbol: "" },
   { value: 1e3, symbol: "K" },
   { value: 1e6, symbol: "M" },
   { value: 1e9, symbol: "B" },
   { value: 1e12, symbol: "T" },
   { value: 1e15, symbol: "P" },
   { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
