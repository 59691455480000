import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader,giv, callApi, formatDate } from '../Helper';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ calipaPlus, setCalipaPlus ] = useState(null);

  const getCalipaPlus = () => {
    return new Promise(async resolve => {
      setLoading(true);
      await callApi('get_calipa_plus.php', {  }).then(response => {
        setLoading(false);
        if(response.status === 1) {
          setCalipaPlus(response.calipaPlus);
          resolve(true)
        } else {
          setCalipaPlus(null);
          resolve(false);
        }
      })
    })
  }

  const init = async () => {
    if(appContext.auth === 1) {
      //stay here
      await getCalipaPlus();
      setReady(true);
    } else {
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
    }
  }



  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"40px" }}>
          <h4 className="font-light"><span className="text-secondary font-bold">CALIPA+</span> Subscription</h4>
          <small className="text-muted font-light">Get Access to all Premium articles from our best authors</small>
        </div>

        {
          (calipaPlus && calipaPlus.status) ?
          <div className="col-md-12" style={{ marginBottom:"20px" }}>
            <h6 className="font-semi-bold text-primary">Current subscription</h6>
            <div className="text-start">
              <h6 className="font-light">
                You have a valid <span className="font-bold text-secondary">CALIPA+</span> subscription untill {formatDate(calipaPlus.end)}
              </h6>
            </div>
          </div> 
          :
          <div className="col-md-12" style={{ marginBottom:"20px" }}>
            <h6 className="font-semi-bold text-primary">Buy New subscription</h6>
            <div className="text-start">
              <div className="row">
                <div className="col-md-6" style={{ marginTop: "20px" }}>
                  <div className="card z-depth-1">
                    <div className="card-body">
                      <div className="text-center">
                        <h3 className="font-bold text-primary">1 Month</h3>
                        <h1 className="font-light">
                          5,000 Tsh
                        </h1>
                        <h6 className="font-light text-muted">
                          <strike>6,000 Tsh</strike>
                        </h6>
                        <hr/>
                        <h6 className="">
                          Access all premium articles for 30 Days
                        </h6>
                        <button 
                          className="btn bg-primary"
                          style={{
                            width: "100%",
                            padding: "10px",
                            fontSize: "20px"
                          }}
                          onClick={() => appContext.navTo({
                            item: 'view',
                            subItem: 'buy_calipa_plus',
                            extraItem: 'monthly',
                          })}
                        >
                          BUY NOW
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6" style={{ marginTop: "20px" }}>
                  <div className="card z-depth-1">
                    <div className="card-body">
                      <div className="text-center">
                        <h3 className="font-bold text-primary">1 Year</h3>
                        <h1 className="font-light">
                          50,000 Tsh
                        </h1>
                        <h6 className="font-light text-muted">
                          <strike>72,000 Tsh</strike>
                        </h6>
                        <hr/>
                        <h6 className="">
                          Access all premium articles for 12 Months
                        </h6>
                        <button 
                          className="btn bg-primary"
                          style={{
                            width: "100%",
                            padding: "10px",
                            fontSize: "20px"
                          }}
                          onClick={() => appContext.navTo({
                            item: 'view',
                            subItem: 'buy_calipa_plus',
                            extraItem: 'yearly',
                          })}
                        >
                          BUY NOW
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        }

      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
