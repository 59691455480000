import React, { Component, Fragment } from 'react';
import QuizTestQn from './QuizTestQn';

export default class QuizTest extends Component {

  constructor(props){
    super(props);
    this.state = {
      ready: false,
    }
    this.qnsView = [];
    this.submittedAnswers = [];
  }

  componentDidMount(){
    //create qns view
    this.createQnsView();
  }

  answerCallback = (answerObj) => {
    if(this.submittedAnswers.length > 0){
      this.submittedAnswers.forEach((item, i) => {
        if(Number(answerObj.index) === Number(item.index)){
          //just update the answer
          this.submittedAnswers.splice(i, 1);
          this.submittedAnswers.push(answerObj);
          return;
        }
        else{
          if(Number(i) === Number(this.submittedAnswers.length - 1)){
            this.submittedAnswers.push(answerObj);
            return;
          }
        }
      });
    }
    else{
      this.submittedAnswers.push(answerObj);
    }
  }

  createQnsView = () => {
    this.qnsView = this.props.quizQns.map((item, index) => {
      return (
        <QuizTestQn answerCallback={this.answerCallback} key={index} index={(index+1)} qn={item}/>
      );
    });

    this.setState({
      ready: true,
    });
  }

  markQuiz = () => {
    //get aswers and send them to the server
    let answers = JSON.stringify(this.submittedAnswers);
    this.props.sendQuizAnswers(answers);
  }

  render(){
    //console.log(this.props.qn);
    if(this.state.ready === true){
      return (
        <Fragment>
          {this.qnsView}
          <div style={{ width:"100%", marginTop:"30px" }}  className="text-end">
            <button onClick={() => { this.markQuiz() }} className="btn bg-primary text-light">Submit Answers</button>
          </div>
        </Fragment>
      )
    }
    else{
      return (
        <Fragment>
        </Fragment>
      )
    }
  }
}
