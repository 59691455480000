import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast, CgArrowRightR } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { MdSearch } from 'react-icons/md';
import { RiVideoChatLine } from 'react-icons/ri';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';
import Categories from '../ones/Categories';
import FeedTail from '../ones/FeedTail';
import SwipeTabs from "../components/SwipeTabs";
import SeriesFeed from '../ones/SeriesFeed';
import { MdLightMode, MdArrowBack, MdPlaylistAddCheck } from "react-icons/md";
import { TbBooks } from 'react-icons/tb';
import Banner from '../ones/Banner';

import { callApi, getInlineLoader } from '../Helper';

export const NewsFeedContext = createContext(null);

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ isHome, setIsHome ] = useState(props.isHome);
  const [ isSearch, setIsSearch ] = useState(props.isSearch);
  const [ isSearchInit, setIsSearchInit ] = useState(true);
  const [ type, setType ] = useState(props.type); //for specific content type (courses, books, podcasts)
  const [ category, setCategory ] = useState(props.category);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ booksFeed, setBooksFeed ] = useState(null);
  const [ coursesFeed, setCoursesFeed ] = useState(null);
  const [ articlesFeed, setArticlesFeed ] = useState(null);
  const [ podcastsFeed, setPodcastsFeed ] = useState(null);
  const [ seriesFeed, setSeriesFeed ] = useState(null);

  const [ fBooks, setFbooks ] = useState([]);
  const [ fCourses, setFcourses ] = useState([]);
  const [ fPodcasts, setFpodcasts ] = useState([]);
  const [ fArticles, setFarticles ] = useState([]);
  const [ fSeries, setFseries ] = useState([]);
 
  const [ searchTerm, setSearchTerm ] = useState("");

  const [ currentBooksFeedBatchNo, setCurrentBooksFeedBatchNo ] = useState(0);
  const [ currentCoursesFeedBatchNo, setCurrentCoursesFeedBatchNo ] = useState(0);
  const [ currentPodcastsFeedBatchNo, setCurrentPodcastsFeedBatchNo ] = useState(0);
  const [ currentArticlesFeedBatchNo, setCurrentArticlesFeedBatchNo ] = useState(0);
  const [ currentSeriesFeedBatchNo, setCurrentSeriesFeedBatchNo ] = useState(0);

  const [ isFetching1, setIsFetching1 ] = useState(false);
  const [ isFetching2, setIsFetching2 ] = useState(false);
  const [ isFetching3, setIsFetching3 ] = useState(false);
  const [ isFetching4, setIsFetching4 ] = useState(false);
  const [ isFetching5, setIsFetching5 ] = useState(false);
  const [ showTail, setShowTail ] = useState(false);

  const fetchBooksFeed = (isLazyLoad = false, batchNo = 1) => {
    return new Promise(async resolve => {
      //let batchNo = 1;
      let filter = '';

      if(isHome) {
        //specifics
        //leave the defaults
      }

      if(isSearch) {
        filter = searchTerm;
      }

      if(type) {
        if(category) {
          filter = category;
        }
      }

      setIsFetching1(true);
      await callApi('fetch_books_feed.php', { batchNo, filter }).then(response => {
        setIsFetching1(false);
        if(response.status === 1) {
          if(response.books) {
            setBooksFeed(feed => {
              if(type || isSearch) {
                if(feed) {
                  if(feed.books) {
                    //if(Number(response.books.batchNo) === Number(Number(feed.books.batchNo) + 1)) {
                    if(isLazyLoad) {
                      setFbooks([...fBooks, ...response.books.batchItems]);
                      return response;
                    } else {
                      //leave old list of books
                      setFbooks(response.books.batchItems);
                      return feed;
                    }
                  } else {
                    setFbooks([...response.books.batchItems]);
                    return response;
                  }
                } else {
                  setFbooks(response.books.batchItems);
                  return response;
                }
              } else {
                setFbooks(response.books.batchItems);
                return response;
              }
            });
            setCurrentBooksFeedBatchNo(response.books.batchNo);
          } else {
            setBooksFeed(response);
          }
        } else {
          appContext.setError(response.msg);
        }
      })

      resolve();
    })
  }

  const fetchPodcastsFeed = (isLazyLoad = false, batchNo = 1) => {
    return new Promise(async resolve => {
      //let batchNo = 1;
      let filter = '';

      if(isHome) {
        //specifics
        //leave the defaults
      }

      if(isSearch) {
        filter = searchTerm;
      }

      if(type) {
        if(category) {
          filter = category;
        }
      }

      setIsFetching3(true);
      await callApi('fetch_podcasts_feed.php', { batchNo, filter }).then(response => {
        setIsFetching3(false);
        if(response.status === 1) {
          if(response.podcasts) {
            setPodcastsFeed(feed => {
              if(type || isSearch) {
                if(feed) {
                  if(feed.podcasts) {
                    //if(Number(response.books.batchNo) === Number(Number(feed.books.batchNo) + 1)) {
                    if(isLazyLoad) {
                      setFpodcasts([...fPodcasts, ...response.podcasts.batchItems]);
                      return response;
                    } else {
                      //leave old list of books
                      setFpodcasts(response.podcasts.batchItems);
                      return feed;
                    }
                  } else {
                    setFpodcasts([...response.podcasts.batchItems]);
                    return response;
                  }
                } else {
                  setFpodcasts(response.podcasts.batchItems);
                  return response;
                }
              } else {
                setFpodcasts(response.podcasts.batchItems);
                return response;
              }
            });
            setCurrentPodcastsFeedBatchNo(response.podcasts.batchNo);
          } else {
            setPodcastsFeed(response);
          }
        } else {
          appContext.setError(response.msg);
        }
      })

      resolve();
    })
  }

  const fetchArticlesFeed = (isLazyLoad = false, batchNo = 1) => {
    return new Promise(async resolve => {
      //let batchNo = 1;
      let filter = '';

      if(isHome) {
        //specifics
        //leave the defaults
      }

      if(isSearch) {
        filter = searchTerm;
      }

      if(type) {
        if(category) {
          filter = category;
        }
      }

      setIsFetching4(true);
      await callApi('fetch_articles_feed.php', { batchNo, filter }).then(response => {
        setIsFetching4(false);
        if(response.status === 1) {
          if(response.articles) {
            setArticlesFeed(feed => {
              if(type || isSearch) {
                if(feed) {
                  if(feed.articles) {
                    //if(Number(response.books.batchNo) === Number(Number(feed.books.batchNo) + 1)) {
                    if(isLazyLoad) {
                      setFarticles([...fArticles, ...response.articles.batchItems]);
                      return response;
                    } else {
                      //leave old list of books
                      setFarticles(response.articles.batchItems);
                      return feed;
                    }
                  } else {
                    setFarticles([...response.articles.batchItems]);
                    return response;
                  }
                } else {
                  setFarticles(response.articles.batchItems);
                  return response;
                }
              } else {
                setFarticles(response.articles.batchItems);
                return response;
              }
            });
            setCurrentArticlesFeedBatchNo(response.articles.batchNo);
          } else {
            setArticlesFeed(response);
          }
        } else {
          appContext.setError(response.msg);
        }
      })

      resolve();
    })
  }
  

  const fetchCoursesFeed = (isLazyLoad = false, batchNo = 1) => {
    return new Promise(async resolve => {
      //let batchNo = 1;
      let filter = '';

      if(isHome) {
        //specifics
        //leave the defaults
      }

      if(isSearch) {
        filter = searchTerm;
      }

      if(type) {
        if(category) {
          filter = category;
        }
      }

      setIsFetching2(true);
      await callApi('fetch_courses_feed.php', { batchNo, filter }).then(response => {
        setIsFetching2(false);
        if(response.status === 1) {
          if(response.courses) {
            setCoursesFeed(feed => {
              if(type || isSearch) {
                if(feed) {
                  if(feed.courses) {
                    //if(Number(response.books.batchNo) === Number(Number(feed.books.batchNo) + 1)) {
                    if(isLazyLoad) {
                      setFcourses([...fCourses, ...response.courses.batchItems]);
                      return response;
                    } else {
                      //leave old list of books
                      setFcourses(response.courses.batchItems);
                      return feed;
                    }
                  } else {
                    setFcourses([...response.courses.batchItems]);
                    return response;
                  }
                } else {
                  setFcourses(response.courses.batchItems);
                  return response;
                }
              } else {
                setFcourses(response.courses.batchItems);
                return response;
              }
            });
            setCurrentCoursesFeedBatchNo(response.courses.batchNo);
          } else {
            setCoursesFeed(response);
          }
        } else {
          appContext.setError(response.msg);
        }
      })

      resolve();
    })
  }

  const fetchSeriesFeed = (isLazyLoad = false, batchNo = 1) => {
    return new Promise(async resolve => {
      //let batchNo = 1;
      let filter = '';

      if(isHome) {
        //specifics
        //leave the defaults
      }

      if(isSearch) {
        filter = searchTerm;
      }

      if(type) {
        if(category) {
          filter = category;
        }
      }

      setIsFetching5(true);
      await callApi('fetch_series_feed.php', { batchNo, filter }).then(response => {
        setIsFetching5(false);
        if(response.status === 1) {
          if(response.series) {
            setSeriesFeed(feed => {
              if(type || isSearch) {
                if(feed) {
                  if(feed.series) {
                    //if(Number(response.books.batchNo) === Number(Number(feed.books.batchNo) + 1)) {
                    if(isLazyLoad) {
                      setFseries([...fSeries, ...response.series.batchItems]);
                      return response;
                    } else {
                      //leave old list of books
                      setFseries(response.series.batchItems);
                      return feed;
                    }
                  } else {
                    setFseries([...response.series.batchItems]);
                    return response;
                  }
                } else {
                  setFseries(response.series.batchItems);
                  return response;
                }
              } else {
                setFseries(response.series.batchItems);
                return response;
              }
            });
            setCurrentSeriesFeedBatchNo(response.series.batchNo);
          } else {
            setSeriesFeed(response);
          }
        } else {
          appContext.setError(response.msg);
        }
      })

      resolve();
    })
  }


  const search = async () => {
    if(searchTerm.trim().length > 0) {
      setLoading(true);
      await fetchBooksFeed().then(async () => {
        await fetchCoursesFeed().then(async () => {
          await fetchPodcastsFeed().then(async () => {
            await fetchArticlesFeed().then(async () => {
              await fetchSeriesFeed().then(async () => {
                if(isSearchInit) {
                  setIsSearchInit(false);
                }
                setLoading(false);
              })
            })
          })
        })
      })
    } else {
      appContext.setError('Invalid search term');
    }
  }

  const triggerLazyLoad = async (nextBatch, type, mId) => {
    if(type === 'book') {
      if(Number(nextBatch) >= Number(Number(currentBooksFeedBatchNo) + 1)) {
        //trigger books feed reload
        setShowTail(true);
        await fetchBooksFeed(true, nextBatch).then(() => {
          setShowTail(false);
        })
      }
    } else if(type === 'course') {
      if(Number(nextBatch) >= Number(Number(currentCoursesFeedBatchNo) + 1)) {
        //trigger courses feed reload
        setShowTail(true);
        await fetchCoursesFeed(true, nextBatch).then(() => {
          setShowTail(false);
        })
      }
    } else if(type === 'podcast') {
      if(Number(nextBatch) >= Number(Number(currentPodcastsFeedBatchNo) + 1)) {
        //trigger courses feed reload
        setShowTail(true);
        await fetchPodcastsFeed(true, nextBatch).then(() => {
          setShowTail(false);
        })
      }
    } else if(type === 'article') {
      if(Number(nextBatch) >= Number(Number(currentArticlesFeedBatchNo) + 1)) {
        //trigger articles feed reload
        setShowTail(true);
        await fetchArticlesFeed(true, nextBatch).then(() => {
          setShowTail(false);
        })
      }
    } else if(type === 'series') {
      if(Number(nextBatch) >= Number(Number(currentSeriesFeedBatchNo) + 1)) {
        //trigger series feed reload
        setShowTail(true);
        await fetchSeriesFeed(true, nextBatch).then(() => {
          setShowTail(false);
        })
      }
    }
  }

  const init = async () => {
    //setReady(false);
    if(isSearch) {
      
      setBooksFeed({
        books: {
          batchItems: [],
        }
      })

      setCoursesFeed({
        courses: {
          batchItems: [],
        }
      })

      setPodcastsFeed({
        podcasts: {
          batchItems: [],
        }
      })

      setArticlesFeed({
        articles: {
          batchItems: [],
        }
      })

      setSeriesFeed({
        series: {
          batchItems: [],
        }
      })

      setReady(true);

      
    } else {
      setLoading(true);
      await fetchBooksFeed().then(async () => {
        await fetchCoursesFeed().then(async () => {
          await fetchPodcastsFeed().then(async () => {
            await fetchArticlesFeed().then(async () => {
              await fetchSeriesFeed().then(async () => {
                //more inits
                setReady(true);
                setLoading(false);
              })
            })
          })
        })
      })
    }
  }

  useEffect(() => {
    init();
    
  }, [ ]);

  useEffect(() => {
    setIsHome(props.isHome);
    setIsSearch(props.isSearch);
    setType(props.type);
    setCategory(props.category);
  }, [ props.isHome, props.isSearch, props.type, props.category ]);

  useEffect(() => {
    init();
  }, [ isHome, isSearch, type, category ]);

  useEffect(() => {
    if(isSearch) {
      appContext.setShowOverlayLoader(loading);
    }
  }, [ loading ]);

  useEffect(() => {
    if(booksFeed) {
      //..
    }
  }, [ booksFeed ])

  useEffect(() => {
    if(fBooks) {
      //..
    }
  }, [ fBooks ])

  const newsFeedContext = {
    isHome,
    isSearch,
    isFetching1,
    isFetching2,
    isFetching3,
    isFetching4,
    isFetching5,
    triggerLazyLoad,
    showTail,
    setShowTail,
    currentBooksFeedBatchNo,
    currentCoursesFeedBatchNo,
    currentPodcastsFeedBatchNo,
    currentArticlesFeedBatchNo,
    currentSeriesFeedBatchNo,
  }
  if(ready) {

    if(isHome) {
      return (
        <NewsFeedContext.Provider value={newsFeedContext}>
         
         {/* Trending Books */}
         <div className="row" style={{ marginTop: "40px" }}>
           <div className="col-12">
             <div className="mSectionHeader d-flex justify-content-between">
               <p className="mTitle noMargin align-self-center"><TbBooks className="text-primary" size={40}/> Trending books</p>
               <span onClick={() => { appContext.navTo({ item: 'content', subItem: 'books' }) }} className="mAction noMargin align-self-center text-muted">More &nbsp; <CgArrowRightR size={20}/></span>
             </div>
           </div>
           <div
            className="col-md-12 d-flex mNoScrollbars"
            style={{
              flexDirection: "row",
              overflowX: "auto",
              paddingLeft: "0px",
            }}
           >
            {
             (booksFeed && booksFeed.books && booksFeed.books.batchItems.length > 0) ?
              booksFeed.books.batchItems.map((item, i) => {
                return (
                  <ContentFeed data={item} mId={i} type="book" key={i}/>
                );
              }):
              <div className="col-md-12 mSpacerMargin">
                <h6 style={{ paddingLeft: "20px" }} className="text-muted font-light">No books were found</h6>
              </div>
            }
           </div>
         </div>

         {/* Podcasts */}
         <div className="row" style={{ marginTop: "30px" }}>
           <div className="col-12">
             <div className="mSectionHeader d-flex justify-content-between">
               <p className="mTitle noMargin align-self-center"><MdPodcasts className="text-primary" size={40}/> Trending Podcasts</p>
               <span onClick={() => { appContext.navTo({ item: 'content', subItem: 'podcasts' }) }} className="mAction noMargin align-self-center text-muted">More &nbsp; <CgArrowRightR size={20}/></span>
             </div>
           </div>
           <div
            className="col-md-12 d-flex mNoScrollbars"
            style={{
              flexDirection: "row",
              overflowX: "auto",
              paddingLeft: "0px",
            }}
           >
           {
             (podcastsFeed && podcastsFeed.podcasts && podcastsFeed.podcasts.batchItems.length > 0) ?
             podcastsFeed.podcasts.batchItems.map((item, i) => {
               return (
                 <ContentFeed data={item} mId={i} type="podcast" key={i}/>
               );
             }):
             <div className="col-md-12 mSpacerMargin">
               <h6 style={{ paddingLeft: "20px" }} className="text-muted font-light">No podcasts were found</h6>
             </div>
           }
           </div>
         </div>

         {/* Series */}
         <div className="row" style={{ marginTop: "30px" }}>
           <div className="col-12">
             <div className="mSectionHeader d-flex justify-content-between">
               <p className="mTitle noMargin align-self-center"><MdPlaylistAddCheck className="text-primary" size={40}/> Series For You</p>
               <span onClick={() => { appContext.navTo({ item: 'content', subItem: 'series' }) }} className="mAction noMargin align-self-center text-muted">More &nbsp; <CgArrowRightR size={20}/></span>
             </div>
           </div>
           <>
           {
             (seriesFeed && seriesFeed.series && seriesFeed.series.batchItems.length > 0) ?
             seriesFeed.series.batchItems.map((item, i) => {
               if(i < 5) {
                return (
                  <SeriesFeed data={item} mId={i} type="series" key={i}/>
                );
               }
             }):
             <div className="col-md-12 mSpacerMargin">
               <h6 className="text-muted font-light">No series were found</h6>
             </div>
           }
           </>
         </div>

         {/* Courses */}
         <div className="row" style={{ marginTop: "30px" }}>
           <div className="col-12">
             <div className="mSectionHeader d-flex justify-content-between">
               <p className="mTitle noMargin align-self-center">Top Videos</p>
               <span onClick={() => { appContext.navTo({ item: 'content', subItem: 'courses' }) }} className="mAction noMargin align-self-center text-muted">More videos &nbsp; <CgArrowRightR size={20}/></span>
             </div>
           </div>
           {
             (coursesFeed && coursesFeed.courses && coursesFeed.courses.batchItems.length > 0) ?
             coursesFeed.courses.batchItems.map((item, i) => {
               return (
                 <ContentFeed data={item} mId={i} type="course" key={i}/>
               );
             }):
             <div className="col-md-12 mSpacerMargin">
               <h6 className="text-muted font-light">No videos were found</h6>
             </div>
           }
         </div>
         
         {/* Articles */}
         <div className="row" style={{ marginTop: "30px" }}>
           <div className="col-12">
             <div className="mSectionHeader d-flex justify-content-between">
               <p className="mTitle noMargin align-self-center">Top Articles</p>
               <span onClick={() => { appContext.navTo({ item: 'content', subItem: 'articles' }) }} className="mAction noMargin align-self-center text-muted">More articles &nbsp; <CgArrowRightR size={20}/></span>
             </div>
           </div>
           {
             (articlesFeed && articlesFeed.articles && articlesFeed.articles.batchItems.length > 0) ?
             articlesFeed.articles.batchItems.map((item, i) => {
               return (
                 <ContentFeed data={item} mId={i} type="article" key={i}/>
               );
             }):
             <div className="col-md-12 mSpacerMargin">
               <h6 className="text-muted font-light">No articles were found</h6>
             </div>
           }
         </div>

         

         
        </NewsFeedContext.Provider>
      )
    }

    if(type) {
      return (
        <NewsFeedContext.Provider value={newsFeedContext}>

          {
            (type === 'articles') ?
            <div className="row">
              <div className="col-12" style={{ marginTop: "var(--top-bar-height)" }}>
                <div className="mSectionHeader">
                  <p className="noMargin text-end" style={{ fontSize:"40px", fontFamily: "FontLight" }}>Articles</p>
                </div>
              </div>
              <Categories/>

              {
                (!loading && articlesFeed && articlesFeed.articles && fArticles.length > 0) ?
                <>
                  {
                    fArticles.map((item, i) => {
                      if(Number(i) === Number(Number(fArticles.length) - 1)) {
                        return (
                          <ContentFeed nextBatch={Math.floor(fArticles.length/10) + 1} data={item} mId={'article_'+item.id} type="article" key={i} isLast={true}/>
                        );
                      } else {
                        return (
                          <ContentFeed mId={'article_'+item.id} data={item} type="article" key={i}/>
                        );
                      }
                    })
                  }
                  <FeedTail/>
                </>
                :
                <>
                {
                  (fArticles.length === 0 || !articlesFeed.articles) ?
                  <div className="col-md-12 mSpacerMargin">
                    <h6 className="text-muted font-light">No articles were found</h6>
                  </div> :
                  <div className="col-md-12 mSpacerMargin">
                    <h6 className="text-muted font-light">Loading please wait..</h6>
                  </div>
                }
                </>
              }
            </div> : ""
          }

          {
            (type === 'series') ?
            <div className="row">
              <div className="col-12" style={{ marginTop: "var(--top-bar-height)" }}>
                <div className="mSectionHeader">
                  <p className="noMargin text-end" style={{ fontSize:"40px", fontFamily: "FontLight" }}>Series</p>
                </div>
              </div>
              <Categories/>

              {
                (!loading && seriesFeed && seriesFeed.series && fSeries.length > 0) ?
                <>
                  {
                    fSeries.map((item, i) => {
                      if(Number(i) === Number(Number(fSeries.length) - 1)) {
                        return (
                          <SeriesFeed nextBatch={Math.floor(fSeries.length/10) + 1} data={item} mId={'series_'+item.id} type="series" key={i} isLast={true}/>
                        );
                      } else {
                        return (
                          <SeriesFeed mId={'series_'+item.id} data={item} type="series" key={i}/>
                        );
                      }
                    })
                  }
                  <FeedTail/>
                </>
                :
                <>
                {
                  (fSeries.length === 0 || !seriesFeed.series) ?
                  <div className="col-md-12 mSpacerMargin">
                    <h6 className="text-muted font-light">No series were found</h6>
                  </div> :
                  <div className="col-md-12 mSpacerMargin">
                    <h6 className="text-muted font-light">Loading please wait..</h6>
                  </div>
                }
                </>
              }
            </div> : ""
          }

          {
            (type === 'books') ?
            <div className="row">
              <div className="col-12" style={{ marginTop: "var(--top-bar-height)" }}>
                <div className="mSectionHeader">
                  <p className="noMargin text-end" style={{ fontSize:"40px", fontFamily: "FontLight" }}>Books</p>
                </div>
              </div>
              <Categories/>

              {
                (!loading && booksFeed && booksFeed.books && fBooks.length > 0) ?
                <div
                  className="col-md-12 d-flex mNoScrollbars"
                  id="m-books-container"
                  style={{
                    flexDirection: "row",
                    overflowX: "auto",
                  }}
                >
                  {
                    fBooks.map((item, i) => {
                      if(Number(i) === Number(Number(fBooks.length) - 1)) {
                        return (
                          <ContentFeed nextBatch={Math.floor(fBooks.length/10) + 1} data={item} mId={'book_'+item.id} type="book" key={i} isLast={true}/>
                        );
                      } else {
                        return (
                          <ContentFeed mId={'book_'+item.id} data={item} type="book" key={i}/>
                        );
                      }
                    })
                  }
                  <FeedTail/>
                </div>
                :
                <>
                {
                  (fBooks.length === 0 || !booksFeed.books) ?
                  <div className="col-md-12 mSpacerMargin">
                    <h6 style={{ paddingLeft: "20px" }} className="text-muted font-light">No books were found</h6>
                  </div> :
                  <div className="col-md-12 mSpacerMargin">
                    <h6 style={{ paddingLeft: "20px" }} className="text-muted font-light">Loading please wait..</h6>
                  </div>
                }
                </>
              }
            </div> : ""
          }

          {
            (type === 'courses') ?
            <div className="row">
              <div className="col-12" style={{ marginTop: "var(--top-bar-height)" }}>
                <div className="mSectionHeader">
                  <p className="noMargin text-end" style={{ fontSize:"40px", fontFamily: "FontLight" }}>Videos</p>
                </div>
              </div>
              <Categories/>

              {
                (!loading && coursesFeed && coursesFeed.courses && fCourses.length > 0) ?
                <>
                  {
                    fCourses.map((item, i) => {
                      if(Number(i) === Number(Number(fCourses.length) - 1)) {
                        return (
                          <ContentFeed nextBatch={Math.floor(fCourses.length/10) + 1} data={item} mId={'course_'+item.id} type="course" key={i} isLast={true}/>
                        );
                      } else {
                        return (
                          <ContentFeed mId={'course_'+item.id} data={item} type="course" key={i}/>
                        );
                      }
                    })
                  }
                  <FeedTail/>
                </>
                :
                <>
                {
                  (fCourses.length === 0 || !coursesFeed.courses) ?
                  <div className="col-md-12 mSpacerMargin">
                    <h6 className="text-muted font-light">No videos were found</h6>
                  </div> :
                  <div className="col-md-12 mSpacerMargin">
                    <h6 className="text-muted font-light">Loading please wait..</h6>
                  </div>
                }
                </>
              }
            </div> : ""
          }

          {
            (type === 'podcasts') ?
            <div className="row">
              <div className="col-12" style={{ marginTop: "var(--top-bar-height)" }}>
                <div className="mSectionHeader">
                  <p className="noMargin text-end" style={{ fontSize:"40px", fontFamily: "FontLight" }}>Podcasts</p>
                </div>
              </div>
              <Categories/>

              {
                (!loading && podcastsFeed && podcastsFeed.podcasts && fPodcasts.length > 0) ?
                <div
                  className="col-md-12 d-flex mNoScrollbars"
                  id="m-podcasts-container"
                  style={{
                    flexDirection: "row",
                    overflowX: "auto",
                  }}
                >
                  {
                    fPodcasts.map((item, i) => {
                      if(Number(i) === Number(Number(fPodcasts.length) - 1)) {
                        return (
                          <ContentFeed nextBatch={Math.floor(fPodcasts.length/10) + 1} data={item} mId={'podcast_'+item.id} type="podcast" key={i} isLast={true}/>
                        );
                      } else {
                        return (
                          <ContentFeed mId={'podcast_'+item.id} data={item} type="podcast" key={i}/>
                        );
                      }
                    })
                  }
                  <FeedTail/>
                </div>
                :
                <>
                {
                  (fPodcasts.length === 0 || !podcastsFeed.podcasts) ?
                  <div className="col-md-12 mSpacerMargin">
                    <h6 style={{ paddingLeft: "20px" }} className="text-muted font-light">No podcasts were found</h6>
                  </div> :
                  <div className="col-md-12 mSpacerMargin">
                    <h6 style={{ paddingLeft: "20px" }} className="text-muted font-light">Loading please wait..</h6>
                  </div>
                }
                </>
              }
            </div> : ""
          }
        </NewsFeedContext.Provider>

      )
    }

    if(isSearch) {
      
      return (
        <NewsFeedContext.Provider value={newsFeedContext}>
          
          <div className="TopBar">
            <div className="container d-flex justify-content-between" style={(appContext.showViewer) ? { height:"100%", padding:"0px", zIndex: "inherit" } : { height:"100%", padding:"0px" }}>
              <MdArrowBack onClick={() => appContext.goBack()} size={30} style={{ cursor: "pointer", flexShrink: "0" }} className="align-self-center noMargin noPad"/>
              <form onSubmit={(e) => { e.preventDefault(); search() }} style={{ height: "100%" }} className="align-self-center flex-grow-1 noMargin noPad">
                <div className="mSectionHeader d-flex justify-content-between">
                  <input onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search here...." className="form-control align-self-center flex-grow-1" style={{ }}/>
                </div>
              </form>
              <MdLightMode style={{ cursor: 'pointer', flexShrink: "0", marginLeft: '20px' }} onClick={() => appContext.toggleTheme()} size={30} className="align-self-center noPad" color='var(--on-background)'/>
            </div>
          </div>
          
          {
            !(isSearchInit) ?
            
            <div className="FullPane" id="fullPane" data-support-top-bar={false}>
              <SwipeTabs
                tabs={[
                  {
                    title: "Articles",
                    id: "search-articles",
                    view: (
                      <div className="container">
                        
                        <div className="row">
                          {
                            (!isSearchInit) ?
                            <>
                            {
                              (!loading && articlesFeed && articlesFeed.articles && articlesFeed.articles && fArticles.length > 0) ?
                              <>
                                {
                                  fArticles.map((item, i) => {
                                    if(Number(i) === Number(Number(fArticles.length) - 1)) {
                                      return (
                                        <ContentFeed nextBatch={Math.floor(fArticles.length/10) + 1} data={item} mId={'article_'+item.id} type="article" key={i} isLast={true}/>
                                      );
                                    } else {
                                      return (
                                        <ContentFeed mId={'article_'+item.id} data={item} type="article" key={i}/>
                                      );
                                    }
                                  })
                                }
                                <FeedTail/>
                              </>
                              :
                              <>
                              {
                                (fArticles.length === 0 || !articlesFeed.articles) ?
                                <div className="col-md-12 mSpacerMargin">
                                  <h6 className="text-muted font-light">No articles were found for your search</h6>
                                </div> :
                                <div className="col-md-12 mSpacerMargin">
                                  <h6 className="text-muted font-light">Loading please wait..</h6>
                                </div>
                              }
                              </>
                            }
                            </>: ""
                          }

                        </div>
                        
                      </div>
                    )
                  },
                  {
                    title: "Books",
                    id: "search-books",
                    view: (
                      <div className="container">
                        
                        <div className="row">
                          {
                            (!isSearchInit) ?
                            <>
                            
                            {
                              (!loading && booksFeed && booksFeed.books && booksFeed.books && fBooks.length > 0) ?
                              <div
                                className="col-md-12 d-flex mNoScrollbars"
                                id="m-books-container"
                                style={{
                                  flexDirection: "row",
                                  overflowX: "auto",
                                }}
                              >
                                {
                                  fBooks.map((item, i) => {
                                    if(Number(i) === Number(Number(fBooks.length) - 1)) {
                                      return (
                                        <ContentFeed nextBatch={Math.floor(fBooks.length/10) + 1} data={item} mId={'book_'+item.id} type="book" key={i} isLast={true}/>
                                      );
                                    } else {
                                      return (
                                        <ContentFeed mId={'book_'+item.id} data={item} type="book" key={i}/>
                                      );
                                    }
                                  })
                                }
                                <FeedTail/>
                              </div>
                              :
                              <>
                              {
                                (fBooks.length === 0 || !booksFeed.books) ?
                                <div className="col-md-12 mSpacerMargin">
                                  <h6 style={{ paddingLeft: "20px" }} className="text-muted font-light">No books were found for your search</h6>
                                </div> :
                                <div className="col-md-12 mSpacerMargin">
                                  <h6 style={{ paddingLeft: "20px" }} className="text-muted font-light">Loading please wait..</h6>
                                </div>
                              }
                              </>
                            }
                            </>: ""
                          }

                        </div>
                        
                      </div>
                    )
                  },
                  {
                    title: "Courses",
                    id: "search-courses",
                    view: (
                      <div className="container">
                        
                        <div className="row">
                          {
                            (!isSearchInit) ?
                            <>
                            
                            {
                              (!loading && coursesFeed && coursesFeed.courses && fCourses.length > 0) ?
                              <>
                                {
                                  fCourses.map((item, i) => {
                                    if(Number(i) === Number(Number(fCourses.length) - 1)) {
                                      return (
                                        <ContentFeed nextBatch={Math.floor(fCourses.length/10) + 1} data={item} mId={'course_'+item.id} type="course" key={i} isLast={true}/>
                                      );
                                    } else {
                                      return (
                                        <ContentFeed mId={'course_'+item.id} data={item} type="course" key={i}/>
                                      );
                                    }
                                  })
                                }
                                <FeedTail/>
                              </>
                              :
                              <>
                              {
                                (fCourses.length === 0 || !coursesFeed.courses) ?
                                <div className="col-md-12 mSpacerMargin">
                                  <h6 className="text-muted font-light">No videos were found for your search</h6>
                                </div> :
                                <div className="col-md-12 mSpacerMargin">
                                  <h6 className="text-muted font-light">Loading please wait..</h6>
                                </div>
                              }
                              </>
                            }
                            </>: ""
                          }

                        </div>
                        
                      </div>
                    )
                  },
                  {
                    title: "Podcasts",
                    id: "search-podcasts",
                    view: (
                      <div className="container">
                        <div className="row">
                          {
                            (!isSearchInit) ?
                            <>
                            
                            {
                              (!loading && podcastsFeed && podcastsFeed.podcasts && fPodcasts.length > 0) ?
                              <div
                                className="col-md-12 d-flex mNoScrollbars"
                                id="m-podcasts-container"
                                style={{
                                  flexDirection: "row",
                                  overflowX: "auto",
                                }}
                              >
                                {
                                  fPodcasts.map((item, i) => {
                                    if(Number(i) === Number(Number(fPodcasts.length) - 1)) {
                                      return (
                                        <ContentFeed nextBatch={Math.floor(fPodcasts.length/10) + 1} data={item} mId={'podcast_'+item.id} type="podcast" key={i} isLast={true}/>
                                      );
                                    } else {
                                      return (
                                        <ContentFeed mId={'podcast_'+item.id} data={item} type="podcast" key={i}/>
                                      );
                                    }
                                  })
                                }
                                <FeedTail/>
                              </div>
                              :
                              <>
                              {
                                (fPodcasts.length === 0 || !podcastsFeed.podcasts) ?
                                <div className="col-md-12 mSpacerMargin">
                                  <h6 style={{ paddingLeft: "20px" }} className="text-muted font-light">No podcasts were found for your search</h6>
                                </div> :
                                <div className="col-md-12 mSpacerMargin">
                                  <h6 style={{ paddingLeft: "20px" }} className="text-muted font-light">Loading please wait..</h6>
                                </div>
                              }
                              </>
                            }
                            </>: ""
                          }

                        </div>
        
                      </div>
                    )
                  },
                  {
                    title: "Series",
                    id: "search-series",
                    view: (
                      <div className="container">
                        
                        <div className="row">
                          {
                            (!isSearchInit) ?
                            <>
                            {
                              (!loading && seriesFeed && seriesFeed.series && fSeries && fSeries.length > 0) ?
                              <>
                                {
                                  fSeries.map((item, i) => {
                                    if(Number(i) === Number(Number(fSeries.length) - 1)) {
                                      return (
                                        <SeriesFeed nextBatch={Math.floor(fSeries.length/10) + 1} data={item} mId={'series_'+item.id} type="series" key={i} isLast={true}/>
                                      );
                                    } else {
                                      return (
                                        <SeriesFeed mId={'series_'+item.id} data={item} type="series" key={i}/>
                                      );
                                    }
                                  })
                                }
                                <FeedTail/>
                              </>
                              :
                              <>
                              {
                                (fSeries.length === 0 || !seriesFeed.series) ?
                                <div className="col-md-12 mSpacerMargin">
                                  <h6 className="text-muted font-light">No series were found for your search</h6>
                                </div> :
                                <div className="col-md-12 mSpacerMargin">
                                  <h6 className="text-muted font-light">Loading please wait..</h6>
                                </div>
                              }
                              </>
                            }
                            </>: ""
                          }

                        </div>
                        
                      </div>
                    )
                  },
                ]}
              />
            </div>
            
            :
            <>
              <Banner/>
              
            </>
          }
          
        </NewsFeedContext.Provider>
      )

    }

  } else {
    return (
      <div className="row">
        <div className="col-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }

}
