import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';
import { getInlineLoader, giv, callApi } from '../Helper';


export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const init = () => {
    if(appContext.auth === 1) {
      //go to main panel account
      appContext.superNavTo({
        item: 'account'
      });
    } else if(appContext.auth === 2 ) {
      //go to verification
      appContext.navTo({
        item: 'account',
        subItem: 'verification',
      })
    } else {
      //stay here
      setReady(true);
    }
  }

  const resetPwd = async (e) => {
    e.preventDefault();
    if(!loading) {
      let email = giv('_email');

      if(email.trim().length > 0) {
        setLoading(true);
        await callApi('reset_password.php', { email }).then(response => {

          if(response.status === 1) {
            setLoading(false);
            appContext.setMessage('Check your mail inbox for instructions on how to login');
          } else {
            setLoading(false);
            appContext.setError(response.msg);
          }

        })
      } else {
        appContext.setError('Invalid email address');
      }
    }
  }
  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"20px" }}>
          <h4 className="font-light">Reset Password</h4>
          <small className="form-text">Provide email address you used to register on CALIPA and we'll email you the new pasword</small>
        </div>

        <div className="col-md-6">
          <form onSubmit={(e) => resetPwd(e)}>
            <div className="form-group">
              <label>Email Address</label>
              <input type="email" id="_email" className="form-control"/>
            </div>

            <div className="form-group text-end">
              <button
                className="btn bg-secondary text-dark support-loading"
              >
              {
                (loading) ?
                getInlineLoader() :
                "Reset Password"
              }
              </button>
            </div>
          </form>
          <div className="text-start">
            <small className="form-text">
              You can also <span style={{ cursor: "pointer" }} onClick={() => { appContext.navTo({ item: "account", subItem: "register" }) }} className="font-semi-bold text-primary">Register</span> or <span className="font-semi-bold text-primary" style={{ cursor: "pointer" }} onClick={() => { appContext.navTo({ item: "account", subItem: "login" }) }}>Login</span>
              </small>
          </div>
        </div>

      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
