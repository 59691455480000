import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { NewsFeedContext } from '../views/NewsFeed'
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { BsPersonCircle } from 'react-icons/bs';
import { formatMoney, BASE_API } from '../Helper';
import logo from '../assets/logo.png';
import sample from '../assets/sample-cover.jpeg';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ data, setData ] = useState(props.data);
  const [ type, setType ] = useState(props.type)

  const edit = () => {
    appContext.navTo({
      item: 'publisher',
      subItem: `edit-${type}`,
      extraItem: data.id,
    })
  }

  const quiz = () => {
    appContext.navTo({
      item: 'publisher',
      subItem: `manage-${type}-quiz`,
      extraItem: data.id,
    })
  }

  useEffect(() => {
    setData(props.data);
    setType(props.type);
  }, [ props.data, props.type ]);

  return (
    <div className="text-start z-depth-1" style={{ width: "100%", padding:"10px", marginBottom: "10px" }}>
      <h6 className="font-light">{data.title}</h6>
      <small className="font-light text-muted">{(data.moded === 'yes') ? "Allowed by admin": "Pending admin verification"}</small>
      <div className="text-end" style={{ marginTop: "20px" }}>
        <button onClick={edit} className="btn bg-primary text-light" style={{ margin: "5px" }}>Edit</button>
        <button onClick={quiz} className="btn bg-primary text-light" style={{ margin: "5px" }}>Quiz</button>
      </div>
    </div>
  )
}
