import React, { Component } from 'react';

export default class TermsRefund extends Component {
  render(){
    return (
      <div style={{ width: "100%"}}>
        We stand behind our products and your satisfaction with them is important to us.
        However, we guarantee our esteemed subscribers and readers the validity of information
        and details provided on our sites and App by "us" and third parties though we accept no
        liabilities for any transactions made to third parties except to and through CAS & Media Co. Ltd or
        CAS Life Purpose Academy's verified payment gateways and bank accounts. 
        All claims will be settled within 30 working days and refund made in 30 days after approval of claims.
      </div>
    );
  }
}
