import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import $ from 'jquery';
import { BASE_API, getInlineLoader } from '../Helper';
import QuizTest from '../ones/QuizTest.js';
import { ContentViewContext } from './ContentView';


export default function PTakeQuiz(props) {
  const appContext = useContext(AppContext);
  const contentViewContext = useContext(ContentViewContext);

  const [ quizData, setQuizData ] = useState(null);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ id, setId ] = useState(props.id); //quizId
  const [ taker, setTaker ] = useState("");
  const [ count, setCount ] = useState(0);
  const [ doubleTake, setDoubleTake ] = useState(false);
  const [ score, setScore ] = useState(0);
  const [ date, setDate ] = useState('');

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     auth: 0,
  //     showLoader: true,
  //     viewData: {},
  //     taker: "", *
  //     count: 0, *
  //     booksCol: null,
  //     coursesCol: null,
  //     id: props.id, *
  //     quizData: null, *
  //     certs: null,
  //     doubleTake: false, *
  //     score: 0, *
  //     date: '', *
  //   }
  // }


  const checkDoubleTake = () => {
    console.log(appContext.certs);
    return new Promise(resolve => {
      if(appContext.certs){

        appContext.certs.forEach((item) => {
          if(Number(item.quizId) === Number(id)){
            setDoubleTake(true);
            setScore(item.score);
            setDate(item.date);
            setCount(() => {
              if(item.count) {
                return item.count;
              } else {
                return 1;
              }
            })
            setTaker(() => {
              if(item.taker) {
                return item.taker;
              } else {
                return "";
              }
            })

          }
        })
      }

      resolve();
    })
  }



  const getQuizData = async () => {
    return new Promise(async resolve => {
      setLoading(true);
      await $.post(BASE_API+'get_quiz_data_sub.php', { id }, (data, status) => {
        if(status === "success"){
          let response = JSON.parse(data);
          if(response.status === 1){
            setLoading(false);
            setQuizData(response.quizData);
            resolve();
          }
          else{
            resolve();
            appContext.setError(response.msg);
            //window.history.back();
          }
        }
        else{
          resolve();
          appContext.setError('Network error occured!');
        }
      })
    })
  }

  const sendQuizAnswers = async (answers) => {
    let taker = $('#_quizTaker').val();
    if(taker.trim().length > 0) {
      setLoading(true);
      await $.post(BASE_API+'mark_quiz.php', { answers, quizId:props.id, taker }, (data, status) => {
        setLoading(false);
        if(status === 'success'){
          console.log(data);
          let response = JSON.parse(data);
          if(response.status === 1){
            appContext.setMessage('Marking succesful');
            //window.location.reload();
          }
          else{
            appContext.setError(response.msg);
          }
        }
        else{
          appContext.setError('Network error');
        }
      });
    }
    else {
      appContext.setError('Specify Full name to appear on certificate')
    }
  }

  const init = async () => {
    setReady(false);
    await getQuizData().then(async () => {
      await checkDoubleTake().then(() => {
        setReady(true);
      })
    })
  }

  const exitQuizer = () => {
    contentViewContext.setShowIntro(true);
    contentViewContext.setShowReader(false);
    contentViewContext.setShowListener(false);
    contentViewContext.setShowWatcher(false);
    contentViewContext.setShowQuizer(false);
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    init();
  }, [ props.id ]);

  if(ready) {
    if(quizData !== null){
      return (
        <>
        <div className="row">
          <div className="col-12" style={{ marginBottom:"40px" }}>
            <h4 className="font-light">Taking {contentViewContext.type} quiz</h4>
            <h5 className="font-semi-bold text-muted">{contentViewContext.data.title}</h5>
            <button onClick={exitQuizer} className="btn bg-secondary text-dark">Exit</button>
          </div>
        </div>
        {
          (doubleTake === true)
          ?
          <div className="row">

            <div className="col-md-12 col-sm-12" style={{ marginBottom:"20px" }}>
              <div className="card">
                <div className="card-body">
                  <h6 className="text-center text-danger">You took this Quiz on {date}, Go to 'Account' menu for the certificate</h6>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12" style={{ marginBottom:"20px" }}>
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title text-center">Quiz Score</h6>
                  <h1 className="text-center" style={{ color:"var(--accentColor)" }}>{score} %</h1>
                </div>
              </div>
            </div>

            {
              (Number(count) < 3) ?
              <>
              <div className="col-md-12 col-sm-12" style={{ marginBottom:"20px" }}>
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-center text-danger">You are left with {3 - Number(count)} chances to retake this quiz</h6>
                    <div className="form-group" style={{ maxWidth:"360px", margin:"0 auto" }}>
                      <label>Full Name</label>
                      <input id="_quizTaker" defaultValue={taker} className="form-control"/>
                      <small className="form-text">Will appear on your certificate</small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-sm-12" style={{ marginBottom:"20px" }}>
                <QuizTest sendQuizAnswers={sendQuizAnswers} quizQns={JSON.parse(quizData.quizQns)}/>
              </div>
              </>
              :
              ""
            }
          </div>
          :
          <div className="row">
            <div className="col-md-12 col-sm-12" style={{ marginBottom:"20px" }}>
              <div className="card">
                <div className="card-body">
                  <h6 className="text-center text-danger">You can retake this quiz up to 3 times only. Welcome</h6>
                  <div className="form-group" style={{ maxWidth:"360px", margin:"0 auto" }}>
                    <label>Full Name</label>
                    <input id="_quizTaker" className="form-control"/>
                    <small className="form-text">Will appear on your certificate</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12" style={{ marginBottom:"20px" }}>
              <QuizTest sendQuizAnswers={sendQuizAnswers} quizQns={JSON.parse(quizData.quizQns)}/>
            </div>
          </div>
        }
        </>
      );
    }
    else{
      return (
        <div></div>
      );
    }
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }


}
