import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import Banner from '../ones/Banner';
import Categories from '../ones/Categories';
import NewsFeed from '../views/NewsFeed';

export default function View(props) {
  const appContext = useContext(AppContext);

  return (
    <>
      <Categories/>
      <Banner/>
      <NewsFeed isHome={true} isSearch={false} type={false} category={false}/>
    </>
  )
}
