import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader, callApi } from '../Helper';
import logo from '../assets/logo.png';
import { ContentViewContext } from './ContentView';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { BASE_API, formatSecs } from '../Helper';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';
import ReactAudioPlayer from 'react-audio-player';
import { AiFillForward, AiFillBackward, AiOutlinePlayCircle, AiOutlinePauseCircle } from 'react-icons/ai';


export default function View(props) {

  const appContext = useContext(AppContext);
  const contentViewContext = useContext(ContentViewContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ userFeedBack, setUserFeedBack ] = useState(null);
  const [ rating, setRating ] = useState(5);
  const [ comment, setComment ] = useState('');

  function exitFeedbackForm () {
    contentViewContext.setShowIntro(true);
    contentViewContext.setShowReader(false);
    contentViewContext.setShowListener(false);
    contentViewContext.setShowWatcher(false);
    contentViewContext.setShowQuizer(false);
    contentViewContext.setShowFeedbackForm(false);
  }

  function getUserFeedBack () {
    return new Promise(async resolve => {
      await callApi('get_user_feedback.php', { id: contentViewContext.data.id, type: contentViewContext.type }).then(response => {
        if(response.status === 1) {
          setUserFeedBack(response.userFeedBack);
          resolve();
        } else {
          appContext.setError(response.msg);
        }
      })
    })
  }

  const submitFeedback = async () =>  {
    if(!loading) {
      setLoading(true);
      await callApi('send_feedback.php', { rating, comment, id: contentViewContext.data.id, type: contentViewContext.type }).then(response => {
        setLoading(false);
        if(response.status === 1) {
          appContext.setMessage('Thank you for your feedback');
          exitFeedbackForm();
        } else {
          appContext.setError(response.msg);
        }
      })
    }
  }

  const editFeedback = async () =>  {
    if(!loading) {
      setLoading(true);
      await callApi('edit_feedback.php', { rating, comment, id: contentViewContext.data.id, type: contentViewContext.type }).then(response => {
        setLoading(false);
        if(response.status === 1) {
          appContext.setMessage('Thank you for your feedback');
          exitFeedbackForm();
        } else {
          appContext.setError(response.msg);
        }
      })
    }
  }

  const init = async () => {
    setReady(false);
    if(contentViewContext.data) {
      await getUserFeedBack().then(() => {
        setReady(true);
      })
    } else {
      appContext.setError('Could not open this content');
    }
  }

  useEffect(() => {
    let finishers = init();
    return () => {
      appContext.setHideTopBar(false);
    }
  }, [ ]);



  useEffect(() => {
    //appContext.setShowOverlayLoader(loading);
  }, [ loading ]);

  useEffect(() => {
    if(userFeedBack) {
      setComment(userFeedBack.comment);
      setRating(Math.floor(userFeedBack.rating));
    }
  }, [ userFeedBack ]);

  return (
    <div className="container" style={{ padding: "0" }}>
      {
        (ready) ?
        <div className="row">
          <div className="col-md-6" style={{ padding: "10px" }}>
            <div className="form-group">
              <label className="">Comment (Optional)</label>
              <textarea maxLength={200} value={comment} onChange={(e) => setComment(e.target.value)} className="form-control" />
            </div>
            <div className="form-group text-center">
              <h1 className="text-primary">{rating} Stars</h1>
              <div className="mStarsBar d-flex justify-content-center">
                <div className="mStar" onClick={() => setRating(1)}>
                  {
                    (rating >= 1) ?
                    <AiFillStar className="mStarIcon"/>
                    :
                    <AiOutlineStar className="mStarIcon"/>
                  }
                </div>

                <div className="mStar" onClick={() => setRating(2)}>
                  {
                    (rating >= 2) ?
                    <AiFillStar className="mStarIcon"/>
                    :
                    <AiOutlineStar className="mStarIcon"/>
                  }
                </div>
                <div className="mStar" onClick={() => setRating(3)}>
                  {
                    (rating >= 3) ?
                    <AiFillStar className="mStarIcon"/>
                    :
                    <AiOutlineStar className="mStarIcon"/>
                  }
                </div>
                <div className="mStar" onClick={() => setRating(4)}>
                  {
                    (rating >= 4) ?
                    <AiFillStar className="mStarIcon"/>
                    :
                    <AiOutlineStar className="mStarIcon"/>
                  }
                </div>
                <div className="mStar" onClick={() => setRating(5)}>
                  {
                    (rating >= 5) ?
                    <AiFillStar className="mStarIcon"/>
                    :
                    <AiOutlineStar className="mStarIcon"/>
                  }
                </div>
              </div>
              <small className="text-muted">Click stars to rate</small>
              <hr/>
              {
                (userFeedBack) ?
                <button onClick={editFeedback} className="btn bg-primary text-white support-loading">
                { (loading) ? getInlineLoader() : "Edit Feedback" }
                </button>
                :
                <button onClick={submitFeedback} className="btn bg-primary text-white support-loading">
                { (loading) ? getInlineLoader() : "Submit Feedback" }
                </button>
              }
              <br/>
              <button onClick={exitFeedbackForm} className="btn bg-dark text-white" style={{ marginTop: "10px" }}>Go Back</button>
            </div>
          </div>

        </div> :
        <div className="row">
          <div className="col-md-12 support-loading">
            {getInlineLoader()}
          </div>
        </div>
      }
    </div>
  )

}
