import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';
import { getInlineLoader, giv, callApi } from '../Helper';


export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const init = () => {
    if(appContext.auth === 1) {
      //go to main panel account
      appContext.navTo({
        item: 'account'
      });
    } else if(appContext.auth === 2 ) {
      //go to verification
      appContext.navTo({
        item: 'account',
        subItem: 'verification',
      });
    } else {
      //stay here
      setReady(true);
    }
  }

  const register = async (e) => {
    e.preventDefault();
    if(!loading) {
      let email = giv('_email');
      let password = giv('_password');
      let confirm = giv('_confirm');

      if(email.trim().length > 0) {
        if(password.trim().length >= 6) {
          if(password === confirm) {

            setLoading(true);
            await callApi('register.php', { emailPhone: email, password }).then(async response => {

              if(response.status === 1) {
                await appContext.saveLocalUser(response.user);
                appContext.authCheck().then(response => {
                  setLoading(false);
                  if(response.status === 1) {
                    
                    appContext.setMessage('Registration was successful, confirmation email was sent to you');
                    appContext.navTo({
                      item: 'account',
                      subItem: 'verification',
                    })
                  } else {
                    appContext.setError(response.msg);
                  }
                })
              } else {
                setLoading(false);
                appContext.setError(response.msg);
              }
              
            })
          } else {
            appContext.setError('Please confirm your password correctly');
          }
        } else {
          appContext.setError('password must have atleast 6 characters');
        }
      } else {
        appContext.setError('Invalid email address');
      }
    }
  }
  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"20px" }}>
          <h4 className="font-light">Register</h4>
        </div>

        <div className="col-md-6">
          <form onSubmit={(e) => register(e)}>
            <div className="form-group">
              <label>Email Address</label>
              <input type="email" id="_email" className="form-control"/>
            </div>

            <div className="form-group">
              <label>Create Password</label>
              <input type="password" id="_password" className="form-control"/>
            </div>

            <div className="form-group">
              <label>Confirm Password</label>
              <input type="password" id="_confirm" className="form-control"/>
            </div>

            <div className="form-group text-end">
              <button
                className="btn bg-secondary text-dark support-loading"
              >
              {
                (loading) ?
                getInlineLoader() :
                "Register"
              }
              </button>
            </div>
          </form>
          <div className="text-start">
            <small className="form-text">
              You can also <span style={{ cursor: "pointer" }} onClick={() => { appContext.navTo({ item: "account", subItem: "login" }) }} className="font-semi-bold text-primary">Login</span> or <span className="font-semi-bold text-primary" style={{ cursor: "pointer" }} onClick={() => { appContext.navTo({ item: "account", subItem: "reset" }) }}>Reset password</span>
              </small>
          </div>
        </div>

      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
