import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import logo from '../assets/logo.png';

export default function View(props) {
  const appContext = useContext(AppContext);
  return (
    <>
      <div className="CategoriesFixed">
        <div className="container">
          <div className="row">
            <div className="col-12" style={{ marginTop: "20px" }}>
              <div className="Categories d-flex">
                <button  onClick={() => { appContext.navTo({ item: 'content', subItem: 'articles' }) }} className="mItem btn btn-sm" data-current={(appContext.navSubItem === 'articles')}>Articles</button>
                <button  onClick={() => { appContext.navTo({ item: 'content', subItem: 'books' }) }} className="mItem btn btn-sm" data-current={(appContext.navSubItem === 'books')}>Books</button>
                <button  onClick={() => { appContext.navTo({ item: 'content', subItem: 'courses' }) }} className="mItem btn btn-sm" data-current={(appContext.navSubItem === 'courses')}>Videos</button>
                <button  onClick={() => { appContext.navTo({ item: 'content', subItem: 'podcasts' }) }} className="mItem btn btn-sm" data-current={(appContext.navSubItem === 'podcasts')}>Podcasts</button>
                <button  onClick={() => { appContext.navTo({ item: 'content', subItem: 'series' }) }} className="mItem btn btn-sm" data-current={(appContext.navSubItem === 'series')}>Series</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        (appContext.navSubItem) ?
        //..
        <div className="col-12 mSpacerMargin">
          <div className="Categories d-flex" data-fixed={false}>
            <button onClick={() => { appContext.navTo({ item: 'content', subItem: appContext.navSubItem }) }} className="mItem btn btn-sm" data-current={(!appContext.navExtraItem)}>All</button>
            {
              appContext.systemParams.contentTags.map((item, i) => {
                return (
                  <button key={i} onClick={() => { appContext.navTo({ item: 'content', subItem: appContext.navSubItem, extraItem: item }) }} className="mItem btn btn-sm" data-current={(appContext.navExtraItem === item)}>{item}</button>
                )
              })
            }
            
          </div>
        </div>: ""
        //..
      }
    </>
  )

}
