import { useState, useContext, createContext } from "react";
import { AppContext } from "../App";
import { getInlineLoader } from "../Helper";
import logo from '../assets/logo.png';

export default function MainLoader(props) {
  const appContext = useContext(AppContext);


  if(appContext.showOverlayLoader) {
    return (
      <div className="MainLoader">
        {getInlineLoader()}
      </div>
    )
  } else {
    return <></>;
  }
}
