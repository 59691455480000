import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';
import { getInlineLoader, giv, callApi, WEB_URL, copyText } from '../Helper';
import TermsPrivacy from './TermsPrivacy';
import TermsRefund from './TermsRefund';
import TermsService from './TermsService';
import ReactToPrint from 'react-to-print';
import cas_sign from '../assets/cas_sign.jpg';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';




export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ userId, setUserId ] = useState(props.userId);
  const [ quizId, setQuizId ] = useState(props.quizId);
  const [ userData, setUserData ] = useState(null);
  const [ quizData, setQuizData ] = useState(null);
  const [ certData, setCertData ] = useState(null);



  const init = async () => {
    appContext.setShowOverlayLoader(true);
    await getUserData().then(async userData => {
      if(userData) {
        await getQuizData().then(async quizData => {
          if(quizData) {
            await getCertData(userData).then(async result => {
              if(result) {
                setReady(true);
                appContext.setHideTopBar(true);
              }
            })
          }
        })
      }
    })
  }

  const getUserData = async () => {
    return new Promise(async resolve => {
      await callApi('get_user_info.php', { userId }).then(response => {
        if(response.status === 1) {
          setUserData(response.userData);
          resolve(response.userData);
        } else {
          appContext.setError(response.msg);
          resolve(null);
        }
      })
    })
  }

  const getQuizData = async () => {
    return new Promise(async resolve => {
      await callApi('get_quiz_info.php', { quizId }).then(response => {
        if(response.status === 1) {
          setQuizData(response.quizData);
          resolve(response.quizData);
        } else {
          appContext.setError(response.msg);
          resolve(null);
        }
      })
    })
  }

  function saveCert() {
    //..
    const pdf = new jsPDF('l', 'mm', [297, 210]);
    pdf.addImage(document.getElementById('certImgView').src, 'JPEG', 0, 0);
    // pdf.output('dataurlnewwindow');
    pdf.save(`CALIPA-CERTIFICATE-${userId}${quizId}.pdf`);
  }

  function certLinkCopy() {
    //..
    let _userId = btoa(userId);
    let _quizId = btoa(quizId);

    let link = `${WEB_URL}?cert=${_userId}-${_quizId}`;
    copyText(link);
    appContext.setMessage('Certificate Link was copied');
  }

  function renderWell() {
    const input = document.getElementById('certView');
    html2canvas(input)
      .then((canvas) => {
        //...
        // create intermediate canvas
        var rotCanvas = document.createElement("canvas");

        // swap width and height
        rotCanvas.width = canvas.height;
        rotCanvas.height = canvas.width;

        // get context
        var rctx = rotCanvas.getContext("2d");

        // translate to center (rotation pivot)
        rctx.translate(rotCanvas.width * 0.5, rotCanvas.height * 0.5);

        // rotate -90° (CCW)
        rctx.rotate(-Math.PI * 0.5);

        // draw image offset so center of image is on top of pivot
        rctx.drawImage(canvas, -canvas.width * 0.5, -canvas.height * 0.5);
        //..
        const imgData = canvas.toDataURL('image/png');
        document.getElementById('certImgView').src = imgData;
        document.getElementById('certView').style.display = 'none';
      }).then(() => {
        appContext.setShowOverlayLoader(false);
      })
    ;
  }

  const getCertData = async (userData) => {

    return new Promise(async resolve => {
      let _certData = null;
      JSON.parse(userData.certs).forEach((item, i) => {
        if(Number(item.quizId) === Number(quizId) && !isNaN(quizId) && quizId) {
          _certData = item;
        }
      });

      if(_certData) {
        setCertData(_certData);
        resolve(true);
      } else {
        appContext.setError('Could not fetch certificate data');
        resolve(false);
      }

    })
  }

  useEffect(() => {
    init();

    return () => {
      appContext.setHideTopBar(false);
    }
  }, [ ]);

  useEffect(() => {
    if(ready) {
      renderWell();
    }
  }, [ ready ]);


  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"40px" }}>
        {/* ...................*/}
            <div id="certView" className="CertView">
              <div className="container">
                <div className="row">
                  <img src={logo} className="mLogo"/>
                  <div className="col-12 text-center">
                    <h1 style={{ fontSize:"60px", marginTop:"90px", color:"var(--secondary-color)" }} className="font-bold">Certificate Of</h1>
                    <h1 style={{ fontSize:"40px" }} className="text-primary">Participation</h1>
                  </div>
                  <div className="col-12 text-center">
                    <h6 className="text-dark">Is awarded to</h6>
                    <h2 className="font-bold text-secondary" style={{ fontSize:"25px" }}>{certData.taker}</h2>
                    <h6 style={{ padding:"10px" }} className="text-primary">
                      For the participation in studying <span className="font-bold">{certData.title}</span> at CAS Life Purpose Academy's (<span className="font-bold">CALIPA</span>)
                      digital platforms
                    </h6>
                    <hr/>
                  </div>
                  <div className="col-6 text-start text-primary" style={{ padding:"50px", paddingTop: "200px" }}>
                    <img src={cas_sign} className="mSign"/>
                    <h5>Constantine Shayo</h5>
                    <h6>Director at CALIPA</h6>
                  </div>
                  <div className="col-6 text-end text-primary" style={{ padding:"50px", paddingTop:"200px" }}>
                    <h6>Date</h6>
                    <h5><i>{certData.date}</i></h5>
                  </div>
                </div>
              </div>
            </div>
        {/* ...................*/}
        </div>
        <div className="col-12">
          <img id="certImgView" style={{ width: "100%", height: "auto" }} />
        </div>
        <div className="col-12 text-center" style={{ paddingTop:"10px", paddingBottom:"10px" }}>
          <button className="btn bg-secondary text-dark" onClick={saveCert}>Save As PDF</button>
        </div>
        <div className="col-12 text-center" style={{ paddingTop:"10px", paddingBottom:"10px" }}>
          <button className="btn bg-secondary text-dark" onClick={certLinkCopy}>Copy Link</button>
        </div>
        <div className="col-12 text-center" style={{ paddingTop:"10px", paddingBottom:"10px" }}>
          <button className="btn bg-primary text-white" onClick={() => appContext.goBack()}>Go Back</button>
        </div>
      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
