import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { PublisherContext } from "../main-views/PublisherPanel";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader, formatMoney, callApi, giv } from '../Helper';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';

export default function View(props) {
  const appContext = useContext(AppContext);
  const publisherContext = useContext(PublisherContext);
  const [ ready, setReady ] = useState(false);
  const [ withdrawInit, setWithdrawInit ] = useState(false);
  const [ withdrawComplete, setWithdrawComplete ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const init = () => {
    setReady(true);
  }

  const startWithdraw = async () => {
    if(!loading) {
      setLoading(true);
      await callApi('initiate_withdraw.php', {}).then(response => {
        setLoading(false);
        if(response.status === 1) {
          setWithdrawInit(true);
        } else {
          appContext.setError(response.msg);
        }
      })
    }
  }

  const joinWhatsApp = async () => {
    window.open('https://chat.whatsapp.com/F3uBP9iyr6iI2CYrJCSf5f', '_blank');
  }

  const completeWithdraw = async () => {

    if(!loading) {
      let password = giv('_password');
      let phone = giv('_phone');
      let amount = giv('_amount');

      if(password.trim().length > 0) {
        if(phone.trim().length > 0) {
          if(amount.trim().length > 0) {
            setLoading(true);
            await callApi('send_withdraw_req.php', { password, phone, amount }).then(response => {
              setLoading(false);
              if(response.status === 1) {
                setWithdrawComplete(true);
              } else {
                appContext.setError(response.msg);
              }
            })
          } else {
            appContext.setError("Invalid amount");
          }
        } else {
          appContext.setError("Invalid phone");
        }
      } else {
        appContext.setError("Invalid password");
      }
    }
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"20px" }}>
          <h4 className="font-light">Wallet & Sales</h4>
        </div>

        <div className="col-12 text-end" style={{ marginBottom:"40px" }}>
          <h1 className="font-light">{formatMoney(publisherContext.publisherData.balance)}</h1>
          <h5 className="font-light text-muted">Tsh</h5>
          {
            (withdrawInit) ?
            <div className="d-flex justify-content-end">
              {
                (withdrawComplete) ?
                <div className="text-end">
                  <small className="font-light text-secondary">Your withdraw request was received, one of our admins will process it soon. Thanks</small>
                </div>
                :
                <div className="text-start" style={{ flexBasis: "300px" }}>
                  <div className="form-group">
                    <label>Your Password</label>
                    <input type="password" id="_password" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <input type="text" id="_phone" className="form-control" />
                    <small className="form-text font-light">Your funds will be sent to this phone number</small>
                  </div>
                  <div className="form-group">
                    <label>Amount</label>
                    <input type="number" id="_amount" className="form-control" />
                    <small className="form-text font-light">Amount you want to withdraw</small>
                  </div>
                  <div className="form-group text-end">
                    <button onClick={completeWithdraw} className="btn bg-primary text-light">Complete Withdraw</button>
                  </div>
                </div>
              }
            </div>
            :
            <div className="text-end">
              <button onClick={startWithdraw} className="btn bg-primary text-light">Request to withdraw</button>
            </div>
          }
        </div>

        <div className="col-12" style={{ marginBottom:"40px" }}>
          <h6 className="font-light text-muted">Join CALIPA Publishers' WhatsApp group for all updates, guides and tips</h6>
          <button onClick={joinWhatsApp} className="btn bg-primary text-light">JOIN US ON WhatsApp</button>
        </div>

        <div dangerouslySetInnerHTML={{__html: publisherContext.publisherData.terms}} className="col-12" style={{ marginBottom:"20px" }}>
        </div>

        <div dangerouslySetInnerHTML={{__html: publisherContext.publisherData.sales}} className="col-12" style={{ marginBottom:"20px" }}>
        </div>
      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
