import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io';
import { getInlineLoader, giv, callApi } from '../Helper';
import PublisherWallet from '../views/PublisherWallet';

import PostArticle from '../views/PostArticle';
import UploadBook from '../views/UploadBook';
import UploadCourse from '../views/UploadCourse';
import UploadPodcast from '../views/UploadPodcast';
import PAllBooks from '../views/PAllBooks';
import PAllPodcasts from '../views/PAllPodcasts';
import PAllCourses from '../views/PAllCourses';
import PEditContent from '../views/PEditContent';
import PManageQuiz from '../views/PManageQuiz';
import CreateSeries from '../views/CreateSeries';


export const PublisherContext = createContext(null);
export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ publisherData, setPublisherData ] = useState(null);

  const sendPubReq = async () => {
    let bio = giv('__bio');
    let username = giv('__username');
    if(username.trim().length > 0) {
      if(bio.trim().length > 0) {
        setLoading(true);
        await callApi('submit_pub_request.php', { bio, username }).then(async response => {
          if(response.status === 1) {
            await appContext.authCheck();
            setLoading(false);
            appContext.setMessage('Your request was sent');
          } else {
            setLoading(false);
            appContext.setError(response.msg);
          }
        })
      } else {
        appContext.setError('Invalid Bio')
      }
    } else {
      appContext.setError('Invalid fullname')
    }
  }

  const getPublisherData = async () => {
    await callApi('get_publisher_data.php', {}).then(response => {
      if(response.status === 1) {
        setPublisherData(response);
      } else {
        appContext.setError(response.msg);
      }
    })
  }

  const init = async () => {
    if(appContext.auth === 1) {
      if(appContext.userData.publishing_status === 1) {
        await getPublisherData();
      }
      setReady(true);
    } else {
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
    }
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  const publisherContext = {
    publisherData,
    getPublisherData,
  }

  if(ready) {
    if(!appContext.navSubItem && !appContext.navExtraItem) {
      //render intro if has publisher access, otherwise show request access
      if(appContext.userData.publishing_status === 1) {
        return (
          <div className="row">
            <div className="col-12" style={{ marginBottom:"20px" }}>
              <h4 className="font-light">Publisher Panel</h4>
            </div>

            <div className="col-md-6">

              <div
                onClick={() => {
                  appContext.navTo({
                    item: 'publisher',
                    subItem: 'wallet',
                  })
                }}
                className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
                <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
                  Your Wallet & Sales
                </h6>
                <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
              </div>

              <div
                onClick={() => {
                  appContext.navTo({
                    item: 'publisher',
                    subItem: 'post-article',
                  })
                }}
                className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
                <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
                  Post New Article
                </h6>
                <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
              </div>

              <div
                onClick={() => {
                  appContext.navTo({
                    item: 'publisher',
                    subItem: 'create-series',
                  })
                }}
                className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
                <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
                  Create New Series
                </h6>
                <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
              </div>

              <div
                onClick={() => {
                  appContext.navTo({
                    item: 'publisher',
                    subItem: 'upload-book',
                  })
                }}
                className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
                <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
                  Upload New Book
                </h6>
                <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
              </div>

              

              <div
                onClick={() => {
                  appContext.navTo({
                    item: 'publisher',
                    subItem: 'upload-course',
                  })
                }}
                className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
                <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
                  Upload New Course
                </h6>
                <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
              </div>

              <div
                onClick={() => {
                  appContext.navTo({
                    item: 'publisher',
                    subItem: 'upload-podcast',
                  })
                }}
                className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
                <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
                  Upload New Podcast
                </h6>
                <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
              </div>

            </div>

            <div className="col-md-6">
              <div
                onClick={() => {
                  appContext.navTo({
                    item: 'publisher',
                    subItem: 'all-books',
                  })
                }}
                className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
                <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
                  Manage Books
                </h6>
                <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
              </div>

              <div
                onClick={() => {
                  appContext.navTo({
                    item: 'publisher',
                    subItem: 'all-courses',
                  })
                }}
                className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
                <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
                  Manage Courses
                </h6>
                <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
              </div>

              {/*
              <div
                onClick={() => {
                  appContext.navTo({
                    item: 'publisher',
                    subItem: 'all-podcasts',
                  })
                }}
                className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
                <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
                  Manage Podcasts
                </h6>
                <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
              </div>

              <div
                onClick={() => {
                  appContext.navTo({
                    item: 'publisher',
                    subItem: 'all-articles',
                  })
                }}
                className="text-left d-flex justify-content-between mHorizontalTab z-depth-1">
                <h6 className="noMargin align-self-center flex-grow-1 font-regular text-secondary">
                  Manage Articles
                </h6>
                <IoIosArrowForward size={20} className="noMargin align-self-center" style={{ flexShrink: "0" }} />
              </div>
              */}
              <div
                style={{ padding: "10px" }}
                className="z-depth-1">

                <h6>Contact our Admins if you want more help on managing your published content</h6>
                <hr/>
                <h6 className="text-muted" style={{ fontSize: "14px" }}>+255 787 559 510 (Whatsapp and Calls)</h6>
                <h6 className="text-muted" style={{ fontSize: "14px" }}>+255 749 487 578 (Whatsapp and Calls)</h6>
                <h6 className="text-muted" style={{ fontSize: "14px" }}>support@calipa.co.tz</h6>
                <h6 className="text-muted" style={{ fontSize: "14px" }}>moderator@calipa.co.tz</h6>
                <h6 className="text-muted" style={{ fontSize: "14px" }}>developer@calipa.co.tz</h6>
              </div>

            </div>

          </div>
        )
      } else {
        //render publisher requesting or request status
        return (
          <div className="row">
            <div className="col-12" style={{ marginBottom:"20px" }}>
              <h4 className="font-light">Publisher Panel</h4>
            </div>

            {
              (appContext.userData.publishing_status === 0) ?
              <div className="col-12" style={{ marginBottom:"20px" }}>
                <h6 className="font-light text-muted">Update your name and bio and Send publishing request below to access this section</h6>
                <div className="form-group">
                  <label>Full Name</label>
                  <input id="__username" defaultValue={(appContext.userData.username) ? appContext.userData.username : ""} className="form-control" />
                  <small className="form-text">This name will be displayed to your audience</small>
                </div>
                <div className="form-group">
                  <label>Your Bio</label>
                  <textarea id="__bio" defaultValue={(appContext.userData.bio) ? appContext.userData.bio : ""} className="form-control" />
                  <small className="form-text">Write short introductory about you to your expected audience</small>
                </div>
                <div className="form-group text-end">
                  <button onClick={sendPubReq} className="btn bg-secondary text-dark">Send Request</button>
                </div>
              </div> :
              <div className="col-12" style={{ marginBottom:"20px" }}>
                <h6 className="font-light text-muted">You sent publishing request, please wait for admin approval. You'll be notified soon</h6>
              </div>
            }
          </div>
        )
      }
    } else {
      //render specific parts
      if(appContext.navSubItem === 'wallet' && !appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PublisherWallet/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'post-article' && !appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PostArticle/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'upload-book' && !appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <UploadBook/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'upload-course' && !appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <UploadCourse/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'upload-podcast' && !appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <UploadPodcast/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'create-series' && !appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <CreateSeries/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'all-books' && !appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PAllBooks/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'all-courses' && !appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PAllCourses/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'all-podcasts' && !appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PAllPodcasts/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'edit-book' && appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PEditContent type="book" id={appContext.navExtraItem}/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'edit-course' && appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PEditContent type="course" id={appContext.navExtraItem}/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'edit-podcast' && appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PEditContent type="podcast" id={appContext.navExtraItem}/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'manage-book-quiz' && appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PManageQuiz type="book" id={appContext.navExtraItem}/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'manage-course-quiz' && appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PManageQuiz type="course" id={appContext.navExtraItem}/>
          </PublisherContext.Provider>
        )
      } else if(appContext.navSubItem === 'manage-podcast-quiz' && appContext.navExtraItem) {
        return (
          <PublisherContext.Provider value={publisherContext}>
            <PManageQuiz type="podcast" id={appContext.navExtraItem}/>
          </PublisherContext.Provider>
        )
      }
    }
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }

}
