import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader } from '../Helper';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);
  

  const init = () => {
    if(appContext.auth === 1) {
      //stay here
      setReady(true);
    } else {
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
    }
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"40px" }}>
          <h4 className="font-light">Your Certificates</h4>
          <small className="form-text">take quizes on courses, books and podcasts to get more certificates</small>
        </div>

        <div className="col-md-12" style={{ marginBottom:"40px" }}>
          {
            (appContext.certs && appContext.certs.length > 0) ?
            appContext.certs.map((item, i) => {
              console.log(item);
              return (
                <div className="text-start z-depth-1" key={i} style={{ marginBottom: "10px", padding: "10px" }}>
                  <h5 className="font-light text-secondary" >{item.title}</h5>
                  <h2 className="text-muted font-semi-bold" style={{ marginBottom: "-5px" }}>{item.score}%</h2>
                  <small className="text-muted font-light">Score</small>
                  <div className="text-end">
                    <button onClick={() => { appContext.navTo({ item: 'view-cert', subItem: appContext.userData.id, extraItem: item.quizId }) }} className="btn bg-primary text-light" >View</button>
                  </div>
                </div>
              )
            }) :
            <small className="font-light form-text">You don't have any certificates</small>
          }
        </div>


      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
