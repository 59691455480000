import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader } from '../Helper';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';
import { MdLightMode, MdArrowBack, MdPlaylistAddCheck } from "react-icons/md";
import SwipeTabs from "../components/SwipeTabs";

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);

  const init = () => {
    if(appContext.auth === 1) {
      //stay here
      setReady(true);
    } else {
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
    }
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  if(ready) {
    //..
    return (
      <>
        
        <div className="TopBar">
          <div className="container d-flex justify-content-between" style={(appContext.showViewer) ? { height:"100%", padding:"0px", zIndex: "inherit" } : { height:"100%", padding:"0px" }}>
            <MdArrowBack onClick={() => appContext.goBack()} size={30} style={{ cursor: "pointer", flexShrink: "0" }} className="align-self-center noMargin noPad"/>
            <h6 style={{ fontSize: "25px", paddingLeft: "10px" }} className="align-self-center flex-grow-1 noMargin">
              Your Collections
            </h6>
            <MdLightMode style={{ cursor: 'pointer', flexShrink: "0" }} onClick={() => appContext.toggleTheme()} size={30} className="align-self-center noMargin noPad" color='var(--on-background)'/>
          </div>
        </div>
        
        <div className="FullPane" id="fullPane" data-support-top-bar={true}>
          <SwipeTabs
            tabs={[
              {
                title: "Articles",
                id: "search-articles",
                view: (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6" style={{ marginBottom:"40px" }}>
                        <h6 style={{ fontSize: "30px", marginTop: "20px", marginBottom: "20px" }} className="text-muted">Articles</h6>
                        {
                          (appContext.articlesCol && appContext.articlesCol.length > 0) ?
                          appContext.articlesCol.map((item, i) => {
                            return (
                              <div className="text-start z-depth-1" key={i} style={{ marginBottom: "10px", padding: "10px" }}>
                                <h5 className="font-light text-secondary" style={{ marginBottom: "30px", fontSize: "16px" }}>{item.title}</h5>
                                <div className="text-end">
                                  <button onClick={() => { appContext.navTo({ item: 'view', subItem: 'article', extraItem: item.id }) }} className="btn bg-primary text-light" >View</button>
                                </div>
                              </div>
                            )
                          }) :
                          <small className="font-light form-text">No articles were found in your collection</small>
                        }
                      </div>
                    </div>
                  </div>
                )
              },
              {
                title: "Books",
                id: "search-books",
                view: (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6" style={{ marginBottom:"40px" }}>
                        <h6 style={{ fontSize: "30px", marginTop: "20px", marginBottom: "20px" }} className="text-muted">Books</h6>
                        {
                          (appContext.booksCol && appContext.booksCol.length > 0) ?
                          appContext.booksCol.map((item, i) => {
                            return (
                              <div className="text-start z-depth-1" key={i} style={{ marginBottom: "10px", padding: "10px" }}>
                                <h5 className="font-light text-secondary" style={{ marginBottom: "30px", fontSize: "16px" }}>{item.title}</h5>
                                <div className="text-end">
                                  <button onClick={() => { appContext.navTo({ item: 'view', subItem: 'book', extraItem: item.id }) }} className="btn bg-primary text-light" >View</button>
                                </div>
                              </div>
                            )
                          }) :
                          <small className="font-light form-text">No books were found in your collection</small>
                        }
                      </div>
                    </div>
                  </div>
                )
              },
              {
                title: "Videos",
                id: "search-courses",
                view: (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6" style={{ marginBottom:"40px" }}>
                        <h6 style={{ fontSize: "30px", marginTop: "20px", marginBottom: "20px" }} className="text-muted">Videos</h6>
                        {
                          (appContext.coursesCol && appContext.coursesCol.length > 0) ?
                          appContext.coursesCol.map((item, i) => {
                            return (
                              <div className="text-start z-depth-1" key={i} style={{ marginBottom: "10px", padding: "10px" }}>
                                <h5 className="font-light text-secondary" style={{ marginBottom: "30px", fontSize: "16px" }}>{item.title}</h5>
                                <div className="text-end">
                                  <button onClick={() => { appContext.navTo({ item: 'view', subItem: 'course', extraItem: item.id }) }} className="btn bg-primary text-light" >View</button>
                                </div>
                              </div>
                            )
                          }) :
                          <small className="font-light form-text">No videos were found in your collection</small>
                        }
                      </div>
                    </div>
                  </div>
                )
              },
              {
                title: "Podcasts",
                id: "search-podcasts",
                view: (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6" style={{ marginBottom:"40px" }}>
                        <h6 style={{ fontSize: "30px", marginTop: "20px", marginBottom: "20px" }} className="text-muted">Podcasts</h6>
                        {
                          (appContext.podcastsCol && appContext.podcastsCol.length > 0) ?
                          appContext.podcastsCol.map((item, i) => {
                            return (
                              <div className="text-start z-depth-1" key={i} style={{ marginBottom: "10px", padding: "10px" }}>
                                <h5 className="font-light text-secondary" style={{ marginBottom: "30px", fontSize: "16px" }}>{item.title}</h5>
                                <div className="text-end">
                                  <button onClick={() => { appContext.navTo({ item: 'view', subItem: 'podcast', extraItem: item.id }) }} className="btn bg-primary text-light" >View</button>
                                </div>
                              </div>
                            )
                          }) :
                          <small className="font-light form-text">No podcasts were found in your collection</small>
                        }
                      </div>
                    </div>
                  </div>
                )
              },
              
            ]}
          />
        </div>
      </>
    )
    //..
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }

  
}
