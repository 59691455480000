import React, { Component, useContext, useEffect, useState } from 'react';
import { getInlineLoader } from '../Helper';
import { NewsFeedContext } from '../views/NewsFeed'


export default function View(props) {
  const newsFeedContext = useContext(NewsFeedContext);

  useEffect(() => {
    //..
  }, [ newsFeedContext.showTail ])


  if(newsFeedContext.showTail) {
    return (
      <div id="feedTail" className="col-12 col-md-3 support-loading">
        {getInlineLoader()}
      </div>
    )
  } else {
    return <></>;
  }
}
