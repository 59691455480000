import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { BiUser } from 'react-icons/bi';
import { RiUserFollowLine } from 'react-icons/ri';
import { callApi } from "../Helper";


export default function AuthorThumb(props) {
  const appContext = useContext(AppContext);

  const [ data, setData ] = useState(props.data); /* bio, name, id */
  const [ canFollow, setCanFollow ] = useState(appContext.canFollow(data.id));
  const [ hasFollowed, setHasFollowed ] = useState(appContext.hasFollowed(data.id));
  const [ loading, setLoading ] = useState(false);

  const viewProfile = () => {
    //open profile view for author
    appContext.navTo({
      item: 'authors',
      subItem: data.id,
    })
  }

  const followAuthor = async () => {
    setLoading(true)
    await callApi('follow_author.php', { authorId: data.id }).then(async response => {
      if(response.status === 1) {
        await appContext.authCheck()
        appContext.setMessage("You followed succesfully")
        setLoading(false)
      } else {
        setLoading(false)
        appContext.setError(response.msg);
      }
    })
  }

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    setCanFollow(appContext.canFollow(data.id));
    setHasFollowed(appContext.hasFollowed(data.id));
  }, [ data ])

  useEffect(() => {
    setCanFollow(appContext.canFollow(data.id));
    setHasFollowed(appContext.hasFollowed(data.id));
  }, [ appContext.following, appContext.followers, appContext.userData ])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ])

  return (
    <div style={{ width: "100%", padding: "0px", margin: "0px" }}>
      <div className="d-flex">
        <BiUser
          size={50}
          className="align-self-center"
          color="var(--secondary-color)"
          style={{
            flexShrink: "0",
            marginRight: "10px",
            marginLeft: "0px",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        />

        <p
          className="align-self-center font-regular"
          style={{
            fontSize: "20px",
            margin: "0px",
            padding: "0px",
            color: "var(--primary-color)",
            textTransform: "capitalize",
          }}
        >
          {data.name}
        </p>
      </div>
      <div 
        className="text-start"
        style={{
          borderLeft: "3px solid var(--primary-color)",
          paddingLeft: "10px",
          marginTop: "10px",
        }}
      >
        <h6 className="font-semi-bold">ABOUT<br/>THE AUTHOR</h6>
        <h6 
          className="text-muted"
          style={{
            display: "block",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
            overflow: "hidden",
            maxHeight: "3.6em",
            lineHeight: "1.2em",
          }}
        >
          {data.bio}
        </h6>

        <button
          style={{
            marginTop: "20px",
            marginRight: "10px"
          }}
          className="btn btn-sm bg-primary"
          onClick={viewProfile}
        >
          View Profile
        </button>

        {
          (!hasFollowed && canFollow) ?
          <button
            style={{
              marginTop: "20px"
            }}
            className="btn btn-sm bg-secondary"
            onClick={followAuthor}
          >
            Follow <RiUserFollowLine/>
          </button>
          :
          ""
        }


        {
          (!hasFollowed && canFollow) ?
          <div className="text-start">
            <small className="font-light text-muted">Follow this author for more content like this</small>
          </div>
          :
          ""
        }

        {
          (hasFollowed) ?
          <div className="text-start">
            <small className="font-light text-muted">You are following this author</small>
          </div>
          :
          ""
        }

        
      </div>
    </div>
  )
}
