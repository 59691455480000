import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../App';
import { MdDashboard, MdManageAccounts, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FiActivity } from 'react-icons/fi';
import { IoIosPeople } from 'react-icons/io';
import { AiOutlineZoomIn, AiOutlineZoomOut, AiOutlineClose } from 'react-icons/ai';
import { RiSearch2Fill } from 'react-icons/ri';
import logo from '../assets/logo.png';
import { giv } from '../Helper';

export default function TopBar(props) {
  const appContext = useContext(AppContext);

  if(appContext.showBookControls) {
    if(appContext.bookReaderContext) {
      return (
        <div  className="BookControls">
          <div className="container d-flex justify-content-betwwen">
            <div className="align-self-center noPad noMargin" style={{ height: "100%", flexBasis:"50%", flexShrink: "0" }}>

              <div className="noMargin d-flex justify-content-start" style={{ height: "50%" }}>
                <input id="__pageNo" className="form-control align-self-center" type="text" style={{ flexBasis:"120px", fontSize:"12px" }}/>
                <button onClick={() => appContext.bookReaderContext.goTo(giv('__pageNo'))} className="align-self-center btn bg-primary" style={{ flexBasis:"50px", padding: "2px", fontSize:"14px", color: "var(--white-color)", fontFamily:"FontSemiBold" }}>GO</button>
              </div>
              <div className="noMargin d-flex justify-content-between" style={{ height: "50%", padding:"3px", fontSize:"16px", fontFamily:"FontSemiBold" }}>
                <span className="text-muted">{appContext.bookReaderContext.pageNumber}/{appContext.bookReaderContext.numPages}</span>
              </div>

            </div>

            <div className="align-self-center noPad noMargin" style={{ height: "100%", flexBasis:"50%", flexShrink: "0" }}>
              <div className="noMargin d-flex justify-content-end" style={{ height: "50%" }}>
                <button onClick={() => appContext.bookReaderContext.previousPage()} className="align-self-center btn bg-primary" style={{ flexBasis:"40px", padding: "2px", fontSize:"14px", color: "var(--white-color)", fontFamily:"FontSemiBold", margin:"3px" }}><MdKeyboardArrowLeft size={20}/></button>
                <button onClick={() => appContext.bookReaderContext.nextPage()} className="align-self-center btn bg-primary" style={{ flexBasis:"40px", padding: "2px", fontSize:"14px", color: "var(--white-color)", fontFamily:"FontSemiBold", margin:"3px" }}><MdKeyboardArrowRight size={20}/></button>
                <button onClick={() => appContext.bookReaderContext.scalePage()} className="align-self-center btn bg-primary" style={{ flexBasis:"40px", padding: "2px", fontSize:"14px", color: "var(--white-color)", fontFamily:"FontSemiBold", margin:"3px" }}><AiOutlineZoomIn size={20}/></button>
                <button onClick={() => appContext.bookReaderContext.scalePage(false)} className="align-self-center btn bg-primary" style={{ flexBasis:"40px", padding: "2px", fontSize:"14px", color: "var(--white-color)", fontFamily:"FontSemiBold", margin:"3px" }}><AiOutlineZoomOut size={20}/></button>
              </div>
              <div className="noMargin d-flex justify-content-end" style={{ height: "50%" }}>
                <button onClick={() => appContext.bookReaderContext.exitReader()} className="align-self-center btn bg-secondary text-dark" style={{ flexBasis:"100px", padding: "2px", fontSize:"14px", color: "var(--white-color)", fontFamily:"FontSemiBold", margin:"3px" }}><AiOutlineClose size={20}/> Exit</button>
                <button onClick={() => appContext.bookReaderContext.resetPageWidth()} className="align-self-center btn bg-secondary text-dark" style={{ flexBasis:"100px", padding: "2px", fontSize:"14px", color: "var(--white-color)", fontFamily:"FontSemiBold", margin:"3px" }}>Fit Width</button>
              </div>
            </div>

          </div>
        </div>
      )
    } else {
      return (
        <></>
      );
    }
  } else {
    return (
      <></>
    );
  }
}
