import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';
import { getInlineLoader, giv, callApi } from '../Helper';


export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const init = () => {
    if(appContext.auth === 1) {
      //go to main panel account
      appContext.superNavTo({
        item: 'account'
      });
    } else {
      //stay here
      setReady(true);
    }
  }

  const resendOtp = async () => {
    if(!loading) {
      appContext.setMessage('Requesting verification code, please wait');
      setLoading(true);
      await callApi('request_otp.php', {}).then(response => {
        setLoading(false);
        if(response.status === 1) {
          appContext.setMessage('New code was sent to your email address');
        } else {
          appContext.setError(response.msg);
        }
      })
    }
  }

  const verify = async (e) => {
    e.preventDefault();
    if(!loading) {
      let otp = giv('_otp');
      if(otp.trim().length > 0) {
        setLoading(true);
        await callApi('verify_account.php', { otp }).then(response => {

          if(response.status === 1) {
            appContext.authCheck().then(response => {
              setLoading(false);
              if(response.status === 1) {
                appContext.setMessage('Verification was completed successfully');
                appContext.superNavTo({
                  item: 'account',
                })
              } else {
                appContext.setError(response.msg);
              }
            })
          } else {
            setLoading(false);
            appContext.setError(response.msg);
          }


        })
      } else {
        appContext.setError('Invalid verification code');
      }
    }
  }
  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"20px" }}>
          <h4 className="font-light">Verify Your Account</h4>
        </div>

        <div className="col-md-6">
          <form onSubmit={(e) => verify(e)}>
            <div className="form-group">
              <label>Verification Code</label>
              <input type="text" id="_otp" className="form-control"/>
              <small className="form-text">Verification code was sent to {appContext.userData.email}</small>
            </div>


            <div className="form-group text-end">
              <button
                className="btn bg-secondary text-dark support-loading"
              >
              {
                (loading) ?
                getInlineLoader() :
                "Verify"
              }
              </button>
            </div>
          </form>
          <div className="text-left">
            <small className="form-text">
              Want new verification code? <span onClick={resendOtp} className="text-secondary" style={{ cursor: 'pointer' }}>Click here</span>
            </small>
          </div>
        </div>

      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
