import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes, NotFound } from 'react-router-dom';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "video-react/dist/video-react.css";
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';
import App from './App';

// TODO: handle 404 in routes
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter basename="/">
    <Routes>
      <Route exact path="/" element={<App/>}/>
      <Route exact path="/:_navItem" element={<App/>}/>
      <Route exact path="/:_navItem/:_navSubItem" element={<App/>}/>
      <Route exact path="/:_navItem/:_navSubItem/:_navExtraItem" element={<App/>}/>
    </Routes>
  </HashRouter>
);
