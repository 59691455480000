import { useState, useContext, createContext, useEffect, useRef } from "react";
import { AppContext } from "../App";
import { PublisherContext } from "../main-views/PublisherPanel";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader, formatMoney, callApi, giv, BASE_API } from '../Helper';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';
import { Editor } from '@tinymce/tinymce-react';
import sanitizeHtml from 'sanitize-html';

export default function View(props) {
  const appContext = useContext(AppContext);
  const publisherContext = useContext(PublisherContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ type, setType ] = useState('free');
  const [ price, setPrice ] = useState(0);
  const [ title, setTitle ] = useState("");
  const [ uploadedTitle, setUploadedTitle ] = useState(false);
  const [ selectedTags, setSelectedTags ] = useState([]);
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };


  const init = () => {
    setReady(true);
  }

  const addTag = (tag) => {
    
    if(selectedTags.length < 3) {
      setSelectedTags(prev => {
        let _i = prev.indexOf(tag);
        if(_i === -1) {
          //add it
          return [ ...prev, tag ];
        } else {
          return prev;
        }
        
      })
    }
  }

  const removeTag = (tag) => {
    //..
    setSelectedTags(prev => {
      let _i = prev.indexOf(tag);
      if(_i > -1) {
        //remove it
        return prev.splice(_i, 1);
      } else {
        return prev;
      }
      
    })
    //..
  }

  const loadCoverPhoto = (e) => {
    let img = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsDataURL(img)
    fileReader.onload = function () {
      document.getElementById('_coverPhotoImg').src = fileReader.result;
    }
  }

  const upload = async () => {
    if(!loading) {
      setUploadedTitle(false);
      let coverPhoto = document.getElementById('_coverPhoto').files[0];
      if(coverPhoto) {
        if(title.trim().length > 0) {
          if(type.trim().length > 0) {
            if(selectedTags.length > 0) {
              if((type === 'paid' && !isNaN(price) && Number(price) >= 0) || (type === 'free' && Number(price) === 0)) {

                let content = sanitizeHtml(editorRef.current.getContent(), {
                  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img'])
                });
                if(content.trim().length > 5) {
                  setLoading(true);
                  await callApi('create_new_post.php', { title, type, price, category:selectedTags.toString(), coverPhoto, content }).then(async response => {
                    if(response.status === 1) {
                      await publisherContext.getPublisherData();
                      setLoading(false);
                      appContext.setMessage('Your article was uploaded');
                      setUploadedTitle(title);
                      setType('free');
                      setSelectedTags([]);
                      setPrice(0);
                      setTitle('');
                      document.getElementById('_coverPhoto').value = "";
                      document.getElementById('_coverPhotoImg').src = logo;
                      editorRef.current.setContent("");
                      appContext.scrollToTop();
                    } else {
                      setLoading(false);
                      appContext.setError(response.msg);
                    }
                  })
                } else {
                  appContext.setError("Invalid Article body");
                }
              } else {
                appContext.setError("Invalid Price, minimum content price is 1000");
              }
            } else {
              appContext.setError("Invalid category");
            }
          } else {
            appContext.setError("Invalid type");
          }
        } else {
          appContext.setError("Invalid title");
        }
      } else {
        appContext.setError('Invalid Cover Photo');
      }
    }
  }

  useEffect(() => {
    if(type === 'free') {
      setPrice(0);
    }
  }, [ type ])

  useEffect(() => {
    init();
  }, [ ]);

  

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"20px" }}>
          <h4 className="font-light">Post New Article</h4>
        </div>

        {
          (uploadedTitle) ?
          <div className="col-12" style={{ marginBottom:"20px" }}>
            <div className="z-depth-1 bg-primary" style={{ width: "100%", padding: "10px", wordWrap: "break-word" }}>
              <h6 className="font-light">{uploadedTitle} was uploaded successfully</h6>
            </div>
          </div>: ""
        }

        <div className="col-md-6" style={{ marginBottom:"20px" }}>
          <input onChange={loadCoverPhoto} hidden={true} id="_coverPhoto" type="file" accept="image/*" />
          <small className="form-text font-light">Click the image below to select a cover photo for your article <span className="text-secondary">(recommended ratio is 1:2 )</span></small>
          <div onClick={() => document.getElementById('_coverPhoto').click()} className="CoverPhotoUploader text-center">
            <img src={logo} id="_coverPhotoImg"/>
          </div>
        </div>

        <div className="col-md-6" style={{ marginBottom:"20px" }}>

          <div className="form-group">
            <label>Type</label>
            <select value={type} onChange={(e) => { setType(e.target.value) }} className="form-control">
              <option value="paid" >Paid</option>
              <option value="free" >Free</option>
            </select>
          </div>

          {
            (type === 'paid') ?
            <div className="form-group">
              <h6 className="text-muted">Your readers will need CALIPA+ Subscription in order to read this article. You will be rewarded 50 Tsh per Unique View of your article</h6>
            </div>
            :
            <div className="form-group">
              <h6 className="text-muted">Any User will be able to view your article</h6>
            </div>
          }

          <div className="form-group">
            <label>Article Title (70 chars max)</label>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} maxLength={70} className="form-control"/>
          </div>

          <div className="form-group">
            <label>Categories & Tags</label>
            <div className="text-start">
              {
                (appContext.systemParams.contentTags && appContext.systemParams.contentTags.length > 0) ?
                appContext.systemParams.contentTags.map((item, i) => {
                  if(selectedTags.indexOf(item) > -1) {
                    //selected tag
                    return (
                      <button
                        className="btn btn-sm"
                        key={i}
                        style={{
                          color: "var(--on-primary)",
                          background: "var(--primary-color)",
                          margin: "5px",
                        }}
                        onClick={() => removeTag(item)}
                      >
                        {item}
                      </button>
                    )
                  } else {
                    //tag not sselected
                    return (
                      <button
                        className="btn btn-sm"
                        key={i}
                        style={{
                          color: "var(--primary-color)",
                          background: "none",
                          margin: "5px",
                        }}
                        onClick={() => addTag(item)}
                      >
                        {item}
                      </button>
                    )
                  }
                }): ""
              }
            </div>
            <h6 className="form-text">Select up to 3</h6>
          </div>

        </div>

        <div className="col-md-12" style={{ marginBottom:"40px" }}>

          <div className="form-group">
            <h5>Article Body</h5>
            <Editor
              tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue=""
              onNodeChange={(e) => {
                if (e && e.element.nodeName.toLowerCase() == 'img') {
                  editorRef.current.dom.setAttribs(e.element, {
                    display: 'block',
                    width: '100%',
                    height: 'auto',
                  })
                }
              }}
              init={{
                height: 500,
                body_class: "mceEditorBody",
                promotion: false,
                branding: false,
                menubar: true,
                mobile: {
                  menubar: true
                },
                plugins: [
                  'image',
                  'code',
                  'codesample',
                ],
                codesample_languages: [
                  { text: 'HTML/XML', value: 'markup' },
                  { text: 'JavaScript', value: 'javascript' },
                  { text: 'CSS', value: 'css' },
                  { text: 'PHP', value: 'php' },
                  { text: 'Ruby', value: 'ruby' },
                  { text: 'Python', value: 'python' },
                  { text: 'Java', value: 'java' },
                  { text: 'C', value: 'c' },
                  { text: 'C#', value: 'csharp' },
                  { text: 'C++', value: 'cpp' }
                ],
                toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat' + ' codesample ' + 'image',
                //..
                /* enable title field in the Image dialog*/
                image_title: true,
                /* enable automatic uploads of images represented by blob or data URIs*/
                automatic_uploads: true,
                /*
                  URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                  images_upload_url: 'postAcceptor.php',
                  here we add custom filepicker only to Image dialog
                */
                images_upload_url: BASE_API+'tinymce_post_acceptor.php',
                image_dimensions: false,
                file_picker_types: 'image',
                /* and here's our custom image picker*/
                file_picker_callback: function (cb, value, meta) {
                  var input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');

                  /*
                    Note: In modern browsers input[type="file"] is functional without
                    even adding it to the DOM, but that might not be the case in some older
                    or quirky browsers like IE, so you might want to add it to the DOM
                    just in case, and visually hide it. And do not forget do remove it
                    once you do not need it anymore.
                  */

                  input.onchange = function () {
                    var file = this.files[0];

                    var reader = new FileReader();
                    reader.onload = function () {
                      /*
                        Note: Now we need to register the blob in TinyMCEs image blob
                        registry. In the next release this part hopefully won't be
                        necessary, as we are looking to handle it internally.
                      */
                      var id = 'blobid' + (new Date()).getTime();
                      var blobCache =  editorRef.current.editorUpload.blobCache;
                      var base64 = reader.result.split(',')[1];
                      var blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);

                      /* call the callback and populate the Title field with the file name */
                      cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };

                  input.click();
                },
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px,  }',
                //..
                
              }}
              
            />
          </div>

          <div className="form-group text-end">
            <button onClick={upload} className="btn bg-primary text-light" style={{ minWidth: "100px", height: "60px" }}>Upload</button>
          </div>

        </div>

      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
