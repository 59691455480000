import { useState, useEffect } from "react";
import { Modal } from 'bootstrap';

export default function ConfirmDialog(props) {
  const [ show, setShow ] = useState(props.show);
  const [ message, setMessage ] = useState(props.message);
  const [ onConfirm, setOnConfirm ] = useState(() => { return props.onConfirm });

  
  const showDialog = () => {
    let modal = new Modal(document.getElementById('ConfirmDialog'));
    modal.show();
  }

  const hideDialog = () => {
    document.getElementById('_dialogCloser').click();
  }

  const handleConfirm = () => {
    onConfirm();
    setShow(false);
    setOnConfirm(null);
  }

  useEffect(() => {
    document.getElementById('ConfirmDialog').addEventListener('hide.bs.modal', (e) => {
      setShow(false);
    });
  }, [ ]);

  useEffect(() => {
    setShow(props.show);
    setMessage(props.message);
    setOnConfirm(() => { return props.onConfirm });
  }, [ props.show, props.message, props.onConfirm ]);

  

  useEffect(() => {
    if(show) {
      showDialog();
    } else {
      hideDialog();
    }
  }, [ show ]);

  return (
    <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="ConfirmDialog" tabIndex="-1" aria-labelledby="ConfirmDialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title font-semi-bold" id="">Confirm Dialog</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {message}
          </div>
          <div className="modal-footer">
            <button id="_dialogCloser" type="button" className="btn no-shadow text-accent font-bold" data-bs-dismiss="modal">No</button>
            <button onClick={handleConfirm} type="button" className="btn bg-accent font-bold btn-rounded">Yes</button>
          </div>
        </div>
      </div>
    </div>

  )
}