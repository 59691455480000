import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { PublisherContext } from "../main-views/PublisherPanel";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader, formatMoney, callApi, giv } from '../Helper';
import logo from '../assets/logo.png';
import OneContent from '../ones/OneContent';
import NewQuiz from '../views/NewQuiz';
import EditQuiz from '../views/EditQuiz';


export default function View(props) {
  const appContext = useContext(AppContext);
  const publisherContext = useContext(PublisherContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ type, setType ] = useState(props.type);
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ quizData, setQuizData ] = useState(null);

  const fetchContentData = () => {
    return new Promise(async resolve => {

      await callApi(`get_${type}_data.php`, { id }).then(async response => {
        if(response.status === 1) {
          setData(response.data);
          resolve(response.data);
        } else {
          appContext.setError(response.msg);
          resolve(null);
        }

      })
    })
  }

  const fetchQuizData = () => {
    return new Promise(async resolve => {

      await callApi(`get_quiz_data.php`, { type, id }).then(async response => {
        if(response.status === 1) {
          setQuizData(response.quizData);
          resolve(response.quizData);
        } else {
          appContext.setError(response.msg);
          resolve(null);
        }

      })
    })
  }

  const init = async () => {
    await fetchContentData().then(async (data) => {
      if(data) {
        if(Number(data.publisher) === Number(appContext.userData.id)) {
          await fetchQuizData().then(async (data) => {

            setReady(true);
          })
        } else {
          appContext.setError('You do not own this content');
        }
      } else {
        appContext.setError('Unknown error occurred');
      }
      setReady(true);
    })
  }

  const updateQuiz = async () => {
    if(!loading) {
      let description = giv('_description');
      let category = giv('_category');

      if(description.trim().length > 0) {
        if(category.trim().length > 0) {
          setLoading(true);
          await callApi(`update_${type}_data.php`, { id, category, description }).then(response => {
            if(response.status === 1) {
              appContext.setMessage('Content was updated');
            } else {
              appContext.setError(response.msg);
            }
            setLoading(false);
          })
        } else {
          appContext.setError('Invalid category');
        }
      } else {
        appContext.setError('Invalid description');
      }
    }
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData, type, id ]);

  useEffect(() => {
    setType(props.type);
    setId(props.id);
  }, [ props.type, props.id ])

  if(ready && data) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"20px" }}>
          <h4 className="font-light">Manage {type} Quiz</h4>
          <h5 className="font-semi-bold text-muted">{data.title}</h5>
        </div>

        <div className="col-md-12" style={{ marginBottom:"40px" }}>
          {/* imported code from old ui */}
          <div className="row">
            {
              (quizData === false)
              ?
              (
                <div className="col-sm-12 col-md-12">
                  <h6 className="text-danger">
                    No quiz is available for this content, please create one below.
                    Quizes are in form of multiple choice question tests with questions up to 20.
                    Answers will be shuffled automatically for you. This quiz will be used to offer certificates for readers
                    Your quiz should have atleast one question with all 4 aswers given including one right answer. Thanks
                    Save button is at the bottom
                  </h6>
                  <NewQuiz contentId={id} contentType={type} quizData={quizData}/>
                </div>
              )
              :
              (
                <div className="col-sm-12 col-md-12">
                  <h6 className="text-danger">
                    Quiz was found, you can edit questions & answers or add new ones below.
                    Please be informed that this quiz will be used to offer certificates for subscribers of your content here on CALIPA
                  </h6>
                  <h6 className="text-dark" style={{ marginTop:"20px" }}>
                    <i>This Quiz was last edited on {quizData.date}</i>
                  </h6>
                  <EditQuiz contentId={props.id} contentType={props.type} quizQns={JSON.parse(quizData.quizQns)}/>
                </div>
              )
            }
          </div>
          {/* end of imported code from old ui */}
        </div>


      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
