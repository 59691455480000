import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { PublisherContext } from "../main-views/PublisherPanel";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader, formatMoney, callApi, giv } from '../Helper';
import logo from '../assets/logo.png';
import OneContent from '../ones/OneContent';

export default function View(props) {
  const appContext = useContext(AppContext);
  const publisherContext = useContext(PublisherContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ type, setType ] = useState(props.type);
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);

  const fetchContentData = () => {
    return new Promise(async resolve => {
      await callApi(`get_${type}_data.php`, { id }).then(async response => {
        if(response.status === 1) {
          setData(response.data);
          resolve(response.data);
        } else {
          appContext.setError(response.msg);
          resolve(null);
        }

      })
    })
  }

  const init = async () => {
    await fetchContentData().then((data) => {
      if(data) {
        if(Number(data.publisher) === Number(appContext.userData.id)) {
          setReady(true);
        } else {
          appContext.setError('You do not own this content');
        }
      } else {
        appContext.setError('Unknown error occurred');
      }
    })
  }

  const updateInfo = async () => {
    if(!loading) {
      let description = giv('_description');
      let category = giv('_category');

      if(description.trim().length > 0) {
        if(category.trim().length > 0) {
          setLoading(true);
          await callApi(`update_${type}_data.php`, { id, category, description }).then(response => {
            if(response.status === 1) {
              appContext.setMessage('Content was updated');
            } else {
              appContext.setError(response.msg);
            }
            setLoading(false);
          })
        } else {
          appContext.setError('Invalid category');
        }
      } else {
        appContext.setError('Invalid description');
      }
    }
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData, type, id ]);

  useEffect(() => {
    setType(props.type);
    setId(props.id);
  }, [ props.type, props.id ])

  if(ready && data) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"20px" }}>
          <h4 className="font-light">Edit {type} information</h4>
          <h5 className="font-semi-bold text-muted">{data.title}</h5>
        </div>

        <div className="col-md-12" style={{ marginBottom:"40px" }}>
          <div className="form-group">
            <label>Description</label>
            <textarea id="_description" className="form-control" defaultValue={data.description}></textarea>
          </div>

          <div className="form-group">
            <label>category</label>
            <select id="_category" className="form-control" defaultValue={data.title}>
              <option value="technology">Technology</option>
              <option value="education">Education</option>
              <option value="social">Social</option>
              <option value="stories">Stories</option>
              <option value="novels">Novels</option>
              <option value="finance">Finance</option>
              <option value="business">Business</option>
              <option value="health">Health</option>
              <option value="soft-skills">Soft Skills</option>
              <option value="personal-development">Personal Development</option>
              <option value="psychology">Psychology</option>
            </select>
          </div>
        </div>

        {/*
        <div className="col-md-6" style={{ marginBottom:"40px" }}>
          <input onChange={loadCoverPhoto} hidden={true} id="_coverPhoto" type="file" />
          <small className="form-text font-light">Click the image below to select a cover photo for your book <span className="text-secondary">(recommended ratio is 2:1 or 1:1 )</span></small>
          <div onClick={() => document.getElementById('_coverPhoto').click()} className="CoverPhotoUploader text-center">
            <img src={logo} id="_coverPhotoImg"/>
          </div>
        </div>
        */}

        <div className="col-md-12" style={{ marginBottom:"40px" }}>
          <div className="form-group text-end">
            <button onClick={updateInfo} className="btn bg-primary text-light">UPDATE INFO</button>
          </div>
        </div>

      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
