import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { PublisherContext } from "../main-views/PublisherPanel";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiCoursestack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader, formatMoney, callApi, giv } from '../Helper';
import logo from '../assets/logo.png';
import OneContent from '../ones/OneContent';

export default function View(props) {
  const appContext = useContext(AppContext);
  const publisherContext = useContext(PublisherContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ courses, setCourses ] = useState(null);

  const fetchCourses = () => {
    return new Promise(async resolve => {
      await callApi('fetch_my_courses.php', {}).then(async response => {
        if(response.status === 1) {
          setCourses(response.courses);
        } else {
          appContext.setError(response.msg);
        }
        resolve();
      })
    })
  }

  const init = async () => {
    await fetchCourses().then(() => {
      setReady(true);
    })
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"20px" }}>
          <h4 className="font-light">Manage Courses</h4>
        </div>

        <div className="col-md-12" style={{ marginBottom:"40px" }}>
        {
          (courses && courses.length > 0) ?
          courses.map((item, i) => {
            return (
              <OneContent type="course" data={item} key={i}/>
            )
          })
          :
          <h6 className="font-light">No Courses were found</h6>
        }
        </div>


      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
