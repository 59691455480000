import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader } from '../Helper';
import logo from '../assets/logo.png';
import { ContentViewContext } from './ContentView';
import { BASE_API } from '../Helper';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';


export default function View(props) {

  const appContext = useContext(AppContext);
  const contentViewContext = useContext(ContentViewContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);



  function exitWatcher () {
    contentViewContext.setUrl(null);
  }


  const init = () => {
    setReady(false);
    if(contentViewContext.url && contentViewContext.data) {
      setReady(true);
      appContext.setHideTopBar(true);

      let menuHidder = (event) => {
        event.preventDefault();
        //props.loaderCallback(false);
      }

      document.addEventListener("contextmenu", menuHidder);

      return () => {
        document.removeEventListener("contextmenu", menuHidder);
      }
    } else {
      appContext.setError('Could not open this course');
    }
  }

  useEffect(() => {
    let finishers = init();
    return () => {
      appContext.setHideTopBar(false);
      finishers();
    }
  }, [ ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ]);


  return (
    <div className="container" style={{ padding: "0" }}>
      {
        (ready) ?
        <div className="row">
          <div className="col-md-12" style={{ padding: "0" }}>
            <Player preload="metadata" poster={logo} >
              <LoadingSpinner/>
              <BigPlayButton position="center" />
              <source src={BASE_API+contentViewContext.url} />
            </Player>
          </div>

          <div className="col-md-12 text-center" style={{ padding: "20px" }}>
            <button onClick={exitWatcher} style={{ marginTop: "var(--top-bar-height)" }} className="btn bg-secondary font-regular">EXIT PLAYER</button>
          </div>
        </div> :
        <div className="row">
          <div className="col-md-12 support-loading">
            {getInlineLoader()}
          </div>
        </div>
      }
    </div>
  )

}
