import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../App';
import { MdDashboard, MdManageAccounts } from 'react-icons/md';
import { FiActivity } from 'react-icons/fi';
import { IoIosPeople } from 'react-icons/io';
import { RiSearch2Fill } from 'react-icons/ri';
import logo from '../assets/logo.png';

export default function TopBar(props) {
  const appContext = useContext(AppContext);

  if(!appContext.hideTopBar) {
    return (
      <div  className="BottomBar d-flex justify-content-between">
        <div onClick={() => appContext.navTo({ item: 'content' })} className="mNavItem text-center align-self-center d-flex justify-content-center" data-selected={(appContext.navItem === 'content' || !appContext.navItem)}>
          <MdDashboard className="mNavIcon align-self-center flex-grow-1"/>
        </div>

        <div onClick={() => appContext.navTo({ item: 'search' })} className="mNavItem text-center align-self-center d-flex justify-content-center" data-selected={(appContext.navItem === 'search')}>
          <RiSearch2Fill className="mNavIcon align-self-center flex-grow-1"/>
        </div>

        <div onClick={() => appContext.navTo({ item: 'account' })} className="mNavItem text-center align-self-center d-flex justify-content-center" data-selected={(appContext.navItem === 'account' || appContext.navItem === 'publisher')}>
          <MdManageAccounts className="mNavIcon align-self-center flex-grow-1"/>
        </div>
      </div>
    )
  } else {
    return (
      <></>
    );
  }
}
