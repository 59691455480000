import { useState, useContext, createContext, useEffect, useRef } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader } from '../Helper';
import logo from '../assets/logo.png';
import { ContentViewContext } from './ContentView';
import { BASE_API, formatSecs } from '../Helper';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';
import ReactAudioPlayer from 'react-audio-player';
import { AiFillForward, AiFillBackward, AiOutlinePlayCircle, AiOutlinePauseCircle } from 'react-icons/ai';


export default function View(props) {

  const appContext = useContext(AppContext);
  const contentViewContext = useContext(ContentViewContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ duration, setDuration ] = useState(0);
  const [ playedTime, setPlayedTime ] = useState(0);
  //const [ audioEl, setAudioEl ] = useState(null);
  const [ initCompleted, setInitCompleted ] = useState(false);
  const [ audioStatus, setAudioStatus ] = useState('paused')
  const [ seekLength, setSeekLength ] = useState(0);

  const audioElRef = useRef(null);

  function exitListener () {
    pause();
    closeAudio();
    contentViewContext.setUrl(null);
  }

  function play () {
    if(audioElRef.current) {
      setAudioStatus('playing');
    }
  }

  function pause () {
    if(audioElRef.current) {
      setAudioStatus('paused');
    }
  }

  function initListener () {
    setDuration(audioElRef.current.duration);
    setPlayedTime(0);
    setLoading(false);
    setInitCompleted(true);
  }

  const seekTrackTo = (value) => {
    if(audioElRef.current) {
      audioElRef.current.currentTime = value;
    }
  }

  const seekForward = () => {
    let seekInterval = 5; //ten seconds back
    if(audioElRef.current) {
      let expectedTime = playedTime + seekInterval;
      if(expectedTime >= 0 && expectedTime <= duration) {
        seekTrackTo(expectedTime);
      }
    }
  }

  const closeAudio = () => {
    if(audioElRef.current) {
      audioElRef.current.pause();
    }
  }

  const seekBack = () => {
    let seekInterval = 5; //ten seconds back
    if(audioElRef.current) {
      let expectedTime = playedTime - seekInterval;
      if(expectedTime >= 0 && expectedTime <= duration) {
        seekTrackTo(expectedTime);
      }
    }
  }

  const init = () => {
    setReady(false);
    if(contentViewContext.url && contentViewContext.data) {
      audioElRef.current = new Audio(BASE_API+contentViewContext.url);

      setReady(true);
      setLoading(true);
      appContext.setHideTopBar(true);

      let menuHidder = (event) => {
        event.preventDefault();
        //props.loaderCallback(false);
      }

      document.addEventListener("contextmenu", menuHidder);

      return () => {
        document.removeEventListener("contextmenu", menuHidder);
      }
    } else {
      appContext.setError('Could not open this course');
    }
  }

  useEffect(() => {
    let finishers = init();
    return () => {
      appContext.setHideTopBar(false);
      finishers();
      closeAudio();
    }
  }, [ ]);

  useEffect(() => {
    if(audioElRef.current && !initCompleted) {
      let timer = setInterval(() => {
        setPlayedTime(audioElRef.current.currentTime);
      }, 1000);

      audioElRef.current.addEventListener('canplay', initListener);
      return (() => {
        audioElRef.current.removeEventListener('canplay', initListener);
        clearInterval(timer);
      })
    }
  }, [ audioElRef.current ]);

  useEffect(() => {
    if(playedTime > 0) {
      setSeekLength(playedTime);
    }
  }, [ playedTime ])

  useEffect(() => {
    if(audioElRef.current) {
      if(audioStatus === 'paused') {
        audioElRef.current.pause();
      } else if(audioStatus === 'playing') {
        audioElRef.current.play();
      }
    }
  }, [ audioStatus ]);

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ]);


  return (
    <div className="container" style={{ padding: "0" }}>
      {
        (ready) ?
        <div className="row">
          <div className="col-md-12" style={{ padding: "0" }}>
            <div className="" style={{ maxWidth: "500px", margin: "0 auto", padding: "0px" }}>
              <div className="z-depth-1" style={{ width: "100%", maxHeight:"30vh", overflow: "hidden" }}>
                <img src={BASE_API+contentViewContext.data.coverPhoto} style={{ width: "100%", height: "auto" }} />
              </div>

              <div className="mp3Track" style={{ width: "100%" }}>
                <input className="mProgress" type="range" min="0" max={duration} value={seekLength} onChange={(e) => seekTrackTo(e.target.value)}/>
              </div>

              <div className="text-center" style={{ padding: "10px" }}>
                <h2 className="font-light">{formatSecs(seekLength)} / {formatSecs(duration)}</h2>
              </div>

              <div className="d-flex justify-content-between" style={{ padding: "10px", width: "100%" }}>
                <button className="mp3Btn" onClick={seekBack}><AiFillBackward/></button>
                {
                  (audioStatus === 'paused') ?
                  <button onClick={play} className="mp3BtnLg"><AiOutlinePlayCircle/></button> :
                  <button onClick={pause} className="mp3BtnLg"><AiOutlinePauseCircle/></button>
                }
                <button className="mp3Btn" onClick={seekForward}><AiFillForward/></button>
              </div>

              <div className="text-center" style={{ padding: "10px" }}>
                <button onClick={exitListener} className="btn bg-secondary text-dark">Exit Player</button>
              </div>
              
              <div className="text-start" style={{ padding: "10px" }}>
                <h2 className="font-light">{contentViewContext.data.title}</h2>
              </div>

              <div className="text-start" style={{ padding: "10px" }}>
                <h6 className="font-light text-muted">By: {contentViewContext.data.authorName}</h6>
              </div>

            </div>
          </div>

        </div> :
        <div className="row">
          <div className="col-md-12 support-loading">
            {getInlineLoader()}
          </div>
        </div>
      }
    </div>
  )

}
