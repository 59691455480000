import { useState, useEffect, createContext } from "react";
import { useParams } from "react-router-dom";
import MainBody from "./components/MainBody";
import ConfirmDialog from "./components/ConfirmDialog";
import MainLoader from "./components/MainLoader";
import OverlayLoader from "./components/OverlayLoader";
import TopBar from './components/TopBar';
import BottomBar from './components/BottomBar';
import BookControls from './components/BookControls';
import Viewer from './main-views/Viewer';
import { callApi, getCookie, getDistanceFromLatLonInKm, getViewerView, resolveMainView, setCookie } from './Helper';

import { Toaster, toast } from 'react-hot-toast';


export const AppContext = createContext(null);
export default function App (props) {



  const { _navItem, _navSubItem, _navExtraItem } = useParams();
  const [ navItem, setNavItem ] = useState((_navItem) ? _navItem : 'content');
  const [ rememberedNav, setRememberedNav ] = useState(null);
  const [ navSubItem, setNavSubItem ] = useState(_navSubItem);
  const [ navExtraItem, setNavExtraItem ] = useState(_navExtraItem);
  const [ showDialog, setShowDialog ] = useState(false);
  const [ dialogMessage, setDialogMessage ] = useState("");
  const [ dialogOnConfirm, setDialogOnConfirm ] = useState(null);
  const [ ready, setReady ] = useState(false);
  const [ auth, setAuth ] = useState(false);
  const [ userData, setUserData ] = useState(null);
  const [ booksCol, setBooksCol ] = useState(null);
  const [ podcastsCol, setPodcastsCol ] = useState(null);
  const [ coursesCol, setCoursesCol ] = useState(null);
  const [ articlesCol, setArticlesCol ] = useState(null);
  const [ certs, setCerts ] = useState(null);
  const [ error, setError ] = useState(false);
  const [ message, setMessage ] = useState(false);
  const [ db, setDb ] = useState(null);
  const [ showViewer, setShowViewer ] = useState(false);
  const [ viewType, setViewType ] = useState(false);
  const [ viewId, setViewId ] = useState(false);
  const [ showOverlayLoader, setShowOverlayLoader ] = useState(false);
  const [ hideTopBar, setHideTopBar ] = useState(false);
  const [ showBookControls, setShowBookControls ] = useState(false);
  const [ bookReaderContext, setBookReaderContext ] = useState(null);
  const [ systemParams, setSystemParams ] = useState({});
  const [ navHistory, setNavHistory ] = useState([]);
  const [ following, setFollowing ] = useState(null);
  const [ followers, setFollowers ] = useState(null);

  //views mods
  const [ mainView, setMainView ] = useState(<></>);
  const [ viewerView, setViewerView ] = useState(<></>);


  const getSavedTheme = () => {
    //check if theme field in the db is set (vaues can be default, light, dark);
    let _savedTheme = 'default'; //default
    if(getCookie('theme')) {
      return getCookie('theme');
    } else {
      setCookie('theme', 'default', 3650)
    }
    return _savedTheme;
  }

  const getDefaultDeviceTheme = () => {
    let _deviceTheme = 'dark'; //default

    if(window.matchMedia) {
      if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
        _deviceTheme = 'dark';
      } else if(window.matchMedia("(prefers-color-scheme: light)").matches) {
        _deviceTheme = 'light';
      }
    }

    return _deviceTheme;
  }

  const setAppTheme = (_theme) => {
    let _themeToSet = (_theme === 'default') ? getDefaultDeviceTheme() : _theme;
    document.documentElement.setAttribute('data-theme', _themeToSet);
  }

  const updateTheme = (_theme) => {
    setCookie('theme', _theme, 360);
  }

  const toggleTheme = () => {
    let _currentTheme = getSavedTheme();
    _currentTheme = (_currentTheme === 'default') ? getDefaultDeviceTheme() : _currentTheme;
    let _theme = null;
    if(_currentTheme === 'light') {
      _theme = 'dark';
    } else if(_currentTheme === 'dark') {
      _theme = 'light';
    }

    updateTheme(_theme);
    setAppTheme(_theme);
  }

  
  const refresh = () => {
    init();
  }

  const goBack = () => {
    //listen for back navigation and call this function there
    window.history.back();
    
  }

  const navBack = goBack;

  const deleteLocalUsers = () => {
    return new Promise(async resolve => {
      setCookie('userId', '', -30);
      setCookie('userToken', '', -30);
      resolve(true);
    })
  }

  

  const saveLocalUser = (user) => {
    return new Promise (async resolve => {
      //delete local users firt
      await deleteLocalUsers().then(async () => {
        //thenn save this user
        setCookie('userId', user.userId, 90);
        setCookie('userToken', user.userToken, 90);
        resolve(true);
        
      })
    })
  }


  const getLocalUser = () => {
    return new Promise(async resolve => {
      //use cookies
      let userId = getCookie('userId');
      let userToken = getCookie('userToken');
      resolve({
        userId,
        userToken,
      })

      
    })
  }


  const isBookInCol = (book) => {
    let inCol = false;
    if(book) {
      if(userData) {
        if(Number(userData.id) === Number(book.publisher)) {
          inCol = true;
        }

        if(booksCol) {
          booksCol.forEach((item, i) => {
            if(Number(item.id) === Number(book.id)) {
              inCol = true;
            }
          });
        }
      }
    }
    return inCol;
  }

  const isPodcastInCol = (podcast) => {
    let inCol = false;
    if(podcast) {
      if(userData) {
        if(Number(userData.id) === Number(podcast.publisher)) {
          inCol = true;
        }

        if(podcastsCol) {
          podcastsCol.forEach((item, i) => {
            if(Number(item.id) === Number(podcast.id)) {
              inCol = true;
            }
          });
        }
      }
    }
    return inCol;
  }

  const isCourseInCol = (course) => {
    let inCol = false;
    if(course) {
      if(userData) {
        if(Number(userData.id) === Number(course.publisher)) {
          inCol = true;
        }

        if(coursesCol) {
          coursesCol.forEach((item, i) => {
            if(Number(item.id) === Number(course.id)) {
              inCol = true;
            }
          });

        }
      }
    }
    return inCol;
  }

  const isArticleInCol = (article) => {
    let inCol = false;
    if(article) {
      if(userData) {
        if(Number(userData.id) === Number(article.publisher)) {
          inCol = true;
        }

        if(articlesCol) {
          articlesCol.forEach((item, i) => {
            if(Number(item.id) === Number(article.id)) {
              inCol = true;
            }
          });

        }
      }
    }
    return inCol;
  }

  

  const navTo = async  (nav, rememberHistory = false) => {
    

    let link = '';

    if(nav.item) {
      link += `#/${nav.item}`
    }

    if(nav.subItem) {
      link += `/${nav.subItem}`
    }

    if(nav.extraItem) {
      link += `/${nav.extraItem}`
    }


    //check if history has to be remembered
    if(
      rememberHistory || 
      (
        (nav.item === 'account' && nav.subItem === 'verification') ||
        (nav.item === 'account' && nav.subItem === 'login') ||
        (nav.item === 'account' && nav.subItem === 'register') ||
        (nav.item === 'account' && nav.subItem === 'reset')
      )
    ) {
      
      if(rememberHistory) {
        setRememberedNav({
          item: navItem,
          subItem: navSubItem,
          extraItem: navExtraItem,
        });
      }

      
    }

    window.location.href = link;
  }


  const hasFollowed = (_authorId) => {
    //this function checks if current user can follow the given authorId
    if(auth === 1) {
      //check if not the same user
      if(userData && Number(userData.id) !== Number(_authorId)) {
        //check if not followed
        let _hasFollowed = false;
        if(following && following.length > 0) {
          following.forEach((item, i) => {
            if(Number(item.userId) === Number(_authorId)) {
              _hasFollowed = true;
            }
          })
        }

        return _hasFollowed;

      }
    }

    return false;
  }

  const canFollow =  (_authorId) => {
    //this function checks if current user can follow the given authorId
    if(auth === 1) {
      //check if not the same user
      if(userData && Number(userData.id) !== Number(_authorId)) {
        //check if not followed
        let _hasFollowed = false;
        if(following && following.length > 0) {
          following.forEach((item, i) => {
            if(Number(item.userId) === Number(_authorId)) {
              _hasFollowed = true;
            }
          })
        }

        if(!_hasFollowed) {
          return true;
        }
      }

    }

    return false;
  }

  const authCheck = (silently = true) => {
    return new Promise(async resolve => {
      if(!silently) {
        setReady(false);
      }

      //resolve(false);

      
      await callApi('auth.php', {}).then(async response => {
        if(!silently) {
          setReady(true);
        }
        if(response.status === 1) {
          if(response.auth > 0) {
            setAuth(response.auth);
            setUserData(response.userData);
            setBooksCol(response.booksCol);
            setPodcastsCol(response.podcastsCol);
            setCoursesCol(response.coursesCol);
            setArticlesCol(response.articlesCol);
            setCerts(response.certs);
            setFollowers(response.followers);
            setFollowing(response.following);
            resolve(response);
          } else {
            //check if local user was saved
            await getLocalUser().then(async user => {
        
              if(user) {
                //request login from local
                //..
                await callApi('login_from_local.php', {...user}).then(async response2 => {
        
                  if(response2.status === 1) {
                    //recall auth
                    await authCheck().then(response3 => resolve(response3))
                  } else {
                    if(!silently) {
                      setAuth(0);
                      setUserData(null);
                      setBooksCol(null);
                      setPodcastsCol(null);
                      setCoursesCol(null);
                      setArticlesCol(null);
                      setCerts(null);
                      setFollowers(null);
                      setFollowing(null);
                    }
                    resolve(response);
                  }
                })
                //..
              } else {
                if(!silently) {
                  setAuth(0);
                  setUserData(null);
                  setBooksCol(null);
                  setPodcastsCol(null);
                  setCoursesCol(null);
                  setArticlesCol(null);
                  setCerts(null);
                  setFollowers(null);
                  setFollowing(null);
                }
                resolve(response);
              }
            })
          }
        } else {
          setError(response.msg);
          resolve(response);
        }
        
      })
      
    })
  }

  const getSystemParams = () => {
    return new Promise(async resolve => {
      await callApi('get_system_params.php', { }).then((response) => {
        if(response.status === 1) {
          setSystemParams(response.params);
          resolve(true);
        } else {
          resolve(false);
          tellError(response.msg);
        }
      })
    })
  }

  const scheduleAutoAuthRefresher = () => {
    setTimeout(() => {
      authCheck(true);
    }, 30000) //after 0.5 min
    
    setTimeout(() => {
      authCheck(true);
    }, 60000) //after 1 min

    setTimeout(() => {
      authCheck(true);
    }, 90000) //after 1.5 min

    setTimeout(() => {
      authCheck(true);
    }, 120000) //after 2 min

    setTimeout(() => {
      authCheck(true);
    }, 150000) //after 2.5 min

    setTimeout(() => {
      authCheck(true);
    }, 180000) //after 3 min

    setTimeout(() => {
      authCheck(true);
    }, 210000) //after 3.5 min

    setTimeout(() => {
      authCheck(true);
    }, 240000) //after 4 min

    setTimeout(() => {
      authCheck(true);
    }, 270000) //after 4.5 min

    setTimeout(() => {
      authCheck(true);
    }, 300000) //after 5 min
  }

  const init = async () => {
    setAppTheme(getSavedTheme());
    setReady(false);
    

    await getSystemParams().then(async () => {
      await authCheck().then(async () => {
        if(!navItem) {
          navTo({
            item: 'content',
          })
        }
        
        //set timer to refresh auth after every 1 min

        setReady(true);
      })
    })
  }

  const tellError = () => {
    toast.error(error, {
      style: {
        borderRadius: '10px',
        background: 'var(--surface-color)',
        color: 'var(--on-surface)',
      },
    });
    setError(false);
  }

  const scrollToTop = () => {
    window.scrollTo(0,0);
  }

  const tellMessage = () => {
    toast.success(message, {
      style: {
        borderRadius: '10px',
        background: 'var(--surface-color)',
        color: 'var(--on-surface)',
      },
    });
    setMessage(false);
  }

  const superNavTo = (nav = null) => {
    //this checks if there is recorded nav history
    if(rememberedNav) {
      navTo(rememberedNav);
    } else {
      //use normal nav
      if(nav) {
        navTo(nav);
      }
    }
  }
  

  const activateDialog = (params) => {
    let {
      message,
      onConfirm
    } = params;
    setDialogOnConfirm(() => { return onConfirm });
    setDialogMessage(message);
    setShowDialog(true);
  }



  useEffect(() => {
    init()
  }, [ ])

  

  useEffect(() => {
    if(message) {
      tellMessage(message);
    }
  }, [ message ])

  useEffect(() => {
    if(error) {
      tellError(error);
    }
  }, [ error ])

  useEffect(() => {
    if(!showBookControls) {
      setBookReaderContext(null);
    }
  }, [ showBookControls ]);

  useEffect(() => {
    if(userData) {
      if(window.calipaApp && window.calipaApp.setOneSignalExternalUserId) {
        try {
          window.calipaApp.setOneSignalExternalUserId(userData.id);
        } catch(error) {
          //..onesignal error
        }
      }
    }
  }, [ userData ])

  useEffect(() => {
    setNavItem((_navItem) ? _navItem : 'content');
    setNavSubItem(_navSubItem);
    setNavExtraItem(_navExtraItem);
  }, [ _navItem, _navSubItem, _navExtraItem ])

  useEffect(() => {
    //scroll to top
    //scrollToTop();

    if(!navItem) {
      setNavItem('content')
    }

    //check for viewerView
    if(navItem === 'view' ) {
      //activate viewer
      setShowViewer(true);
      setViewerView(getViewerView(navSubItem, navExtraItem));
    } else {
      //just set normal views
      setShowViewer(false);
      setViewerView(null);
      setMainView(resolveMainView(appContext))
    }
  }, [ navItem, navSubItem, navExtraItem ])

  const appContext = {
    navItem,
    navSubItem,
    navExtraItem,
    navTo,
    superNavTo,
    activateDialog,
    refresh,
    userData,
    auth,
    error,
    ready,
    setError,
    setMessage,
    goBack,
    setShowViewer,
    showViewer,
    viewId,
    viewType,
    isCourseInCol,
    isBookInCol,
    authCheck,
    showOverlayLoader,
    setShowOverlayLoader,
    hideTopBar,
    setHideTopBar,
    setShowBookControls,
    showBookControls,
    setBookReaderContext,
    bookReaderContext,
    booksCol,
    coursesCol,
    podcastsCol,
    articlesCol,
    isPodcastInCol,
    isArticleInCol,
    certs,
    systemParams,
    setAppTheme,
    getSavedTheme,
    navBack,
    getLocalUser,
    deleteLocalUsers,
    saveLocalUser,
    toggleTheme,
    canFollow,
    hasFollowed,
    followers,
    following,
    rememberedNav,
    scrollToTop,
    viewerView,
    mainView,
    scheduleAutoAuthRefresher,
  }

  if(ready) {
    return (
      <AppContext.Provider value={appContext}>
        <OverlayLoader/>
        <BookControls/>
        <Toaster
          position="bottom-center"
          reverseOrder={true}
          containerStyle={{
            zIndex: "calc(1 + var(--super-ultra-high-index))"
          }}
        />
        <ConfirmDialog show={showDialog} message={dialogMessage} onConfirm={dialogOnConfirm}/>
        <TopBar/>
        <MainBody/>
        <Viewer/>
        <BottomBar/>
      </AppContext.Provider>
    )
  } else {
    return (
      <AppContext.Provider value={appContext}>
        <Viewer/>
        <MainLoader/>
      </AppContext.Provider>
    )
  }

}
