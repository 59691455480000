import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader, formatDate } from '../Helper';
import logo from '../assets/logo.png';
import ContentFeed from '../ones/ContentFeed';

export default function View(props) {
  const appContext = useContext(AppContext);
  const [ ready, setReady ] = useState(false);

  const init = () => {
    if(appContext.auth === 1) {
      //stay here
      setReady(true);
    } else {
      
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
    }
  }

  useEffect(() => {
    init();
  }, [ ]);

  useEffect(() => {
    init();
  }, [ appContext.auth, appContext.userData ]);

  if(ready) {
    return (
      <div className="row">
        <div className="col-12" style={{ marginBottom:"40px" }}>
          <h4 className="font-light">Notifications</h4>
        </div>

        <div className="col-md-12" style={{ marginBottom:"40px" }}>
          {
            (appContext.userData && JSON.parse(appContext.userData.notifications).length > 0) ?
            JSON.parse(appContext.userData.notifications).reverse().map((item, i) => {
              return (
                <div className="text-start z-depth-1" key={i} style={{ marginBottom: "10px", padding: "10px" }}>
                  <small className="text-secondary font-light">{formatDate(item.date)}</small><br/>
                  <p className="font-light" style={{ fontSize: "14px" }}>{item.message}</p>

                </div>
              )
            }) :
            <small className="font-light form-text">No Notifications were found</small>
          }
        </div>


      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12 support-loading">
          {getInlineLoader()}
        </div>
      </div>
    )
  }
}
