import { useState, useContext, createContext, useEffect, useRef } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import logo from '../assets/logo.png';
import calipa_home_banner_1 from '../assets/calipa_home_banner_1.png';
import { BASE_API, callApi, getInlineLoader, textShortener } from "../Helper";
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript


export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setready ] = useState(false);
  const [ featuredContent, setFeaturedContent ] = useState(null);
  
  async function getFeatured() {
    await callApi('fetch_featured_content.php', { }).then((response) => {
      if(response.status === 1) {
        setFeaturedContent(response.featured);
      }
    })
  }

  async function init() {
    setready(false);
    await getFeatured();
    setready(true);
  }

  
  
  useEffect(() => {
    init();
  }, [])

  

  

  //render
  if(ready && featuredContent && featuredContent.length > 0) {
    return (
      <div className="Banner z-depth-1" data-is-search={(appContext.navItem === 'search')}>
        
        <div id="carouselBanner" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000" style={{ height: "100%", width: "100%" }}>
          <div className="carousel-inner" style={{ height: "100%", width: "100%" }}>
          
            {
              featuredContent.map((item, i) => {
                
                return (
                  <div 
                    className={(i===0) ? "carousel-item active" : "carousel-item"}  
                    key={i}
                    id={"banner-slide-"+i}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div 
                      className="mSlide"
                      style={{
                        backgroundImage: `url(${BASE_API+item.coverPhoto})`,
                      }}
                    >
                      
                      <img src={calipa_home_banner_1} />
                      <div
                        className="mInfo"
                      >
                        <div>
                          <h6 style={{ fontSize: "12px", textTransform: "capitalize" }} className="text-muted font-bold">
                            {(item.contentType === 'course') ? "Video" : item.contentType}
                          </h6>
                          <h5
                            style={{
                              fontSize: "20px",
                              width: "150px",
                              wordWrap: "break-word",
                              paddingRight: "10px",
                            }}
                            className="font-regular"
                          >
                            {textShortener(item.title, 40)}
                          </h5>
              
                          <button
                            className="btn bg-primary"
                            style={{ marginTop: "5px", display: "block" }}
                            onClick={() => {
                              appContext.navTo({
                                item: 'view',
                                subItem: item.contentType,
                                extraItem: item.id,
                              })
                            }}
                          >
                            View
                          </button>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                )
              })
            }
            
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselBanner" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselBanner" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <h6
          className="bg-dark font-semi-bold"
          style={{
            position: 'absolute',
            width: "auto",
            height: 'auto',
            padding: "5px",
            borderRadius: "5px",
            fontSize: "12px",
            left: "10px",
            top: "10px"
          }}
        >
          Featured
        </h6>
      </div>
    )
  } else {
    return (
      <div className="Banner z-depth-1">
        {getInlineLoader()}
      </div>
    )
  }
}
