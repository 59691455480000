import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../App';

export default function SwipeTabs(props) {
  const appContext = useContext(AppContext);
  const [ tabs, setTabs ] = useState(props.tabs);

  const scrollTop = () => {
    document.getElementById('fullPane').scrollTo({
     top: 0,
     behavior: "smooth"
   });
  }

  useEffect(() => {
    setTabs(props.tabs);
  }, [ props.tabs ]);

  return (
    <>
      <div className="TabPills">
        <ul 
          className="nav nav-pills mb-3 d-flex container" 
          id="pills-tab" 
          role="tablist" 
          style={{ 
            height: "100%", 
            padding: "10px", 
            margin: "0px", 
            position: "absolute", 
            left: "50%", 
            transform: "translateX(-50%)",
            flexWrap: "nowrap",
            overflow: "auto",
            }}
          >
          {
            tabs.map((item, i) => {
              if(i === 0) {
                return (
                  <li className="nav-item align-self-center" role="presentation" key={i} onClick={scrollTop}>
                    <button style={{ fontSize: "12px" }} className="nav-link mOneLineText active" id={"pills-"+item.id+"-tab"} data-bs-toggle="pill" data-bs-target={"#pills-"+item.id} type="button" role="tab" aria-controls={"pills-"+item.id} aria-selected="true">{item.title}</button>
                  </li>
                );
              } else {
                return (
                  <li className="nav-item align-self-center" role="presentation" key={i} onClick={scrollTop}>
                    <button style={{ fontSize: "12px" }} className="nav-link mOneLineText" id={"pills-"+item.id+"-tab"} data-bs-toggle="pill" data-bs-target={"#pills-"+item.id} type="button" role="tab" aria-controls={"pills-"+item.id} aria-selected="false">{item.title}</button>
                  </li>
                )
              }
            })
          }
        </ul>
      </div>


      <div className="tab-content" id="pills-tabContent" style={{ paddingTop: (appContext.navItem === 'search' && !appContext.showViewer) ? "calc(2 * var(--top-bar-height))" : "var(--top-bar-height)", paddingBottom: "var(--top-bar-height)", paddingLeft: "0px", paddingRight: "0px" }}>
        {
          tabs.map((item, i) => {
            if(i === 0) {
              return (
                <div className="tab-pane mNoPad mNoMargin fade show active" id={"pills-"+item.id} role="tabpanel" aria-labelledby={"pills-"+item.id+"-tab"} key={i}>
                  {item.view}
                </div>
              );
            } else {
              return (
                <div className="tab-pane fade mNoPad mNoMargin show" id={"pills-"+item.id} role="tabpanel" aria-labelledby={"pills-"+item.id+"-tab"} key={i}>
                  {item.view}
                </div>
              );
            }
          })
        }
      </div>
    </>
  )
}
