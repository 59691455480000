import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader, callApi, formatMoney, giv, openExternalUrl } from '../Helper';
import logo from '../assets/logo.png';
import { ContentViewContext } from './ContentView';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { BASE_API, formatSecs } from '../Helper';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';
import ReactAudioPlayer from 'react-audio-player';
import { AiFillForward, AiFillBackward, AiOutlinePlayCircle, AiOutlinePauseCircle } from 'react-icons/ai';


export default function View(props) {

  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ stepShown, setStepShown ] = useState(1);
  const [ error, setError ] = useState('');
  const [ payWith, setPayWith ] = useState('mobile_money');
  const [ phone, setPhone ] = useState('');
  const [ period, setPeriod ] = useState(props.period);

  function exitForm () {
    appContext.navBack();
  }

  const initPay = async () => {
    if(!loading) {

      if((payWith === 'mobile_money' && phone.trim().length === 10) || payWith === 'credit_card' ) {
        setLoading(true);
        await callApi('confirm_buy.php', { id: 'calipa_plus', type: period, phone, gateway: "selcom", payWith }).then(response => {
          setLoading(false);
          if(response.status === 1) {
            setStepShown(2);
            if(payWith === 'credit_card') {
              openExternalUrl(response.payment_gateway_url)
            }
          } else {
            setError(response.msg);
            setStepShown(3);
            appContext.setError(response.msg);
          }
        })
      } else {
        appContext.setError('Invalid mobile phone number');
      }
    }
  }

  const init = async () => {
    setReady(false);

    if(appContext.auth === 1) {
      //stay here
      setReady(true);
    } else {
      appContext.navTo({
        item: 'account',
        subItem: 'login',
      }, true);
      appContext.setError('Login or Register please');
    }
  }

  useEffect(() => {
    let finishers = init();
    return () => {
      appContext.setHideTopBar(false);
    }
  }, [ ]);

  useEffect(() => {
    let finishers = init();
    return () => {
      appContext.setHideTopBar(false);
    }
  }, [ period ]);

  useEffect(() => {
    setPeriod(props.period);
  }, [ props.period ]);

  useEffect(() => {
    //appContext.setShowOverlayLoader(loading);
  }, [ loading ]);



  return (
    <div className="container" style={{ padding: "0" }}>
      {
        (ready) ?
        <div className="row">
          {
            (stepShown === 1) ?
            <>
              
              <div className="col-md-6" style={{ padding: "10px" }}>
                <div className="form-group" style={{ marginBottom: "20px" }}>
                  <h4>
                    {(period === 'yearly') ? "CALIPA+ One Year Subscription": ""}
                    {(period === 'monthly') ? "CALIPA+ One Month Subscription": ""}
                  </h4>
                  <small className="font-bold text-muted">Item Title</small>
                </div>

                <div className="form-group" style={{ marginBottom: "20px" }}>
                  <h5 className="font-light" style={{ textTransform: "capitalize" }}><span className="font-bold text-secondary">CALIPA+</span> Subscription</h5>
                  <small className="font-bold text-muted">Item Type</small>
                </div>

                <div className="form-group" style={{ marginBottom: "20px" }}>
                  <h1 className="font-light">
                    {(period === 'yearly') ? "50,000": ""}
                    {(period === 'monthly') ? "5,000": ""}
                    Tsh
                  </h1>
                  <small className="font-bold text-muted">Item Price</small>
                </div>

              </div>

              

              {
                (payWith === 'mobile_money') ?
                <div className="col-md-6" style={{ padding: "10px" }}>
                  <div className="form-group">
                    <label>Select Payment Method</label>
                    <select className="form-control" value={payWith} onChange={(e) => setPayWith(e.target.value)}>
                      <option value='mobile_money' >Mobile Money - All Tanzanian Networks</option>
                      <option value='credit_card' >Credit/Debit Card - Visa, MasterCard, Express</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Enter your mobile phone number (All networks)</label>
                    <input value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" placeholder="07XXXXXXXX" maxLength={10}/>
                    <small className="form-text">Ten digits wthout a country code, eg 07XXXXXXXX or 06XXXXXXXX</small>
                  </div>

                  <div className="form-group text-end">
                    <button onClick={initPay} className="btn bg-secondary support-loading">{(loading) ? getInlineLoader() : "Pay Now"}</button>
                  </div>
                </div>: ""
              }

              {
                (payWith === 'credit_card') ?
                <div className="col-md-6" style={{ padding: "10px" }}>
                  <div className="form-group">
                    <label>Select Payment Method</label>
                    <select className="form-control" value={payWith} onChange={(e) => setPayWith(e.target.value)}>
                      <option value='mobile_money' >Mobile Money - All Tanzanian Networks</option>
                      <option value='credit_card' >Credit/Debit Card - Visa, MasterCard, Express</option>
                    </select>
                  </div>

                  <div className="form-group text-end">
                    <button onClick={initPay} className="btn bg-secondary support-loading">{(loading) ? getInlineLoader() : "Pay Now"}</button>
                  </div>
                </div>: ""
              }
            </>: ""
          }

          {
            (stepShown === 2) ?
            <>
              {
                (payWith === 'mobile_money') ?
                <div className="col-md-12 text-center" style={{ padding: "10px" }}>
                  <h5 className="font-light text-muted">Check your phone, we sent a Push USSD Notification to you. Please complete the payment and we'll process your purchase. Thanks</h5>
                  <hr/>
                  <button className="support-loading btn bg-secondary" onClick={exitForm}>Close</button>
                </div> : ""
              }

              {
                (payWith === 'credit_card') ?
                <div className="col-md-12 text-center" style={{ padding: "10px" }}>
                  <h5 className="font-light text-muted">Please wait and You'll be redirected to Credit/Debit card payment gateway. Complete the payment and we'll process your purchase. Thanks</h5>
                  <hr/>
                  <button className="support-loading btn bg-secondary" onClick={exitForm}>Close</button>
                </div> : ""
              }
            </>: ""
          }

          {
            (stepShown === 3) ?
            <>
              <div className="col-md-12 text-center" style={{ padding: "10px" }}>
                <h4 className="font-light text-muted">We could not process your payment due to an error</h4>
                <h6 className="font-semi-bold text-danger">{error}</h6>
                <hr/>
                <button className="support-loading btn bg-secondary" onClick={() => setStepShown(1)}>Retry</button>
              </div>
            </>: ""
          }
        </div> :
        <div className="row">
          <div className="col-md-12 support-loading">
            {getInlineLoader()}
          </div>
        </div>
      }
    </div>
  )

}
