import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight } from 'react-icons/md';
import NewsFeed from '../views/NewsFeed';
import { AiOutlineRight } from 'react-icons/ai';

export default function View(props) {
  const appContext = useContext(AppContext);

  return (
    <div className="row">
      <div className="col-12">
        <NewsFeed isHome={false} isSearch={true} type={false} category={false}/>
      </div>
    </div>
  )
}
