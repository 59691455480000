import { useState, useContext, createContext } from "react";
import { AppContext } from "../App";
import { resolveMainView } from "../Helper";


export default function MainBody(props) {
  const appContext = useContext(AppContext);

  return (
    <div className="MainBody" id="mainBody" data-hidetopbar={appContext.hideTopBar}>
      <div className="container">
        {appContext.mainView}
      </div>
    </div>
  )
}
