import { useState, useContext, createContext, useEffect } from "react";
import { AppContext } from "../App";
import { MdArrowRight, MdPodcasts } from 'react-icons/md';
import { AiOutlineRight } from 'react-icons/ai';
import { CgMediaPodcast } from 'react-icons/cg';
import { SiBookstack } from 'react-icons/si';
import { RiVideoChatLine } from 'react-icons/ri';
import { getInlineLoader } from '../Helper';
import logo from '../assets/logo.png';
import { ContentViewContext } from './ContentView';
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import { BASE_API } from '../Helper';

export default function View(props) {

  pdfjs.GlobalWorkerOptions.workerSrc =  `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const appContext = useContext(AppContext);
  const contentViewContext = useContext(ContentViewContext);
  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ numPages, setNumPages] = useState(null);
  const [ pageNumber, setPageNumber] = useState(1);
  const [ pageWidth, setPageWidth ] = useState(0);
  const [ pageScaled, setPageScaled ] = useState(false);
  let resizeObserver = null;

  const scalePage = (add = true) => {
    setPageScaled(true);
    setPageWidth(prevWidth => {
      let result = 100;
      if(add) {
        result = prevWidth + 100
      } else {
        result = prevWidth - 100;
      }

      if(result >= 100 && result <= 3000) {
        return result;
      } else {
        appContext.setError("No more zoom");
        return prevWidth;
      }
    })
  }

  const resetPageWidth = () => {
    setPageWidth(document.getElementById('_pageReader').clientWidth);
    setPageScaled(false);
  }



  const docLoaded = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setPageWidth(document.getElementById('_pageReader').clientWidth);
    setLoading(false);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => {
      if((prevPageNumber + offset) >= 1 && (prevPageNumber + offset) <= numPages){
        return (prevPageNumber + offset)
      }
      else{
        if(pageNumber > 1){
          appContext.setError('You reached the last page');
          appContext.setMessage('Opening first page..');
        }
        return 1;
      }
    });
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function exitReader () {
    contentViewContext.setUrl(null);
  }

  function goTo(pg){
    pg = Number(pg);
    if(pg <= numPages && pg > 0){
      setPageNumber(Number(pg));
    } else {
      appContext.setError('Invalid page number');
    }
  }

  const readerContext = {
    scalePage,
    resetPageWidth,
    pageNumber,
    numPages,
    previousPage,
    nextPage,
    goTo,
    exitReader,
  }

  const init = () => {
    setReady(false);
    if(contentViewContext.url && contentViewContext.data) {
      setReady(true);
      appContext.setHideTopBar(true);
      appContext.setShowBookControls(true);
      appContext.setBookReaderContext(readerContext);

      let menuHidder = (event) => {
        event.preventDefault();
        //props.loaderCallback(false);
      }

      document.addEventListener("contextmenu", menuHidder);
      setLoading(true);

      return () => {
        document.removeEventListener("contextmenu", menuHidder);
      }
    } else {
      appContext.setError('Could not open this book');
    }
  }

  useEffect(() => {
    let finishers = init();
    return () => {
      appContext.setHideTopBar(false);
      appContext.setShowBookControls(false);
      finishers();
    }
  }, [ ]);

  useEffect(() => {
    let reader = document.getElementById('_pageReader');
    if(!resizeObserver) {
      resizeObserver = new ResizeObserver(() => {
        setPageWidth(document.getElementById('_pageReader').clientWidth);
      })
    }

    if(pageScaled) {
      resizeObserver.unobserve(reader);

    } else {
      resizeObserver.observe(reader);
    }

    return () => {
      resizeObserver.unobserve(reader);
    }

  }, [ pageScaled ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ]);

  useEffect(() => {
    appContext.setBookReaderContext(readerContext);
  }, [ numPages, ready, pageNumber ]);

  return (
    <div  id="_pageReader" >
      {
        (ready) ?
        <Document loading="Loading PDF, please wait" error="Loading PDF, please wait" file={BASE_API+contentViewContext.url} onLoadSuccess={ docLoaded }>
          <Page width={pageWidth} pageNumber={pageNumber} />
        </Document>:
        <div className="row">
          <div className="col-md-12 support-loading">
            {getInlineLoader()}
          </div>
        </div>
      }
    </div>
  )

}
